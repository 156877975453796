import React, { useEffect, useMemo, useState, useRef } from 'react'
import { useViewStore } from '../Contexts/viewStore'
import { sendEvent } from '../Contexts/directLineStore'
import {
  setCurrentNewsActivity,
  setRetorikNewsEnded,
  useUtilsStore
} from '../Contexts/utilsStore'

import useNews from '../../hooks/useNews'

import type { Image, Media } from '../../models/attachmentTypes'
import { RetorikActivity } from '../../models/activityTypes'
import { Routes } from '../../models/enums'

import Banner from './Banner'
import { Carousel } from '../Utils'

type NewsProps = {
  intervalInSeconds?: number
  onEnd: () => void
  isRetorikNews?: boolean
}

const News = ({
  intervalInSeconds = 3,
  onEnd,
  isRetorikNews
}: NewsProps): JSX.Element => {
  const { newsAttachments, activity } = useNews()
  const route = useViewStore((state) => state.route)
  const isMobile = useViewStore((state) => state.isMobile)
  const retorikNewsEnded = useUtilsStore((state) => state.retorikNewsEnded)

  const news = useMemo(() => {
    return newsAttachments.map((newsAttachement) => newsAttachement.content)
  }, [newsAttachments])
  const [current, setCurrent] = useState<number>(0)
  const timerRef: React.MutableRefObject<any> = useRef(null)

  useEffect(() => {
    if (!newsAttachments.length) {
      setTimeout(() => {
        sendEvent('news.getAllNews', null)
      }, 2000)
    }

    return (): void => {
      timerRef && clearTimeout(timerRef.current)
    }
  }, [])

  useEffect(() => {
    const currentNew = news[current]
    if (currentNew) {
      const tempActivity: RetorikActivity = {
        ...activity,
        speak: `${currentNew.title}, <break/> ${currentNew.subtitle}: <break/> ${currentNew.text}`
      } as RetorikActivity
      setCurrentNewsActivity(tempActivity)
    } else {
      setCurrentNewsActivity(undefined)
    }
  }, [activity, current])

  const currentMedia = useMemo<Array<Image | Media>>(() => {
    const currentNew = news[current]
    if (currentNew) {
      if (currentNew.media && currentNew.media.length > 0) {
        return currentNew.media
      } else if (currentNew.image && currentNew.image.url) {
        return [currentNew.image]
      }
    }

    return []
  }, [news, current])

  useEffect(() => {
    if (retorikNewsEnded) {
      if (news.length > 0) {
        if (current < news.length - 1) {
          const delay =
            current < news.length - 1
              ? intervalInSeconds
              : intervalInSeconds * 3
          timerRef && clearTimeout(timerRef.current)
          if (route === Routes.News || isRetorikNews) {
            timerRef.current = setTimeout(next, delay * 1000)
          }
        } else {
          onEnd()
        }
      }

      setRetorikNewsEnded(false)
    }
  }, [retorikNewsEnded])

  const next = (): void => {
    const next = (current + 1) % news.length
    changeNews(next)
  }

  const changeNews = (next: number): void => {
    setCurrent(next)
  }

  return news.length > 0 && current >= 0 ? (
    <React.Fragment>
      {/* Carousel in landscape / borne display */}
      {!isMobile && (
        <div
          className='rf-relative
            rf-col-start-5 rf-col-span-3 rf-row-start-1 rf-row-span-9
            large-vertical:rf-col-start-2 large-vertical:rf-col-end-8 large-vertical:rf-row-start-7 large-vertical:rf-row-end-11
            rf-flex rf-justify-center rf-items-center'
        >
          {currentMedia.length > 0 && (
            <Carousel
              className='rf-max-h-4/5 large-vertical:rf-max-h-11/12 rf-max-w-full'
              medias={
                news[current].media || [news[current].image || { url: '' }]
              }
            />
          )}
        </div>
      )}

      {/* Lower banner with title / subtitle / text */}
      <Banner news={news[current]} />
    </React.Fragment>
  ) : (
    <React.Fragment />
  )
}

export default News
