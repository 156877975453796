import React, { useEffect } from 'react'

import { useRetorikStore } from '../Contexts/retorikStore'
import { useViewStore, setRoute } from '../Contexts/viewStore'
import { useActivityStore } from '../Contexts/activityStore'
import { setCurrentSubView } from '../Contexts/utilsStore'

import type { ChatbotData } from '../../models/types'
import { CurrentSubView, Mode, Routes } from '../../models/enums'

import HomeView from '../Views/HomeView'
import NewsView from '../Views/NewsView'
import WarningBeforeNewsView from '../Views/WarningBeforeNewsView'

interface RetorikRouterProps {
  chatbotData: ChatbotData
  externalComponents?: Array<{ name: string; component: JSX.Element }>
}

const RetorikRouter = (props: RetorikRouterProps): JSX.Element => {
  const isUsedOnBorne = useRetorikStore(
    (state) => state.configuration.isUsedOnBorne
  )
  const mode = useRetorikStore((state) => state.mode)
  const route = useViewStore((state) => state.route)
  const lastBotEvent = useActivityStore((state) => state.lastBotEventActivity)

  useEffect(() => {
    if (lastBotEvent) {
      switch (lastBotEvent.name) {
        case 'news.ShowFlashInfo':
          setRoute(Routes.News)
          break
        case 'weather.ShowWeather':
          setCurrentSubView(CurrentSubView.weather)
          break
        default:
          break
      }
    }
  }, [lastBotEvent])

  return (
    <React.Fragment>
      {route === Routes.Home && <HomeView {...props} />}
      {route === Routes.News &&
        (mode === Mode.vocal || !!isUsedOnBorne ? (
          <NewsView {...props} />
        ) : (
          <WarningBeforeNewsView propsToTransfer={props} />
        ))}
    </React.Fragment>
  )
}

export default RetorikRouter
