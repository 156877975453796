/* eslint-disable react/jsx-boolean-value */
import React from 'react'
import { jsxToHtmlElement } from '../../../utils/jsxToHtmlElement'
import { ToggleInput } from 'adaptivecards/lib/card-elements'
import InputToggle from '../InputToggle'

export class ToggleInputClass extends ToggleInput {
  private _value = this.defaultValue || this.valueOff
  public get value(): any {
    return this._value
  }

  public isSet(): any {
    return this._value
  }

  protected internalRender(): HTMLElement {
    const element = jsxToHtmlElement(this.renderReact())
    return element
  }

  protected renderReact = (): JSX.Element => {
    return this.id ? (
      <InputToggle
        id={this.id}
        title={this.title}
        valueOn={this.valueOn}
        defaultValue={this.defaultValue || this.valueOff}
      />
    ) : (
      <React.Fragment />
    )
  }
}
