import { create } from 'zustand'
import { setAdaptiveCardHostConfig } from './adaptiveCardStore'

import { DeviceType, Routes } from '../../models/enums'
import {
  mobileDisplays,
  widgetDisplays,
  landscapeDisplays,
  borneDisplays,
  mediumBreakpoint
} from '../../models/constants'
import type { ThemeColors } from '../../models/colorTypes'
import type { ViewsConfiguration } from '../../models/types'
import { defaultThemeDark } from '../../models/colors'
import { RetorikActivity } from '../../models/activityTypes'

interface ViewStore {
  currentDeviceType: DeviceType
  isMobile: boolean
  isWidget: boolean
  isLandscape: boolean
  isBorne: boolean
  themeColors: ThemeColors
  configurations: ViewsConfiguration
  route: Routes
  currentHeight: number
  currentWidth: number
  isTactile: boolean
  isMobileLandscape: boolean
  displayControls: boolean
  showHomeAttachments: RetorikActivity | undefined
  mediumLandscape: boolean
}

const defaultConfiguration: ViewsConfiguration = {
  homeRoute: 'home',
  views: {
    home: {
      background: {
        image: '',
        style: 'image'
      }
    }
  }
}

const initialState: ViewStore = {
  currentDeviceType: DeviceType.mobile,
  isMobile: false,
  isWidget: false,
  isLandscape: true,
  isBorne: false,
  themeColors: defaultThemeDark,
  configurations: defaultConfiguration,
  route: Routes.Home,
  currentHeight: 1080,
  currentWidth: 1920,
  isTactile: 'ontouchstart' in window || navigator.maxTouchPoints > 0,
  isMobileLandscape: false,
  displayControls: true,
  showHomeAttachments: undefined,
  mediumLandscape: false
}

export const useViewStore = create<ViewStore>()(() => {
  return initialState
})

export const setCurrentDeviceType = (value: DeviceType): void => {
  useViewStore.setState({
    currentDeviceType: value,
    isMobile: mobileDisplays.includes(value),
    isWidget: widgetDisplays.includes(value),
    isLandscape: landscapeDisplays.includes(value),
    isBorne: borneDisplays.includes(value)
  })
  checkMobileLandscape()
}

export const setThemeColors = (value: ThemeColors): void => {
  setAdaptiveCardHostConfig(value.primary, value.card.frame.background)

  useViewStore.setState({
    themeColors: value
  })
}

export const setRoute = (value: Routes): void => {
  useViewStore.setState({ route: value })
}

export const setViewsConfiguration = (value: ViewsConfiguration): void => {
  useViewStore.setState({ configurations: value })
}

const checkMobileLandscape = (): void => {
  const currentState = useViewStore.getState()
  if (
    currentState.currentDeviceType === DeviceType.mobile &&
    currentState.currentHeight < currentState.currentWidth
  ) {
    useViewStore.setState({ isMobileLandscape: true })
  } else {
    useViewStore.setState({ isMobileLandscape: false })
  }
}

export const setCurrentHeight = (value: number): void => {
  useViewStore.setState({ currentHeight: value })
  checkMobileLandscape()
}

export const setCurrentWidth = (value: number): void => {
  useViewStore.setState({
    currentWidth: value,
    mediumLandscape: value < mediumBreakpoint
  })
  checkMobileLandscape()
}

export const setDisplayControls = (value: boolean): void => {
  useViewStore.setState({ displayControls: value })
}

export const setShowHomeAttachments = (
  value: RetorikActivity | undefined
): void => {
  useViewStore.setState({ showHomeAttachments: value })
}
