import React, { useState, useEffect } from 'react'
import { useLocaleStore } from '../../Contexts/localeStore'
import {
  setCurrentCustomView,
  setCurrentSubView
} from '../../Contexts/utilsStore'
import { useViewStore } from '../../Contexts/viewStore'
import { AvailableSubViews, CurrentSubView } from '../../../models/enums'
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter'
import { mobileDisplays } from '../../../models/constants'
import DisplayIcon from './DisplayIcon'
import type { LocalizedStrings } from '../../../models/types'

interface DisplaySubGeneratorProps {
  indice: number
  view: number
  device: number
  handleClick?: () => void
  type: string
}

interface CustomMenuDisplaySubGeneratorProps {
  indice: number
  label: LocalizedStrings | string
  device: number
  handleClick?: () => void
  conditionalHandleClick?: () => void
  icon: JSX.Element
  customDisplay?: string
  type: string
}

function instanceOfDisplayGeneratorProps(
  object: any
): object is DisplaySubGeneratorProps {
  return 'view' in object && object.type === 'BaseMenu'
}

function instanceOfCustomMenuDisplayGeneratorProps(
  object: any
): object is CustomMenuDisplaySubGeneratorProps {
  return 'icon' in object && object.type === 'CustomMenu'
}

const DisplaySubGenerator = (
  props: DisplaySubGeneratorProps | CustomMenuDisplaySubGeneratorProps
): JSX.Element => {
  const translation = useLocaleStore((state) => state.currentTranslations)
  const locale = useLocaleStore((state) => state.locale)
  const isMobile = useViewStore((state) => state.isMobile)
  const [label, setLabel] = useState<string>('')

  /**
   * On component mount :
   *  - set label state depending on the locale
   */
  useEffect(() => {
    if (instanceOfDisplayGeneratorProps(props)) {
      setLabel(translation[props.view.toString()])
    } else if (instanceOfCustomMenuDisplayGeneratorProps(props)) {
      if (props.label && typeof props.label === 'string') {
        setLabel(props.label)
      } else if (props.label && typeof props.label === 'object') {
        setLabel(props.label[locale])
      }
    }
  }, [locale])

  /**
   * On call :
   *  - switch on chosen view
   *  - either display an overlaying page or redirect to another view
   *  - if handleClick is defined, call it
   */
  const handleClickOnCard = (): void => {
    if (instanceOfDisplayGeneratorProps(props)) {
      switch (props.view) {
        case AvailableSubViews.history:
          setCurrentSubView(CurrentSubView.history)
          break
        case AvailableSubViews.tutorial:
          console.log('Tutorial')
          break
      }
    } else if (instanceOfCustomMenuDisplayGeneratorProps(props)) {
      // Call props' closing function in mobile mode if asked by the custom component
      isMobile && props.conditionalHandleClick && props.conditionalHandleClick()
      if (props.customDisplay) {
        setCurrentCustomView({ ...props })
      }
    }

    props.handleClick && props.handleClick()
  }

  return mobileDisplays.includes(props.device) ? (
    /* On mobile / widget : display available views as lines, without icons */
    <button
      className={`rf-w-full rf-py-4 rf-flex rf-flex-row rf-items-center ${
        props.indice !== 0 && 'rf-border-t rf-border-t-menuBorder'
      }`}
      onClick={handleClickOnCard}
      aria-label={label}
    >
      {capitalizeFirstLetter(label, locale)}
    </button>
  ) : (
    /* On desktop / borne : display available views as buttons at the center of the screen */
    <button
      className='rf-h-24 rf-w-24 rf-p-4 rf-flex rf-flex-col rf-items-center rf-justify-between rf-rounded rf-shadow-[3px_3px_16px_#00000029]'
      style={{
        background: 'linear-gradient(to left top, #F2F3F6, #E5E6EC)'
      }}
      onClick={handleClickOnCard}
      aria-label={label}
    >
      {instanceOfDisplayGeneratorProps(props) ? (
        <DisplayIcon view={props.view} className='rf-h-8' />
      ) : (
        <DisplayIcon icon={props.icon} className='rf-h-8 rf-w-8 rf-flex' />
      )}
      <div className='rf-uppercase rf-text-[#575F6B] rf-text-xs'>{label}</div>
    </button>
  )
}

export default DisplaySubGenerator
