import React from 'react'
import { jsxToHtmlElement } from '../../../utils/jsxToHtmlElement'
import { TimeInput } from 'adaptivecards/lib/card-elements'
import InputTime from '../InputTime'

export class TimeInputClass extends TimeInput {
  private _value = this.defaultValue || undefined
  public get value(): any {
    return this._value
  }

  public isSet(): any {
    return this._value
  }

  protected internalRender(): HTMLElement {
    const element = jsxToHtmlElement(this.renderReact())
    return element
  }

  protected renderReact = (): JSX.Element => {
    return this.id ? (
      <InputTime
        id={this.id}
        defaultValue={this.defaultValue}
        maxValue={this.max}
        minValue={this.min}
      />
    ) : (
      <React.Fragment />
    )
  }
}
