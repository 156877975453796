import React, { useEffect, useRef, useState } from 'react'
import { useList } from '../../Contexts/ListContext'
import { useViewStore } from '../../Contexts/viewStore'
import { useLocaleStore } from '../../Contexts/localeStore'
import { sendMessage } from '../../Contexts/directLineStore'

import useCurrentPagination from '../../../hooks/useCurrentPagination'

import type { DirectLineAttachment } from '../../../models/directLine'
import {
  CONTENT_TYPE_EMPTY,
  CONTENT_TYPE_POI
} from '../../../models/attachmentTypes'
import { DeviceType } from '../../../models/enums'
import preventEvents from '../../../utils/preventEvents'
import renderAttachment from '../../Middlewares/attachmentMiddleware'

import GridTemplate from '../../Templates/Grid'
import Animation2 from '../../Loader/Animation2'
import ScrollButton from '../../AnswerPanel/ScrollButton'

interface ListContainerProps {
  hasFilterOrSuggestedAction: boolean
  numberOfResults: number
}

let timer: NodeJS.Timeout | null = null

const ListContainer = ({
  hasFilterOrSuggestedAction,
  numberOfResults
}: ListContainerProps): JSX.Element => {
  const { content, selectedFilters, loadingData, setLoadingData } = useList()
  const translation = useLocaleStore((state) => state.currentTranslations)
  const isMobile = useViewStore((state) => state.isMobile)
  const isWidget = useViewStore((state) => state.isWidget)
  const currentDeviceType = useViewStore((state) => state.currentDeviceType)
  const currentPagination = useCurrentPagination()

  const [contentToDisplay, setContentToDisplay] = useState<
    DirectLineAttachment[]
  >([])
  const [landscapeStyle, setLandscapeStyle] = useState<string>('large:rf-h-3/5')
  const [limitToFill, setLimitToFill] = useState<number>(0)
  const loadingRef = useRef<boolean | null>(false)
  const scrollRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement | null>(null)
  const numberOfDisplayed = useRef<number | null>()
  const [abortController, setAbortController] =
    useState<AbortController | null>(null)

  useEffect(() => {
    setLandscapeStyle(hasFilterOrSuggestedAction ? 'large:rf-h-[60vh]' : '')
  }, [hasFilterOrSuggestedAction])

  useEffect(() => {
    switch (currentDeviceType) {
      case DeviceType.landscape:
        setLimitToFill(10)
        break
      case DeviceType.borne:
        setLimitToFill(9)
        break
      case DeviceType.mobile:
      case DeviceType.widget:
        setLimitToFill(0)
        break
    }
  }, [currentDeviceType])

  useEffect(() => {
    if (content?.attachments && content.attachments.length > 0) {
      const filteredContent = content.attachments.filter(
        (attachment) => attachment.contentType === CONTENT_TYPE_POI
      )

      if (content?.label === 'Davi.NextPageContent') {
        // If we received new content to add to the current one, check for mobile and display all
        if (isMobile) {
          // If the last attachment was added to get an even number, remove it before merging data
          const oldAttachments =
            contentToDisplay[contentToDisplay.length - 1].contentType ===
            CONTENT_TYPE_EMPTY
              ? [...contentToDisplay].slice(0, -1)
              : contentToDisplay
          const tempAttachments = [...oldAttachments, ...filteredContent]
          if (tempAttachments.length % 2 !== 0) {
            setContentToDisplay([
              ...tempAttachments,
              { contentType: CONTENT_TYPE_EMPTY }
            ])
          } else {
            setContentToDisplay(tempAttachments)
          }
        } else {
          setContentToDisplay([...contentToDisplay, ...filteredContent])
        }
      } else {
        // On a mobile device, if there is an odd number of attachments, we put a grey one at the end
        if (isMobile && filteredContent.length % 2 !== 0) {
          setContentToDisplay([
            ...filteredContent,
            { contentType: CONTENT_TYPE_EMPTY }
          ])
        }
        // On a landscape device, we fill with grey cards if less than 10 attachments, same on borne device with less than 9
        else if (filteredContent.length < limitToFill) {
          const tempAttachments = [...filteredContent]
          let i = filteredContent.length
          while (i < limitToFill) {
            tempAttachments.push({ contentType: CONTENT_TYPE_EMPTY })
            i++
          }
          setContentToDisplay(tempAttachments)
        } else {
          setContentToDisplay(filteredContent)
        }
      }
    } else {
      setContentToDisplay([])
    }
  }, [content, limitToFill])

  useEffect(() => {
    loadingRef.current = loadingData
  }, [loadingData])

  useEffect(() => {
    numberOfDisplayed.current = contentToDisplay.filter(
      (item) => item.contentType === CONTENT_TYPE_POI
    ).length

    content?.label !== 'Davi.NextPageContent' && !isMobile
      ? (timer = setTimeout(() => {
          scrollToRef()
        }, 100))
      : setLoadingData(false)

    return () => {
      timer && clearTimeout(timer)
    }
  }, [contentToDisplay])

  useEffect(() => {
    if (contentRef?.current && isWidget) {
      if (currentDeviceType === DeviceType.widgetLandscape) {
        const ab = new AbortController()
        setAbortController(ab)
        preventEvents(null, contentRef, ab.signal)
      } else {
        abortController?.abort()
        setAbortController(null)
      }
    }
  }, [contentRef?.current, currentDeviceType])

  /**
   * Click on 'see more' button in mobile mode or scroll to the end of the list in desktop / borne modes.
   * This will send a postback message with 'hospitality.pointOfInterest.nextPage' name to get new content
   */
  const handleClick = (): void => {
    setLoadingData(true)
    numberOfDisplayed.current &&
      sendMessage(undefined, {
        name: 'hospitality.pointOfInterest.nextPage',
        index:
          numberOfDisplayed.current < currentPagination
            ? 0
            : numberOfDisplayed.current - (currentPagination - 2),
        filters: selectedFilters
      })
  }

  /**
   * Check if we need to call for next content
   */
  const handleScroll = () => {
    if (
      contentRef?.current &&
      contentRef.current.scrollTop + contentRef.current.clientHeight >=
        contentRef.current.scrollHeight - 5 &&
      numberOfDisplayed?.current &&
      numberOfDisplayed.current !== numberOfResults &&
      !loadingRef.current
    ) {
      handleClick()
    }
  }

  /**
   * Scroll to the scrollRef HTMLDivElement at the top of the view
   */
  const scrollToRef = (): void => {
    if (scrollRef && scrollRef.current !== null) {
      scrollRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      })
    }
    setLoadingData(false)
  }

  return content ? (
    <React.Fragment>
      <div
        ref={contentRef}
        className={`rf-relative rf-w-full rf-mb-8 ${landscapeStyle} large-vertical:rf-h-[50vh] rf-overflow-y-visible large:rf-overflow-y-scroll rf-scrollbar-hidden ${
          !isMobile && 'rf-opacity-gradient-b-thin'
        } rf-px-2 large:rf-px-0 rf-pb-8 large:rf-pb-16 ${
          loadingData && 'rf-pointer-events-none'
        }`}
        onScroll={handleScroll}
      >
        <div ref={scrollRef} />

        {/* Loader */}
        {loadingData && (
          <div
            className='rf-absolute rf-z-util rf-w-full rf-flex rf-items-end rf-justify-center rf-bg-white rf-bg-opacity-50 rf-pointer-events-none'
            style={{
              height: contentRef?.current?.scrollHeight
                ? `${contentRef.current.scrollHeight}px`
                : '100%',
              paddingBottom: contentRef?.current?.clientHeight
                ? `calc(${contentRef.current.clientHeight / 2}px - 6rem)`
                : '6rem'
            }}
          >
            <div className='rf-w-6 rf-h-6 rf-mb-16'>
              <Animation2
                color='gray'
                loaderColor='#1999b1'
                backgroundColor='transparent'
              />
            </div>
          </div>
        )}

        {/* Content */}
        <GridTemplate>
          {contentToDisplay.length === 0 ? (
            <div>{translation.poi.nodata}</div>
          ) : (
            contentToDisplay.map((attachment, key) => {
              return <div key={key}>{renderAttachment({ attachment })}</div>
            })
          )}
        </GridTemplate>

        {isMobile && (
          <div className='rf-w-full rf-flex rf-flex-row rf-justify-end rf-gap-8'>
            {/* Button to get more POI in mobile mode where we can't scroll down inside the grid */}
            {numberOfDisplayed.current !== numberOfResults && (
              <button
                className='rf-w-full rf-py-2 rf-bg-templateBackground rf-border rf-border-black rf-rounded rf-text-black'
                onClick={handleClick}
              >
                Voir plus
              </button>
            )}

            {/* 'back up' button */}
            <ScrollButton
              direction='top'
              display={
                !!(
                  numberOfDisplayed?.current &&
                  numberOfDisplayed.current > currentPagination
                )
              }
              scrollHandler={() => scrollToRef()}
              setPreventScroll={() => {}}
              className='rf-w-9 rf-h-9 rf-cursor-pointer rf-bg-[#00000050] rf-p-[7px_12px] rf-rounded-[20px] rf-flex'
            />
          </div>
        )}
      </div>

      {/* 'back up' button in borne / desktop modes */}
      {!isMobile && (
        <ScrollButton
          direction='top'
          display={
            !!(
              numberOfDisplayed?.current &&
              numberOfDisplayed.current > currentPagination
            )
          }
          scrollHandler={() => scrollToRef()}
          setPreventScroll={() => {}}
          className='rf-absolute rf-bottom-1/4 rf-right-3 rf-z-ui rf-w-9 rf-h-9 rf-cursor-pointer rf-bg-[#00000050] rf-p-[7px_12px] rf-rounded-[20px] rf-flex'
        />
      )}
    </React.Fragment>
  ) : (
    <React.Fragment />
  )
}

export default ListContainer
