import React, { useEffect, useState } from 'react'
import { useUtilsStore } from '../Contexts/utilsStore'
import {
  useAdaptiveCardStore,
  setToggleValue,
  addCurrentToggleId
} from '../Contexts/adaptiveCardStore'
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter'
import { CurrentSubView } from '../../models/enums'

interface InputToggleProps {
  id: string
  title?: string
  valueOn: string
  defaultValue: string
}

const InputToggle = ({
  id,
  title,
  valueOn,
  defaultValue
}: InputToggleProps): JSX.Element => {
  const currentSubView = useUtilsStore((state) => state.currentSubView)
  const values = useAdaptiveCardStore((state) => state.values)
  const [on, setOn] = useState<boolean>(
    (values.get(id) as boolean) || defaultValue === valueOn
  )

  useEffect(() => {
    currentSubView !== CurrentSubView.history &&
      addCurrentToggleId(id, defaultValue === valueOn)
  }, [])

  /**
   * On call :
   *  - check if the component will be rendered in history, if so bypass every interaction
   *  - call parent's handleChange method
   *  - set on state to its opposite
   */
  const handleToggle = (): void => {
    console.log('Toggle Change : ', id)
    if (currentSubView !== CurrentSubView.history) {
      setToggleValue(id, !on)
      setOn(!on)
    }
  }

  return (
    <div className='rf-w-full rf-flex rf-flex-row rf-items-start rf-text-size-auto rf-text-white'>
      <label className='rf-flex rf-flex-row rf-items-start'>
        <input
          type='checkbox'
          onChange={handleToggle}
          className='rf-toggle-custom'
          checked={on}
        />
        <div className='rf-input-label'>
          {capitalizeFirstLetter(title || id)}
        </div>
      </label>
    </div>
  )
}

export default InputToggle
