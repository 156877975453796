import React, { useEffect, useState, useMemo } from 'react'
import { useLocaleStore } from '../Contexts/localeStore'
import { useUtilsStore } from '../Contexts/utilsStore'
import {
  useAdaptiveCardStore,
  setValue,
  setCurrentId
} from '../Contexts/adaptiveCardStore'
import { getDateAsArray } from '../../utils/getDateAsArray'
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter'
import { CurrentSubView } from '../../models/enums'
import { ChevronLeftIcon, ChevronRightIcon } from '../Icons/ChevronIcons'

interface InputCalendarProps {
  id: string
  defaultValue?: string
  maxValue?: string
  minValue?: string
  handleChange?: (date: string) => void
  isFilter?: boolean
}

/**
 * Oddmonths used to calculate the max amount of days in a month
 */
const oddMonths = [4, 6, 9, 11]

const InputCalendar = ({
  id,
  defaultValue,
  maxValue,
  minValue,
  handleChange,
  isFilter
}: InputCalendarProps): JSX.Element => {
  const locale = useLocaleStore((state) => state.locale)
  const currentSubView = useUtilsStore((state) => state.currentSubView)
  const values = useAdaptiveCardStore((state) => state.values)

  const [year, setYear] = useState<number>(new Date().getFullYear())
  const [month, setMonth] = useState<number>(new Date().getMonth() + 1)
  const [day, setDay] = useState<number>(new Date().getDate())

  const [maxYear, setMaxYear] = useState<number>(0)
  const [minYear, setMinYear] = useState<number>(100000)
  const [maxMonth, setMaxMonth] = useState<number>(12)
  const [minMonth, setMinMonth] = useState<number>(1)
  const [maxDay, setMaxDay] = useState<number>(31)
  const [minDay, setMinDay] = useState<number>(1)
  const [maxValueAsDate, setMaxValueAsDate] = useState<Date | undefined>(
    undefined
  )
  const [minValueAsDate, setMinValueAsDate] = useState<Date | undefined>(
    undefined
  )

  const [date, setDate] = useState<string>(
    (values.get(id) as string) || `${year}-${month}-${day}`
  )

  /**
   * On month / year states change :
   *  - calculate and return the number of days of the current month
   */
  const days = useMemo<number | undefined>(() => {
    if (month && year) {
      // If we check the february month, either 28 or 29 days depending on the year
      if (month === 2) {
        return year % 4 === 0 ? 29 : 28
      }
      // 30 days for oddMonths, 31 otherwise
      return oddMonths.includes(month) ? 30 : 31
    }
    return undefined
  }, [month, year])

  /**
   * On month / year states change :
   *  - check which day is the first day of the month
   *  - return the number of days of week before the first one of the month
   */
  const emptyDays = useMemo<number>(() => {
    const firstDay = new Date(year, month - 1, 1).toLocaleString('fr-FR', {
      weekday: 'long'
    })
    switch (firstDay.toLowerCase()) {
      case 'lundi':
        return 0
      case 'mardi':
        return 1
      case 'mercredi':
        return 2
      case 'jeudi':
        return 3
      case 'vendredi':
        return 4
      case 'samedi':
        return 5
      case 'dimanche':
        return 6
    }

    return 0
  }, [month, year])

  const title = useMemo<string>(() => {
    const newDate = new Date(year, month - 1, 1)
    return capitalizeFirstLetter(
      new Date(newDate).toLocaleString(locale, {
        month: 'long',
        year: 'numeric'
      })
    )
  }, [month, year])

  /**
   * On locale change :
   *  - translate the days of week to display depending on the locale
   */
  const daysOfWeek = useMemo<Array<string>>(() => {
    let tempArray: Array<string> = []
    for (let i = 1; i < 8; i++) {
      // First day of May 2023 is monday, let's get each day one after another beginning by monday
      const newDate = new Date(2023, 4, i)
      tempArray = [
        ...tempArray,
        capitalizeFirstLetter(
          newDate.toLocaleString(locale, {
            weekday: 'short'
          })
        )
      ]
    }

    return tempArray
  }, [locale])

  useEffect(() => {
    currentSubView !== CurrentSubView.history && setCurrentId(id)
  }, [])

  /**
   * On component mount :
   *  - set day / month / year from localStorage value if there is one, current Date otherwise
   *  - set max values from props or current Date + 150 years otherwise
   */
  useEffect(() => {
    const dateAsArray = getDateAsArray(
      (values.get(id) as string) || defaultValue
    )
    setYear(dateAsArray[0])
    setMonth(dateAsArray[1])
    setDay(dateAsArray[2])
    setDate(`${dateAsArray[0]}-${dateAsArray[1]}-${dateAsArray[2]}`)

    // Set min and max years
    const now = new Date()
    if (maxValue) {
      const maxAsDate = new Date(maxValue)
      setMaxValueAsDate(maxAsDate)
      setMaxYear(maxAsDate.getFullYear())
      setMaxMonth(maxAsDate.getMonth() + 1)
      setMaxDay(maxAsDate.getDate())
    } else {
      setMaxYear(now.getFullYear() + 150)
    }

    if (minValue) {
      const minAsDate = new Date(minValue)
      setMinValueAsDate(minAsDate)
      setMinYear(minAsDate.getFullYear())
      setMinMonth(minAsDate.getMonth() + 1)
      setMinDay(minAsDate.getDate())
    } else {
      setMinYear(now.getFullYear() - 150)
    }
  }, [])

  /**
   * On day / month / year state change :
   *  - check if the date is in the boundaries of minDate / maxDate
   *  - set the value in localStorage
   */
  useEffect(() => {
    // Check if we are in the bounds of minValue and maxValue if there are some
    // Explode date to extract each value and substract 1 to the month (begins at 0 in js Date object)
    const dateAsArray = date.split('-')
    const newDate = new Date(
      parseInt(dateAsArray[0]),
      parseInt(dateAsArray[1]) - 1,
      parseInt(dateAsArray[2])
    )

    if (minValue && minValueAsDate && newDate < minValueAsDate) {
      setDay(minDay)
      setMonth(minMonth)
      setYear(minYear)
      setDate(minValue)
    } else if (maxValue && maxValueAsDate && newDate > maxValueAsDate) {
      setDay(maxDay)
      setMonth(maxMonth)
      setYear(maxYear)
      setDate(maxValue)
    } else {
      // Save date
      id !== 'filterCalendar' && setValue(id, date)
    }
  }, [date])

  const handlePreviousMonth = (): void => {
    if (currentSubView !== CurrentSubView.history) {
      if (year > minYear) {
        setMonth((month) => (month === 1 ? 12 : month - 1))
        month === 1 && setYear((year) => year - 1)
      } else if (year === minYear && month > minMonth) {
        month > 1 && setMonth((month) => month - 1)
      }
    }
  }

  const handleNextMonth = (): void => {
    if (currentSubView !== CurrentSubView.history) {
      if (year < maxYear) {
        setMonth((month) => (month === 12 ? 1 : month + 1))
        month === 12 && setYear((year) => year + 1)
      } else if (year === maxYear && month < maxMonth) {
        month < 12 && setMonth((month) => month + 1)
      }
    }
  }

  const handleClick = (value: number): void => {
    if (currentSubView !== CurrentSubView.history) {
      setDay(value)
      setDate(`${year}-${month}-${value}`)
      id === 'filterCalendar' &&
        handleChange?.(
          `${year}-${month < 10 ? '0' : ''}${month}-${
            value < 10 ? '0' : ''
          }${value}`
        )
    }
  }

  return (
    <div
      className={`rf-w-full rf-px-2 rf-flex rf-flex-col rf-rounded-lg ${
        isFilter ? 'rf-text-trueblack' : 'rf-text-truewhite'
      } rf-subtitle-size-auto rf-unselectable`}
      style={{
        background: isFilter ? '#FFF' : 'rgba(0, 0, 0, 0.5)'
      }}
    >
      {/* Month + year */}
      <div className='rf-w-full rf-px-4 rf-py-6 rf-flex rf-flex-row rf-justify-between rf-items-center'>
        <div
          className='rf-w-4 rf-cursor-pointer'
          onClick={(e): void => {
            e.preventDefault()
            e.stopPropagation()
            handlePreviousMonth()
          }}
        >
          {(year > minYear || (year === minYear && month > minMonth)) && (
            <ChevronLeftIcon
              className='rf-h-4'
              color={isFilter ? '#000' : '#FFF'}
            />
          )}
        </div>
        <div
          className={`rf-width-fill-available rf-text-center rf-title-size-auto ${
            isFilter ? 'rf-text-secondary' : 'rf-text-primary'
          }`}
        >
          {title}
        </div>
        <div
          className='rf-w-4 rf-cursor-pointer'
          onClick={(e): void => {
            e.preventDefault()
            e.stopPropagation()
            handleNextMonth()
          }}
        >
          {(year < maxYear || (year === maxYear && month < maxMonth)) && (
            <ChevronRightIcon
              className='rf-h-4'
              color={isFilter ? '#000' : '#FFF'}
            />
          )}
        </div>
      </div>

      {/* Days */}
      <div className='rf-w-full rf-py-6 rf-grid rf-grid-cols-7 rf-gap-4'>
        {/* Days of week */}
        {daysOfWeek.map((weekDay, key) => {
          return (
            <div
              key={key}
              className='rf-flex rf-justify-center rf-items-center rf-aspect-square'
            >
              {weekDay}
            </div>
          )
        })}

        {/* Empty days */}
        {[...Array(emptyDays)].map((e, key) => {
          return (
            <div key={(key + 1) * 10} className='rf-aspect-square'>
              {e || '\u2800'}
            </div>
          )
        })}

        {/* Days */}
        {[...Array(days)].map((e, key) => {
          return (
            <div
              key={(key + 1) * 100}
              className={`rf-flex rf-justify-center rf-items-center rf-aspect-square ${
                key + 1 === day &&
                parseInt(date.split('-')[1]) === month &&
                parseInt(date.split('-')[0]) === year
                  ? `rf-font-bold rf-text-trueblack ${
                      isFilter ? 'rf-bg-secondary' : 'rf-bg-primary'
                    } rf-rounded-half`
                  : year === maxYear && month === maxMonth && key + 1 > maxDay
                  ? 'rf-text-formInputRadioCheckboxUncheckedBackground'
                  : 'rf-cursor-pointer'
              }`}
              onClick={(e): void => {
                e.preventDefault()
                e.stopPropagation()
                handleClick(key + 1)
              }}
            >
              {e || key + 1}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default InputCalendar
