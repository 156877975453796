import React, { useEffect, useRef, useState } from 'react'
import QRCode from 'qrcode'

import { useLocaleStore } from '../../Contexts/localeStore'
import { useRetorikStore } from '../../Contexts/retorikStore'

import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter'

import QRCodeComponent from '../../Utils/QRCodeComponent'
import PrintingButton from './PrintingButton'
import { RoundIcon } from '../../Icons/DetailedPOIIcons'

interface DownloadQRProps {
  url: string
}

const DownloadQR = ({ url }: DownloadQRProps): JSX.Element => {
  const translation = useLocaleStore((state) => state.currentTranslations)
  const locale = useLocaleStore((state) => state.locale)
  const enableDocumentPrinting = useRetorikStore(
    (state) => state.configuration.enableDocumentPrinting
  )

  const [qrCodeData, setQrCodeData] = useState<string | undefined>(undefined)
  const imageRef = useRef<HTMLImageElement>(null)

  useEffect(() => {
    setQrCodeDataAsync()
  }, [])

  const setQrCodeDataAsync = async (): Promise<void> => {
    const qrCodeString = await QRCode.toDataURL(url)
    setQrCodeData(qrCodeString)
  }

  return (
    <div className='rf-w-full rf-flex rf-flex-row rf-items-start'>
      {/* Printing button if allowed */}
      {enableDocumentPrinting && (
        <div className='rf-w-1/2 rf-flex rf-justify-start'>
          <PrintingButton url={url} />
        </div>
      )}

      {/* QR code for download */}
      <div
        className='rf-flex rf-flex-col rf-items-center rf-text-center rf-gap-2 rf-font-bold rf-text-sm'
        style={{
          width: enableDocumentPrinting ? '50%' : '100%'
        }}
      >
        <div className='rf-flex rf-flex-row rf-items-start rf-gap-1 rf-italic'>
          <RoundIcon />
          <div className='rf-w-fit'>
            {`${capitalizeFirstLetter(
              translation.common.downloadsheetmobile,
              locale
            )} ${translation.link.scanQR}`}
          </div>
        </div>

        {qrCodeData && (
          <div className='large-vertical:rf-mt-4'>
            <QRCodeComponent ref={imageRef} src={qrCodeData} />
          </div>
        )}
      </div>
    </div>
  )
}

export default DownloadQR
