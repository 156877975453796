import React, { useEffect, useState } from 'react'
import { useViewStore } from '../../Contexts/viewStore'
import { useRetorikStore } from '../../Contexts/retorikStore'

import { DEFAULT_SUB_VIEWS } from '../../../models/constants'
import { BaseSubMenu, CustomMenu } from '../../../models/types'

import DisplaySubGenerator from './DisplaySubGenerator'

interface DisplaySubViewsProps {
  handleClick?: () => void
  conditionalHandleClick?: () => void
}

const DisplaySubViews = ({
  handleClick,
  conditionalHandleClick
}: DisplaySubViewsProps): JSX.Element => {
  const isMobile = useViewStore((state) => state.isMobile)
  const currentDeviceType = useViewStore((state) => state.currentDeviceType)
  const configuration = useRetorikStore((state) => state.configuration)

  const [subMenu, setSubMenu] = useState<Array<JSX.Element>>([])

  const buildMenu = (
    menu: Array<BaseSubMenu | CustomMenu>
  ): Array<JSX.Element> => {
    let fullMenu: Array<JSX.Element> = []
    menu.forEach((currentMenu) => {
      // If the menu has 'icon' as key, it's a CustomMenu. Otherwise it's a BaseSubMenu
      if (Object.keys(currentMenu).includes('icon')) {
        const customCurrentMenu = currentMenu as CustomMenu
        fullMenu.push(
          <DisplaySubGenerator
            type='CustomMenu'
            indice={customCurrentMenu.indice}
            label={customCurrentMenu.label}
            icon={customCurrentMenu.icon}
            device={currentDeviceType}
            handleClick={(): void => {
              if (customCurrentMenu.clickHandler) {
                customCurrentMenu.clickHandler()
              }
              handleClick && handleClick()
            }}
            customDisplay={customCurrentMenu.customDisplay}
            conditionalHandleClick={
              customCurrentMenu.closeParametersOnClickInMobileMode
                ? conditionalHandleClick
                : undefined
            }
          />
        )
      } else {
        // In mobile mode, we only push custom items, base items (history / tutorial) have their own render
        !isMobile &&
          fullMenu.push(
            <DisplaySubGenerator
              type='BaseMenu'
              indice={currentMenu.indice}
              view={(currentMenu as BaseSubMenu).view}
              device={currentDeviceType}
              handleClick={handleClick}
            />
          )
      }
    })

    fullMenu = fullMenu.sort(
      (a: any, b: any) => a.props.indice - b.props.indice
    )
    return fullMenu
  }

  useEffect(() => {
    // Handle custom submenus
    if (configuration && configuration.subMenu) {
      setSubMenu(buildMenu(configuration.subMenu))
    } else {
      setSubMenu(buildMenu(DEFAULT_SUB_VIEWS))
    }
  }, [])

  return (
    <React.Fragment>
      {subMenu.map((e: JSX.Element, key) => {
        return <React.Fragment key={key}>{e}</React.Fragment>
      })}
    </React.Fragment>
  )
}

export default DisplaySubViews
