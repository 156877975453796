import React, { useState, useEffect, useRef } from 'react'
import { animated, useSpring } from '@react-spring/web'

import { useLocaleStore } from '../..'
import { useRetorikStore, setMode } from '../Contexts/retorikStore'
import { useViewStore } from '../Contexts/viewStore'

import { Mode } from '../../models/enums'

import ToggleWithTexts from '../Utils/Inputs/ToggleWithTexts'
import Animation from './Animation'

const springEnterDuration = 500
const springLeaveDuration = 1000

const LoaderCallToAction = ({ handleValidation }): JSX.Element => {
  const locale = useLocaleStore((state) => state.locale)
  const translation = useLocaleStore((state) => state.currentTranslations)
  const loaderInformationTexts = useRetorikStore(
    (state) => state.configuration.loaderInformationTexts
  )
  const loaderInformationTextsLocalized = useRetorikStore(
    (state) => state.configuration.loaderInformationTextsLocalized
  )
  const hideRetorikLogo = useRetorikStore(
    (state) => state.configuration.hideRetorikLogo
  )
  const disabledSpeechMode = useRetorikStore(
    (state) => state.configuration.disableSpeechMode
  )

  const mode = useRetorikStore((state) => state.mode)
  const themeColors = useViewStore((state) => state.themeColors)

  const container = useRef<HTMLDivElement>(null)
  const [hover, setHover] = useState<boolean>(false)
  const animationColor = {
    '--rf-color-loader-animation': themeColors.loader.animation
  } as React.CSSProperties
  const animationTimerRef = useRef<NodeJS.Timeout>()

  const [spring, api] = useSpring(() => ({
    from: {
      opacity: 0
    }
  }))

  useEffect(() => {
    api.start({
      from: {
        opacity: 0
      },
      to: {
        opacity: 1
      },
      config: {
        duration: springEnterDuration
      }
    })

    return () => {
      animationTimerRef?.current && clearTimeout(animationTimerRef.current)
    }
  }, [])

  const handleChange = (modeId: number): void => {
    const tempMode = modeId === 1 ? Mode.vocal : Mode.text
    setMode(tempMode)
  }

  const handleValidate = (): void => {
    handleValidation()
    api.start({
      from: {
        opacity: 1
      },
      to: {
        opacity: 0
      },
      config: {
        duration: springLeaveDuration
      }
    })
  }

  return (
    <animated.div
      className='rf-h-full rf-w-full rf-flex rf-flex-col rf-items-center rf-animate-loaderFadeIn'
      ref={container}
      style={{
        ...animationColor,
        ...spring
      }}
    >
      {/* Animation + Text */}
      <div className='rf-w-full rf-h-1/2 rf-pb-6 rf-flex rf-flex-col rf-items-center rf-justify-end'>
        {/* Animation */}
        <div className='rf-w-6 rf-h-6 rf-mb-16'>
          <Animation />
        </div>
        {/* Text */}
        <div
          className='rf-text-center'
          style={{
            color: themeColors.loader.text
          }}
        >
          {/* First line of informations */}
          {(loaderInformationTexts?.vocal.top &&
            loaderInformationTexts.text.top) ||
          (loaderInformationTextsLocalized?.vocal?.top?.[locale] &&
            loaderInformationTextsLocalized.text?.top?.[locale]) ? (
            <p>
              {mode === Mode.vocal
                ? loaderInformationTextsLocalized?.vocal?.top?.[locale] ||
                  loaderInformationTexts?.vocal?.top
                : loaderInformationTextsLocalized?.text?.top?.[locale] ||
                  loaderInformationTexts?.text?.top}
            </p>
          ) : (
            <p>
              {mode === Mode.vocal
                ? translation.loader.loader.optimal
                : translation.loader.loader.classic}
            </p>
          )}

          {/* Second line of informations */}
          {(loaderInformationTexts?.vocal?.bottom &&
            loaderInformationTexts.text?.bottom) ||
          (loaderInformationTextsLocalized?.vocal?.bottom?.[locale] &&
            loaderInformationTextsLocalized.text?.bottom?.[locale]) ? (
            <p className='rf-font-bold'>
              {mode === Mode.vocal
                ? loaderInformationTextsLocalized?.vocal?.bottom?.[locale] ||
                  loaderInformationTexts?.vocal?.bottom
                : loaderInformationTextsLocalized?.text?.bottom?.[locale] ||
                  loaderInformationTexts?.text?.bottom}
            </p>
          ) : (
            <p className='rf-font-bold'>
              {mode === Mode.vocal
                ? translation.loader.loader.vocal
                : translation.loader.loader.text}
            </p>
          )}
        </div>
      </div>

      {/* Button + Logo */}
      <div
        className={`rf-w-full rf-h-1/2 rf-flex rf-flex-col ${
          hideRetorikLogo ? 'rf-justify-start' : 'rf-justify-between'
        } rf-items-center`}
      >
        <div className='rf-flex rf-flex-col rf-items-center'>
          {/* Toggle button with both modes */}
          <ToggleWithTexts
            texts={{
              left: translation.loader.modes.vocal,
              leftId: 1,
              right: translation.loader.modes.text,
              rightId: 2
            }}
            colors={themeColors.loader.toggle}
            defaultChecked={mode}
            handleChange={handleChange}
            showLeft={!disabledSpeechMode}
            showRight
          />
          {/* Validation button or step text */}
          <button
            className='rf-font-bold rf-border-2 rf-rounded-lg rf-px-6 rf-py-3 rf-mt-8'
            style={{
              color: hover
                ? themeColors.loader.button.text.hover
                : themeColors.loader.button.text.default,
              borderColor: hover
                ? themeColors.loader.button.border.hover
                : themeColors.loader.button.border.default,
              background: hover
                ? themeColors.loader.button.background.hover
                : themeColors.loader.button.background.default
            }}
            onClick={handleValidate}
            onMouseEnter={(): void => setHover(true)}
            onMouseLeave={(): void => setHover(false)}
          >
            {translation.loader.loaderValidation}
          </button>
        </div>
      </div>
    </animated.div>
  )
}

export default LoaderCallToAction
