import type { RetorikActivity } from '../models/activityTypes'
import { processMarkdownAndSpeechMarkdown } from '../utils/markdownUtils'
import type { UrlData } from '../models/linkAttachment'
import type { ImageData } from '../models/imageAttachment'

type ReturnData = {
  speak: string | undefined
  text: string | undefined
  htmlText: string | undefined
  images: ImageData[]
  urls: UrlData[]
}

const processDirectLineIncomingActivity = (
  activity: RetorikActivity
): ReturnData => {
  const dataToReturn: ReturnData = {
    speak: undefined,
    text: undefined,
    htmlText: undefined,
    images: [],
    urls: []
  }
  const usedImageURLs: Array<string> = []
  const usedLinkURLs: Array<string> = []

  // Deal with 'speak' field if present
  if (activity?.speak) {
    // Make all the treatments needed for markdown and speechmarkdown
    const data = processMarkdownAndSpeechMarkdown(activity.speak)
    dataToReturn.speak = data.text
    dataToReturn.images = data.images
    dataToReturn.urls = data.urls

    // Add the used image URLs and link URLs to prevent doubles
    data.images.forEach((data) => {
      usedImageURLs.push(data.url)
    })
    data.urls.forEach((data) => {
      usedLinkURLs.push(data.url)
    })
  }

  // Deal with 'text' field if present
  if (activity?.text) {
    // Make all the treatments needed for markdown and speechmarkdown
    const data = processMarkdownAndSpeechMarkdown(activity.text)
    dataToReturn.text = data.text
    dataToReturn.htmlText = data.htmlText
    // Don't add images if they are already in the array (already extracted from the 'speak' field)
    data.images.forEach((image: ImageData) => {
      !usedImageURLs.includes(image.url) && dataToReturn.images.push(image)
    })
    // Don't add urls if they are already in the array (already extracted from the 'speak' field)
    data.urls.forEach((url: UrlData) => {
      !usedLinkURLs.includes(url.url) && dataToReturn.urls.push(url)
    })
  }

  return dataToReturn
}

export default processDirectLineIncomingActivity
