import React, { useEffect, useState } from 'react'
import { useUtilsStore } from '../../Contexts/utilsStore'
import {
  useAdaptiveCardStore,
  setValue,
  setCurrentId
} from '../../Contexts/adaptiveCardStore'
import { useViewStore } from '../../Contexts/viewStore'
import { CurrentSubView } from '../../../models/enums'

interface LikertProps {
  id: string
  ratingMin?: number
  ratingMax: number
  emojis: Array<string>
  strokeColor?: string
}

interface Dataset {
  emoji: string
  value: number
}

/**
 * Default data if not given in props :
 *  - defaultEmoji = default displayed object
 */
const defaultEmoji = '🟊'

// emoji examples ['🟊', '♠', '✺', 'Ϙ', '◎', '₱', '😔', '😐', '😀']

const Likert = ({
  id,
  ratingMin,
  ratingMax,
  emojis,
  strokeColor
}: LikertProps): JSX.Element => {
  const themeColors = useViewStore((state) => state.themeColors)
  const currentSubView = useUtilsStore((state) => state.currentSubView)
  const values = useAdaptiveCardStore((state) => state.values)
  const [data, setData] = useState<Array<Dataset>>([])
  const [rating, setRating] = useState(
    (values.get(id) as number) || ratingMin || 0
  )
  const [hover, setHover] = useState(0)
  const hoverColor = strokeColor || themeColors.formInput.likert.hover

  useEffect(() => {
    currentSubView !== CurrentSubView.history && setCurrentId(id)
  }, [])

  /**
   * On component mount :
   *  - create array of Dataset to be mapped for display
   *  - set stroke color if given in props
   */
  useEffect(() => {
    const min = ratingMin || 0
    const range = min <= 0 ? Math.abs(min) + ratingMax + 1 : ratingMax
    let dataArray: Array<Dataset> = []
    // If there are as many emojis as values, let's use them all
    if (emojis.length === range) {
      for (let i = 0; i < range; i++) {
        dataArray = [...dataArray, { emoji: emojis[i], value: min + i }]
      }
    }
    // Otherwise, let's use only the first emoji for each value
    else {
      for (let i = 0; i < range; i++) {
        dataArray = [
          ...dataArray,
          { emoji: emojis[0] || defaultEmoji, value: min + i }
        ]
      }
    }

    setData(dataArray)
  }, [])

  /**
   * On element click :
   *  - check if the component will be rendered in history, if so bypass every interaction
   *  - call parent function from props with the value retrieved
   *  - set rating
   */
  const handleClick = (value: number): void => {
    if (currentSubView !== CurrentSubView.history) {
      setValue(id, value)
      setRating(value)
    }
  }

  return (
    <div className='rf-my-4 rf-flex rf-flex-row rf-flex-nowrap rf-justify-center rf-items-center rf-title-large-size-auto'>
      {data.map((tuple, index) => {
        return (
          <div
            key={index}
            className='rf-px-1 rf-likert-custom'
            style={{
              color:
                tuple.value <= (hover || rating)
                  ? hoverColor
                  : themeColors.formInput.likert.default
            }}
            onClick={(): void => handleClick(tuple.value)}
            onMouseEnter={(): void => setHover(tuple.value)}
            onMouseLeave={(): void => setHover(rating)}
          >
            {tuple.emoji}
          </div>
        )
      })}
    </div>
  )
}

export default Likert
