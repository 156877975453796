import React, { useState } from 'react'
import {
  useRetorikStore,
  setMode,
  setCanFocusSendBox
} from '../../Contexts/retorikStore'
import { useViewStore } from '../../Contexts/viewStore'
import { setActiveRecognitionState } from '../../Contexts/speechStore'

import { Mode as ModeEnum, RecognitionState } from '../../../models/enums'

import MicrophoneButton from '../Common/MicrophoneButton'
import { KeyboardIcon, MicrophoneIcon } from '../../Icons/MenuIcons'

const Mode = (): JSX.Element => {
  const mode = useRetorikStore((state) => state.mode)
  const disabledSpeechMode = useRetorikStore(
    (state) => state.configuration.disableSpeechMode
  )
  const themeColors = useViewStore((state) => state.themeColors)

  const [fromTextToVocal, setFromTextToVocal] = useState<boolean>(false)

  const toggleMode = (newMode: number): void => {
    setFromTextToVocal(true)
    if (newMode === ModeEnum.text) {
      setCanFocusSendBox(true)
      setActiveRecognitionState(RecognitionState.ForceClosing)
    }
    setMode(newMode)
  }

  return (
    <div
      className={`rf-w-full rf-px-4 rf-grid rf-grid-cols-1 ${
        disabledSpeechMode ? 'rf-py-4' : 'rf-grid-rows-2'
      } rf-items-center`}
    >
      {/* Vocal mode button */}
      {!disabledSpeechMode && (
        <div className='rf-w-full rf-flex rf-justify-center'>
          {mode === ModeEnum.text ? (
            <button
              className='rf-w-full rf-aspect-square rf-flex rf-justify-center rf-items-center rf-border rf-border-menuBorder rf-rounded-half rf-shadow-[0_0_20px_1px_#A4A7AF80]'
              onClick={(): void => toggleMode(ModeEnum.vocal)}
              aria-label='vocal mode'
            >
              <MicrophoneIcon
                className='rf-h-5 rf-w-5 tactile:rf-h-6 tactile:rf-w-6'
                color='#A4A7AF'
              />
            </button>
          ) : (
            <MicrophoneButton openMicrophone={fromTextToVocal} />
          )}
        </div>
      )}

      {/* Text mode button */}
      <button
        className='rf-flex rf-justify-center rf-items-center'
        onClick={(): void => toggleMode(ModeEnum.text)}
        aria-label='text mode'
      >
        <KeyboardIcon
          className='rf-h-5 rf-w-5 large-vertical:rf-h-6 large-vertical:rf-w-6'
          color={mode === ModeEnum.text ? themeColors.secondary : '#A4A7AF'}
        />
      </button>
    </div>
  )
}

export default Mode
