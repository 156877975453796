/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useUtilsStore } from '../../Contexts/utilsStore'
import { useViewStore } from '../../Contexts/viewStore'
import { useWidgetStore } from '../../Contexts/widgetStore'
import { useRetorikStore } from '../../Contexts/retorikStore'

import useActivitiesTextMode from '../../../hooks/useActivitiesTextMode'

import type {
  HistoryActivity,
  RetorikActivity
} from '../../../models/activityTypes'
import { DeviceType } from '../../../models/enums'
import preventEvents from '../../../utils/preventEvents'

import ConversationHeader from '../../Menu/Mobile/ConversationHeader'
import ScrollButton from '../ScrollButton'
import Attachments from '../../Attachments/Attachments'
import ConversationDisplay from './ConversationDisplay'

interface ConversationPanelMobileProps {
  homeActivity: RetorikActivity | undefined
  itemsInConversation: number
}

const ConversationPanelMobile = ({
  homeActivity,
  itemsInConversation
}: ConversationPanelMobileProps): JSX.Element => {
  const activities = useActivitiesTextMode()
  const configuration = useRetorikStore((state) => state.configuration)
  const agentData = useRetorikStore((state) => state.agentData)
  const currentDeviceType = useViewStore((state) => state.currentDeviceType)
  const isWidget = useViewStore((state) => state.isWidget)
  const listClosed = useUtilsStore((state) => state.listClosed)
  const open = useWidgetStore((state) => state.open)

  const [opacityStyle, setOpacityStyle] = useState<string | undefined>('')
  const [scrollButtonDisplay, setScrollButtonDisplay] = useState<boolean>(false)
  const [scrollButtonDirection, setScrollButtonDirection] = useState<
    'top' | 'bottom'
  >('bottom')
  const [preventScroll, setPreventScroll] = useState<boolean>(false)

  const retorikConversationRef = useRef<HTMLDivElement>(null)
  const botEndRef = useRef<HTMLDivElement>(null)
  const timerRef = useRef<NodeJS.Timeout | null>(null)

  const handleScroll = (): void => {
    if (retorikConversationRef.current) {
      const current = retorikConversationRef.current as HTMLDivElement

      if (current.scrollHeight > current.offsetHeight) {
        setScrollButtonDisplay(true)
        if (current.scrollHeight - current.scrollTop === current.clientHeight) {
          setScrollButtonDirection('top')
        } else if (
          current.scrollHeight - current.scrollTop <
          current.clientHeight + 10
        ) {
          setScrollButtonDirection('top')
        } else {
          setScrollButtonDirection('bottom')
        }
      }

      const percentage = Math.ceil(
        (current.scrollTop / (current.scrollHeight - current.clientHeight)) * 15
      )
      setOpacityStyle(
        `linear-gradient(to top, black ${100 - percentage}%, transparent 100%)`
      )
    }
  }

  /**
   * Scroll to the botEndRef HTML element at the bottom of the view
   */
  const scrollHandler = (
    to: 'bottom' | 'top',
    from: 'newMessage' | 'button'
  ): void => {
    if (to === 'bottom') {
      setScrollButtonDirection('top')
    } else {
      setScrollButtonDirection('bottom')
    }
    if (
      (!preventScroll || from === 'button') &&
      botEndRef?.current &&
      to === 'bottom'
    ) {
      botEndRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      })
    } else if (to === 'top' && retorikConversationRef?.current) {
      retorikConversationRef.current.children[1].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      })
    }
  }

  useEffect(() => {
    timerRef.current && clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => {
      scrollHandler('bottom', 'newMessage')
    }, 100)

    return (): void => {
      timerRef.current && clearTimeout(timerRef.current)
    }
  }, [itemsInConversation, retorikConversationRef?.current?.scrollHeight])

  useLayoutEffect(() => {
    if (retorikConversationRef?.current) {
      isWidget && preventEvents(null, retorikConversationRef)
    }
  })

  /**
   * On retorikConversationRef change :
   *  - check if retorikConversationRef.current is defined
   *  - if scrollHeight > clientHeight, call handleScroll
   *  - if scrollHeight <= clientHeight, set opacityStyle state to undefined
   */
  useEffect(() => {
    if (retorikConversationRef.current) {
      if (
        retorikConversationRef.current.scrollHeight >
        retorikConversationRef.current.clientHeight
      ) {
        handleScroll()
      } else {
        setOpacityStyle(undefined)
      }
    }
  }, [retorikConversationRef?.current])

  useEffect(() => {
    if ((listClosed || homeActivity) && botEndRef.current) {
      timerRef.current && clearTimeout(timerRef.current)
      timerRef.current = setTimeout(() => {
        scrollHandler('bottom', 'newMessage')
      }, 100)
    }
  }, [listClosed, homeActivity])

  return (
    <div className='rf-relative rf-max-h-full rf-h-full rf-max-w-full rf-w-full rf-col-start-1 rf-col-span-full rf-row-start-1 rf-row-end-13 rf-self-start rf-overflow-hidden rf-justify-self-center rf-flex rf-flex-col rf-bg-textModePanelBackground'>
      <ConversationHeader
        agentName={agentData?.name}
        companyName={configuration?.companyName}
        companyNameLocalized={configuration?.companyNameLocalized}
        subCompanyMessage={configuration?.subCompanyMessage}
        subCompanyMessageLocalized={configuration?.subCompanyMessageLocalized}
        imageUrl={agentData?.portrait}
      />
      <div
        ref={retorikConversationRef}
        id='retorik-conversation'
        className='rf-relative rf-max-h-full rf-h-full rf-py-4 rf-overflow-y-scroll rf-scrollbar-thin rf-self-auto rf-mb-26 rf-overflow-x-auto rf-justify-self-center rf-flex rf-flex-col rf-text-size-auto rf-text-trueblack rf-bg-textModePanelBackground rf-shadow-[3px_3px_6px_#00000029]'
        style={{
          WebkitMaskImage:
            currentDeviceType === DeviceType.mobile ? opacityStyle : undefined
        }}
        onScroll={handleScroll}
      >
        {/* Button to scroll to top / bottom of the conversation */}
        <ScrollButton
          direction={scrollButtonDirection}
          display={scrollButtonDisplay && open}
          scrollHandler={scrollHandler}
          setPreventScroll={setPreventScroll}
          className='rf-fixed rf-z-ui rf-bottom-40 rf-right-6 rf-w-9 rf-h-9 rf-cursor-pointer rf-bg-[#00000050] rf-p-[7px_12px] rf-rounded-[20px] rf-flex'
        />

        {/* All answers / responses */}
        {activities.map((activity: HistoryActivity, index: number) => {
          const userQuestionTime = activity.question
            ? activity.question.localTimestamp
              ? new Date(activity.question.localTimestamp)
              : activity.question.timestamp
              ? new Date(activity.question.timestamp)
              : new Date()
            : undefined

          return (
            <div key={index} className='rf-pt-3'>
              <ConversationDisplay
                userQuestion={activity.question}
                botResponses={activity.answers}
                userQuestionTime={userQuestionTime}
                isMobile={true}
                triggerScroll={() => scrollHandler('bottom', 'newMessage')}
                canBeStreaming={index === activities.length - 1}
              />
            </div>
          )
        })}

        {/* Home activity attachments */}
        {homeActivity?.attachments?.length ? (
          <div className='rf-pt-4 rf-flex rf-flex-col rf-text-size-auto rf-gap-2'>
            <Attachments activity={homeActivity} />
          </div>
        ) : (
          <React.Fragment />
        )}
        <div ref={botEndRef} />
      </div>
    </div>
  )
}

export default ConversationPanelMobile
