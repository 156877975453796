// Check if we should let the event propagate. For example leaflet events should pass to allow map panning
const checkShouldPropagate = (e: WheelEvent | TouchEvent): boolean => {
  if (e.target) {
    try {
      const className = (e.target as HTMLElement).className
      return className && typeof className === 'string'
        ? className.includes('leaflet')
        : false
    } catch {
      return false
    }
  } else {
    return false
  }
}

const handleStop = (e: WheelEvent | TouchEvent): void => {
  !checkShouldPropagate(e) && e.cancelable && e.preventDefault()
}

const handleRefStop = (
  e: WheelEvent | TouchEvent,
  ref: React.RefObject<HTMLDivElement>
): void => {
  if (!checkShouldPropagate(e) && e.cancelable && ref.current) {
    const scrollHeight = ref.current.scrollHeight
    const scrollTop = ref.current.scrollTop
    const clientHeight = ref.current.clientHeight

    // If no overflow, prevent event default
    if (scrollHeight === clientHeight) {
      e.preventDefault()
    }
    // Wheel event, deltaY gives the vertical direction
    else if (e.type === 'wheel') {
      scrollTop === 0
        ? (e as WheelEvent).deltaY < 0 && e.preventDefault()
        : scrollTop + clientHeight >= scrollHeight - 5 &&
          (e as WheelEvent).deltaY > 0 &&
          e.preventDefault()
    }
    // Touch event, we need an origin to calculate the direction of the movement
    else if (e.type === 'touchmove') {
      if ((e as TouchEvent).touches.length > 1) {
        e.preventDefault()
      } else {
        const positionInLocalStorage = localStorage.getItem(
          'Retorik.Framework.TouchY'
        )
        const origin = positionInLocalStorage
          ? parseInt(positionInLocalStorage)
          : null

        if (origin && !isNaN(origin)) {
          const currentY =
            (e as TouchEvent).touches.length > 0
              ? (e as TouchEvent).touches[0].screenY
              : null

          if (currentY || currentY === 0) {
            const deltaY = origin - currentY
            scrollTop === 0
              ? deltaY < 0 && e.preventDefault()
              : scrollTop + clientHeight >= scrollHeight - 5 &&
                deltaY > 0 &&
                e.preventDefault()
          } else {
            e.preventDefault()
          }
        } else {
          e.preventDefault()
        }
      }
    }

    e.stopImmediatePropagation()
  }
}

const handleTouch = (e: TouchEvent): void => {
  localStorage.setItem('Retorik.Framework.TouchY', `${e.touches[0].screenY}`)
  e.touches.length > 1 &&
    !checkShouldPropagate(e) &&
    e.cancelable &&
    e.preventDefault()
}

/**
 * Attach wheel | touch events on either a HTMLElement or a reference to a HTMLDivElement to prevent the propagation of this events to parent window.
 * @param {HTMLElement | null} element HTMLElement to which we will attach the events
 * @param {React.RefObject<HTMLDivElement>} ref reference to the HTMLDivElement to which we will attach the events
 * @param {AbortSignal} signal abort signal to remove events when needed
 */
const preventEvents = (
  element: HTMLElement | null,
  ref?: React.RefObject<HTMLDivElement>,
  signal?: AbortSignal
): void => {
  // Add abort signal to options if present
  const options = signal
    ? {
        passive: false,
        signal: signal
      }
    : {
        passive: false
      }

  if (ref?.current) {
    try {
      ref.current.removeEventListener('wheel', (e) => handleRefStop(e, ref))
      ref.current.removeEventListener('touchstart', (e) => handleTouch(e))
      ref.current.removeEventListener('touchmove', (e) => handleRefStop(e, ref))
    } catch (err) {
      console.warn(err)
    }

    ref.current.addEventListener('wheel', (e) => handleRefStop(e, ref), options)
    ref.current.addEventListener('touchstart', (e) => handleTouch(e), options)
    ref.current.addEventListener(
      'touchmove',
      (e) => handleRefStop(e, ref),
      options
    )
  } else if (element) {
    element.addEventListener('wheel', (e) => handleStop(e), options)
    element.addEventListener('touchstart', (e) => handleTouch(e), options)
    element.addEventListener('touchmove', (e) => handleStop(e), options)
  }
}

export default preventEvents
