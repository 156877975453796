import React, {
  useState,
  useEffect,
  ReactEventHandler,
  useRef,
  useMemo
} from 'react'
import Webcam from 'react-webcam'
import { useViewStore } from '../Contexts/viewStore'
import useRefDimensions from '../../hooks/useRefDimensions'
import type { BackgroundOptions } from '../../models/types'
import BackgroundVideo from '../Utils/BackgroundVideo'

type BackgroundProps = {
  config: BackgroundOptions
  videoLoop?: boolean
  videoMuted?: boolean
  onVideoEnded?: ReactEventHandler<HTMLVideoElement>
  onVideoCanPlay?: ReactEventHandler<HTMLVideoElement>
}

const Background = ({
  config,
  videoLoop,
  videoMuted,
  onVideoEnded,
  onVideoCanPlay
}: BackgroundProps): JSX.Element => {
  const webcamRotation = useViewStore(
    (state) => state.configurations
  ).webcamRotation
  const [isWebcamAllowed, setIsWebcamAllowed] = useState<boolean>(false)
  const imageRef = useRef<HTMLImageElement>(null)
  const dimensions = useRefDimensions(imageRef)

  const [source, setSource] = useState<{
    facingMode?: string | { exact: string }
    deviceId?: string
  }>({})
  const [videoDimensions, setVideoDimensions] = useState<{
    width?: number
    height?: number
  }>(dimensions)
  const [rotation, setRotation] = useState<string>('')
  const [webcamSizeStyle, setWebcamSizeStyle] = useState<{
    width?: string
    height?: string
  }>({})
  const blurValue = useMemo<React.CSSProperties>(() => {
    return {
      '--rf-blur-background':
        config?.blur !== undefined ? `${config.blur}px` : '0'
    } as React.CSSProperties
  }, [config?.blur])

  useEffect(() => {
    if (config.style === 'webcam') {
      if (isWebcamAllowed) {
        if (config.webcam) {
          switch (config.webcam) {
            case 'back':
              setSource({
                facingMode: { exact: 'environment' },
                deviceId: undefined
              })
              break
            case 'front':
            case undefined:
              setSource({
                facingMode: 'user',
                deviceId: undefined
              })
              break
            default:
              setSource({
                facingMode: undefined,
                deviceId: config.webcam
              })
          }
        }
      } else {
        askForWebcamPermission()
      }
    }
  }, [config, isWebcamAllowed])

  const askForWebcamPermission = async (): Promise<void> => {
    navigator?.mediaDevices &&
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(() => {
          setIsWebcamAllowed(true)
        })
        .catch((error) => {
          console.log(error)
          setIsWebcamAllowed(false)
        })
  }

  useEffect(() => {
    if (config.style === 'webcam') {
      if (!webcamRotation || webcamRotation % 180 === 0) {
        setVideoDimensions(dimensions)
      } else {
        setVideoDimensions({
          height: dimensions.width,
          width: dimensions.height
        })
      }
    }
  }, [dimensions])

  useEffect(() => {
    switch (webcamRotation) {
      case 90:
        setRotation('rf-rotate-90 rf-origin-center')
        setWebcamSizeStyle({
          width: '100vh',
          height: '100vw'
        })
        break
      case -90:
        setRotation('rf--rotate-90 rf-origin-center')
        setWebcamSizeStyle({
          width: '100vh',
          height: '100vw'
        })
        break
      case 180:
        setRotation('rf-rotate-180 rf-origin-center')
        setWebcamSizeStyle({})
        break
      default:
        setRotation('')
        setWebcamSizeStyle({})
        break
    }
  }, [webcamRotation])

  return (
    <React.Fragment>
      {config.image && (
        <img
          ref={imageRef}
          src={config.image}
          alt='background image'
          className='rf-col-span-full rf-row-span-full rf-w-full rf-h-full rf-object-cover rf-filter rf-blur-background'
          style={blurValue}
        />
      )}
      {config.style === 'video' && config.video && (
        <BackgroundVideo
          source={config.video}
          loop={!!videoLoop}
          muted={!!videoMuted}
          onVideoEnded={onVideoEnded}
          onVideoCanPlay={onVideoCanPlay}
          style={blurValue}
        />
      )}
      {config.style === 'webcam' && isWebcamAllowed && (
        <Webcam
          className={`rf-relative rf-col-span-full rf-row-span-full rf-overflow-hidden rf-max-w-none rf-w-screen rf-h-screen rf-filter rf-blur-background rf-border rf-self-center rf-justify-self-center rf-object-cover ${rotation}`}
          {...videoDimensions}
          style={{
            ...blurValue,
            ...webcamSizeStyle
          }}
          audio={false}
          videoConstraints={{ ...source, ...videoDimensions }}
        />
      )}
    </React.Fragment>
  )
}

export default Background
