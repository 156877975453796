import React, { useEffect, useMemo, useState } from 'react'

import { useLocaleStore } from '../Contexts/localeStore'
import { useViewStore } from '../Contexts/viewStore'
import { useUtilsStore, lockClickedButton } from '../Contexts/utilsStore'
import { sendMessage } from '../Contexts/directLineStore'

import type {
  RecommandationButton,
  DiscoverContentType,
  ImageButton
} from '../../models/attachmentTypes'
import { AnimationSize } from '../../models/enums'
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter'

import SvgColorChanger from './Utils/SvgColorChanger'
import Animation from '../Loader/Animation'

const DiscoverAttachment = ({
  title,
  mainButtons,
  recommandationButton,
  history,
  height
}: DiscoverContentType): JSX.Element => {
  const locale = useLocaleStore((state) => state.locale)
  const isMobile = useViewStore((state) => state.isMobile)
  const themeColors = useViewStore((state) => state.themeColors)
  const unlockClickedButton = useUtilsStore(
    (state) => state.unlockClickedButton
  )

  const displayLayout = useMemo<string>(() => {
    if (mainButtons && mainButtons.length > 1) {
      return `rf-w-full rf-grid rf-grid-cols-${mainButtons.length} rf-gap-2 large:rf-gap-4`
    }

    return 'rf-w-1/2 rf-justify-self-center'
  }, [mainButtons])
  const [clicked, setClicked] = useState<boolean>(false)
  const [discoverClicked, setDiscoverClicked] = useState<number | null>(null)

  const animationColor = {
    '--rf-color-loader-animation': themeColors.card.button.text.default
  } as React.CSSProperties

  const handleClick = (
    data: RecommandationButton | ImageButton,
    index?: number
  ): void => {
    if (discoverClicked === null || discoverClicked !== index) {
      lockClickedButton()
      let dataToSend: any = {}
      // @ts-ignore
      if (data.eventName) {
        const tempData = data as RecommandationButton
        setClicked(true)
        dataToSend = {
          type: 'event',
          name: tempData.eventName,
          recommendationId: tempData.recommendationId
        }
      } else if (index !== undefined) {
        setDiscoverClicked(index)
        const tempData = data as ImageButton

        dataToSend = {
          type: 'event',
          name: tempData.EventName,
          value: {
            filterId: tempData.FilterId,
            filterValue: tempData.FilterValue,
            followingCardText: tempData.FollowingCardText,
            identifier: tempData.Identifier
          }
        }
      }

      sendMessage(undefined, dataToSend)
    }
  }

  useEffect(() => {
    if (unlockClickedButton) {
      setClicked(false)
      setDiscoverClicked(null)
    }
  }, [unlockClickedButton])

  return (
    <div
      className={`rf-w-full rf-flex rf-flex-col rf-items-center rf-justify-between rf-rounded rf-bg-cardFrameBackground large:rf-p-[25px] rf-p-4 ${
        history && 'rf-pointer-events-none'
      } `}
      style={{
        height: height
      }}
    >
      <div className='rf-w-full rf-min-h-inherit rf-rounded rf-bg-cardFrameBackground rf-text-cardFrameText rf-title-size-auto rf-mb-3 rf-text-center'>
        {capitalizeFirstLetter(title, locale)}
      </div>

      <div className={`rf-h-full ${displayLayout}`}>
        {mainButtons && mainButtons.length > 0 ? (
          mainButtons.map((button, i) => {
            let colorButtonHandle = ''
            if (button.EventName.indexOf('TouristAttractions') > -1) {
              colorButtonHandle = themeColors.tobeDoneColor
            } else if (button.EventName.indexOf('Products') > -1) {
              colorButtonHandle = themeColors.localProductsColor
            } else if (button.EventName.indexOf('FoodEstablishments') > -1) {
              colorButtonHandle = themeColors.whereToEatColor
            } else if (button.EventName.indexOf('LodgingBusinesses') > -1) {
              colorButtonHandle = themeColors.whereToSleepColor
            } else if (button.EventName.indexOf('TouristDestinations') > -1) {
              colorButtonHandle = themeColors.tobeSeenColor
            } else if (button.EventName.indexOf('Event') > -1) {
              colorButtonHandle = themeColors.agendaColor
            }

            return (
              <div
                key={i}
                className='rf-relative rf-w-full rf-card-button-discover rf-rounded rf-flex rf-flex-col rf-gap-2 large:rf-gap-3 rf-items-center rf-justify-self-center'
                onClick={(): any => handleClick(button, i)}
                style={{
                  boxShadow: '0px 0px 13px #0000009c'
                }}
              >
                {discoverClicked === i ? (
                  <div
                    className='rf-w-full rf-h-full rf-flex rf-justify-center rf-items-center'
                    style={{
                      ...animationColor
                    }}
                  >
                    <div className='rf-w-4 rf-h-4 large:rf-w-6 large:rf-h-6'>
                      <Animation
                        size={
                          isMobile ? AnimationSize.medium : AnimationSize.large
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <React.Fragment>
                    {button.MediaUrl.indexOf('svg') > -1 &&
                    colorButtonHandle !== '' ? (
                      <SvgColorChanger
                        svgUrl={button.MediaUrl}
                        height='50px'
                        fillColor={colorButtonHandle}
                      />
                    ) : (
                      <img
                        src={button.MediaUrl}
                        alt={button.Text}
                        style={{ height: '50px' }}
                      />
                    )}
                    <p className='rf-h-full rf-flex rf-items-center rf-text-center rf-uppercase rf-font-bold large:rf-text-basecustom rf-text-xsl rf-overflow-wrap'>
                      {button.Text}
                    </p>
                  </React.Fragment>
                )}
              </div>
            )
          })
        ) : (
          <></>
        )}
      </div>

      {/* Subtitle 2 lines max / title 2 lines max */}
      {recommandationButton && mainButtons && mainButtons.length > 1 && (
        <div
          className={`rf-mt-4 rf-font-bold ${
            clicked ? 'rf-card-button-clicked' : 'rf-card-button'
          } rf-text-center rf-capitalize-first`}
          onClick={(): any => handleClick(recommandationButton)}
        >
          {clicked ? (
            <div
              className='rf-w-full rf-h-[1.25rem] large:rf-h-[1.3rem] rf-flex rf-justify-center rf-items-center'
              style={{
                ...animationColor
              }}
            >
              <div className='rf-w-4 rf-h-4 large:rf-w-6 large:rf-h-6'>
                <Animation
                  size={isMobile ? AnimationSize.medium : AnimationSize.large}
                />
              </div>
            </div>
          ) : (
            recommandationButton.text
          )}
        </div>
      )}
    </div>
  )
}

export default DiscoverAttachment
