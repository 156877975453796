import React, { useEffect, useMemo, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'

import {
  useViewStore,
  setCurrentDeviceType,
  setCurrentHeight,
  setCurrentWidth
} from '../Contexts/viewStore'
import { setPaginationToSend } from '../Contexts/directLineStore'
import { useRetorikStore } from '../Contexts/retorikStore'

import useRefDimensions from '../../hooks/useRefDimensions'
import useCurrentPagination from '../../hooks/useCurrentPagination'

import type { WithChildren } from '../../models/utils'
import { ContainerParent, DeviceType } from '../../models/enums'
import { mobileBreakpoint, widgetDisplays } from '../../models/constants'
import preventEvents from '../../utils/preventEvents'

import ModalFullscreen from '../Utils/ModalFullscreen'

type ContainerProps = WithChildren<{
  fullSize: boolean
  width?: number | string
  height?: number | string
  parent: number
}>

const Container = ({
  fullSize,
  width,
  height,
  children,
  parent
}: ContainerProps): JSX.Element => {
  const themeColors = useViewStore((state) => state.themeColors)
  const currentDeviceType = useViewStore((state) => state.currentDeviceType)
  const isTactile = useViewStore((state) => state.isTactile)
  const doNotDetectDeviceFromUserAgent = useRetorikStore(
    (state) => state.configuration.doNotDetectDeviceFromUserAgent
  )
  const forceMobileView = useRetorikStore(
    (state) => state.configuration.forceMobileView
  )

  const container = useRef<HTMLDivElement>(null)
  const dimensions = useRefDimensions(container)
  const [portrait, setPortrait] = useState<string>('')
  const [large, setLarge] = useState<string>('')
  // Call useCurrentPagination to update data sent to store. Do not remove
  const pagination = useCurrentPagination()

  const widthStyle = useMemo<{ width?: string }>(() => {
    if (typeof width === 'number') {
      return { width: `${width}px` }
    } else if (typeof width === 'string') {
      return { width: width === 'full' ? '100%' : width }
    }

    return {}
  }, [width])

  const heightStyle = useMemo<{
    height?: string | number
    flexGrow?: number
    minHeight?: number
    maxHeight?: number
  }>(() => {
    if (currentDeviceType === DeviceType.mobile) {
      return {
        height: window.innerHeight,
        minHeight: window.innerHeight,
        maxHeight: window.innerHeight
      }
    } else if (typeof height === 'number') {
      return { height: `${height}px` }
    } else if (typeof height === 'string') {
      return height === 'full'
        ? { height: '100%', flexGrow: 1 }
        : { height: height }
    }

    return {}
  }, [height, currentDeviceType])

  useEffect(() => {
    setPaginationToSend(pagination)
  }, [pagination])

  const checkPortrait = (width: number, height: number): boolean => {
    if (width && height && width < height) {
      setPortrait('rf-portrait')
    } else {
      setPortrait('')
      return false
    }

    return true
  }

  /**
   * On dimensions change :
   *  - check and set the classes in the main div that will allow us to use tailwind variants
   *  - set the main div dimensions in context
   */
  useEffect(() => {
    if (forceMobileView) {
      setLarge('')
      setCurrentDeviceType(DeviceType.mobile)
      setPortrait('rf-portrait')
    } else if (dimensions?.width && dimensions?.height) {
      // If we don't want to use the userAgent to get the device type
      if (doNotDetectDeviceFromUserAgent) {
        const isPortrait = checkPortrait(dimensions.width, dimensions.height)

        if (isPortrait) {
          if (dimensions.width > mobileBreakpoint) {
            setLarge('rf-large')
            setCurrentDeviceType(
              parent === ContainerParent.widget
                ? DeviceType.widgetBorne
                : DeviceType.borne
            )
          } else {
            setLarge('')
            setCurrentDeviceType(
              parent === ContainerParent.widget
                ? DeviceType.widget
                : DeviceType.mobile
            )
          }
        } else {
          if (dimensions.height > mobileBreakpoint) {
            setLarge('rf-large')
            setCurrentDeviceType(
              parent === ContainerParent.widget
                ? DeviceType.widgetLandscape
                : DeviceType.landscape
            )
          } else {
            setLarge('')
            setCurrentDeviceType(
              parent === ContainerParent.widget
                ? DeviceType.widget
                : DeviceType.mobile
            )
          }
        }
      }
      // If we use the userAgent to get the device type
      else {
        if (isMobile) {
          setPortrait('rf-portrait')
          setLarge('')
          setCurrentDeviceType(
            parent === ContainerParent.widget
              ? DeviceType.widget
              : DeviceType.mobile
          )
        } else {
          const isPortrait = checkPortrait(dimensions.width, dimensions.height)

          // Check for 'rf-large' class
          setLarge(
            parent === ContainerParent.widget &&
              dimensions.width < mobileBreakpoint
              ? ''
              : 'rf-large'
          )
          if (isPortrait) {
            setCurrentDeviceType(
              parent === ContainerParent.widget
                ? dimensions.width > mobileBreakpoint
                  ? DeviceType.widgetBorne
                  : DeviceType.widget
                : DeviceType.borne
            )
          } else {
            setCurrentDeviceType(
              parent === ContainerParent.widget
                ? DeviceType.widgetLandscape
                : DeviceType.landscape
            )
          }
        }
      }
    }

    dimensions?.height && setCurrentHeight(dimensions.height)
    dimensions?.width && setCurrentWidth(dimensions.width)
  }, [dimensions])

  useEffect(() => {
    container?.current &&
      widgetDisplays.includes(currentDeviceType) &&
      preventEvents(container.current)
  }, [container.current])

  const customColors = useMemo<React.CSSProperties>(() => {
    return themeColors
      ? ({
          '--rf-color-primary': themeColors.primary,
          '--rf-color-secondary': themeColors.secondary,
          '--rf-color-black': themeColors.black,
          '--rf-color-whereToEatColor': themeColors.whereToEatColor,
          '--rf-color-whereToSleepColor': themeColors.whereToSleepColor,
          '--rf-color-tobeSeenColor': themeColors.tobeSeenColor,
          '--rf-color-tobeDoneColor': themeColors.tobeDoneColor,
          '--rf-color-localProductsColor': themeColors.localProductsColor,
          '--rf-color-servicesColor': themeColors.servicesColor,
          '--rf-color-cardFrameBackground': themeColors.card?.frame?.background,
          '--rf-color-cardFrameBorder': themeColors.card?.frame?.border,
          '--rf-color-cardFrameText': themeColors.card?.frame?.text,
          '--rf-color-cardButtonBackgroundDefault':
            themeColors.card?.button?.background?.default,
          '--rf-color-cardButtonBackgroundHover':
            themeColors.card?.button?.background?.hover,
          '--rf-color-cardButtonBorderDefault':
            themeColors.card?.button?.border?.default,
          '--rf-color-cardButtonBorderHover':
            themeColors.card?.button?.border?.hover,
          '--rf-color-cardButtonTextDefault':
            themeColors.card?.button?.text?.default,
          '--rf-color-cardButtonTextHover':
            themeColors.card?.button?.text?.hover,
          '--rf-color-cardButtonDiscoverBackgroundDefault':
            themeColors.card?.discoverButton?.background?.default,
          '--rf-color-cardButtonDiscoverBackgroundHover':
            themeColors.card?.discoverButton?.background?.hover,
          '--rf-color-cardButtonDiscoverBorderDefault':
            themeColors.card?.discoverButton?.border?.default,
          '--rf-color-cardButtonDiscoverBorderHover':
            themeColors.card?.discoverButton?.border?.hover,
          '--rf-color-cardButtonDiscoverTextDefault':
            themeColors.card?.discoverButton?.text?.default,
          '--rf-color-cardButtonDiscoverTextHover':
            themeColors.card?.discoverButton?.text?.hover,
          '--rf-color-textModePanelBackground':
            themeColors.textMode?.panel?.background,
          '--rf-color-textModePanelBorder': themeColors.textMode?.panel?.border,
          '--rf-color-textModePanelConversationUser':
            themeColors.textMode?.panel?.conversationUser,
          '--rf-color-textModePanelConversationBot':
            themeColors.textMode?.panel?.conversationBot,
          '--rf-color-vocalModeSubtitlesText':
            themeColors.vocalMode?.subtitles?.text,
          '--rf-color-vocalModeSubtitlesBackground':
            themeColors.vocalMode?.subtitles?.background,
          '--rf-color-formInputTextDefault':
            themeColors.formInput?.text?.default,
          '--rf-color-formInputTextHover': themeColors.formInput?.text?.hover,
          '--rf-color-formInputRadioCheckboxUncheckedBackground':
            themeColors.formInput?.inputRadioCheckbox?.unchecked?.background,
          '--rf-color-formInputRadioCheckboxUncheckedBorder':
            themeColors.formInput?.inputRadioCheckbox?.unchecked?.border,
          '--rf-color-formInputRadioCheckboxCheckedBackground':
            themeColors.formInput?.inputRadioCheckbox?.checked?.background,
          '--rf-color-formInputRadioCheckboxCheckedBorder':
            themeColors.formInput?.inputRadioCheckbox?.checked?.border,
          '--rf-color-formInputRadioCheckboxCheckedItem':
            themeColors.formInput?.inputRadioCheckbox?.checked?.item,
          '--rf-color-formInputButtonBackgroundDefault':
            themeColors.formInput?.inputButton?.background?.default,
          '--rf-color-formInputButtonBackgroundHover':
            themeColors.formInput?.inputButton?.background?.hover,
          '--rf-color-formInputButtonBorderDefault':
            themeColors.formInput?.inputButton?.border?.default,
          '--rf-color-formInputButtonBorderHover':
            themeColors.formInput?.inputButton?.border?.hover,
          '--rf-color-formInputButtonTextDefault':
            themeColors.formInput?.inputButton?.text?.default,
          '--rf-color-formInputButtonTextHover':
            themeColors.formInput?.inputButton?.text?.hover
        } as React.CSSProperties)
      : ({} as React.CSSProperties)
  }, [themeColors])

  return (
    <div
      id='retorik-container'
      ref={container}
      className={`rf-relative ${
        fullSize ? 'rf-w-screen rf-h-screen' : 'rf-w-full rf-h-full'
      } rf-max-w-screen rf-max-h-screen rf-grid rf-grid-cols-8 rf-grid-rows-container rf-bg-transparent rf-text-trueblack rf-font-default ${portrait} ${large} ${
        isTactile && 'rf-tactile'
      } rf-overflow-hidden rf-animate-loaderFadeIn`}
      style={{
        ...widthStyle,
        ...heightStyle,
        ...customColors
      }}
    >
      <ModalFullscreen />
      {children}
    </div>
  )
}

export default Container
