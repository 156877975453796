import React, { useEffect, useRef, useState, useMemo } from 'react'
import { useRetorikStore } from '../../Contexts/retorikStore'
import { useViewStore } from '../../Contexts/viewStore'
import DownloadSheet from './DownloadSheet'
import DownloadQR from './DownloadQR'

type DetailedTextProps = {
  text?: string
  otherInformations?: Array<{
    title: string
    content: string | Array<string>
  }>
  color?: string
  url?: string
  titleHeight: number
}

const DownloadQRHeight = 200
const DownloadSheetHeight = 100

const DetailedText = ({
  text,
  otherInformations,
  color,
  url,
  titleHeight
}: DetailedTextProps): JSX.Element => {
  const isUsedOnBorne = useRetorikStore(
    (state) => state.configuration.isUsedOnBorne
  )
  const isMobile = useViewStore((state) => state.isMobile)
  const isLandscape = useViewStore((state) => state.isLandscape)
  const currentHeight = useViewStore((state) => state.currentHeight)

  const textRef = useRef<HTMLDivElement>(null)
  const otherInformationsRef = useRef<HTMLDivElement>(null)
  const [opacityStyle, setOpacityStyle] = useState<string | undefined>('')
  const [otherOpacityStyle, setOtherOpacityStyle] = useState<
    string | undefined
  >('')
  const processedText = useMemo<string>(() => {
    if (text) {
      return text.replaceAll('\n', '<br>')
    }

    return ''
  }, [text])

  const textContainerMaxHeight = useMemo<number>(() => {
    return (
      currentHeight -
      titleHeight -
      (isUsedOnBorne ? DownloadQRHeight : DownloadSheetHeight)
    )
  }, [titleHeight])

  const [textMaxHeight, setTextMaxHeight] = useState<number>(0)
  const [otherInformationsMaxHeight, setOtherInformationsMaxHeight] =
    useState<number>(0)

  useEffect(() => {
    if (
      textRef?.current &&
      otherInformationsRef?.current &&
      textContainerMaxHeight &&
      !isMobile
    ) {
      const textHeight = isLandscape
        ? (textContainerMaxHeight * 58) / 100
        : (currentHeight * 30) / 100

      const otherInformationsHeight = isLandscape
        ? (textContainerMaxHeight * 40) / 100
        : (currentHeight * 18) / 100

      if (textRef.current.scrollHeight > textHeight) {
        if (
          otherInformationsRef.current.scrollHeight > otherInformationsHeight
        ) {
          setTextMaxHeight(textHeight)
          setOtherInformationsMaxHeight(otherInformationsHeight)
        } else {
          setTextMaxHeight(
            textContainerMaxHeight - otherInformationsRef.current.scrollHeight
          )
          setOtherInformationsMaxHeight(otherInformationsHeight)
        }
      } else if (
        otherInformationsRef.current.scrollHeight > otherInformationsHeight
      ) {
        setTextMaxHeight(textHeight)
        setOtherInformationsMaxHeight(
          textContainerMaxHeight - textRef.current.scrollHeight
        )
      } else {
        setTextMaxHeight(textHeight)
        setOtherInformationsMaxHeight(otherInformationsHeight)
      }
    }
  }, [textRef?.current, otherInformationsRef?.current, textContainerMaxHeight])

  useEffect(() => {
    if (textRef?.current && textMaxHeight) {
      if (
        !isMobile &&
        textRef.current.scrollHeight > textMaxHeight &&
        textRef.current.scrollHeight > textRef.current.clientHeight
      ) {
        handleScroll()
      } else {
        setOpacityStyle(undefined)
      }
    }
  }, [textRef?.current, textMaxHeight])

  useEffect(() => {
    if (otherInformationsRef?.current && otherInformationsMaxHeight) {
      if (
        !isMobile &&
        otherInformationsRef.current.scrollHeight >
          otherInformationsMaxHeight &&
        otherInformationsRef.current.scrollHeight >
          otherInformationsRef.current.clientHeight
      ) {
        handleOtherScroll()
      } else {
        setOtherOpacityStyle(undefined)
      }
    }
  }, [otherInformationsRef?.current, otherInformationsMaxHeight])

  /**
   * On call :
   *  - check if textRef.current is defined
   *  - set opacityStyle state depending on scroll height and current scrolling
   */
  const handleScroll = (): void => {
    if (textRef.current) {
      const maxScroll =
        textRef.current.scrollHeight - textRef.current.offsetHeight
      const percentage = Math.ceil((textRef.current.scrollTop / maxScroll) * 40)
      setOpacityStyle(
        `linear-gradient(to bottom, black ${
          60 + percentage
        }%, transparent 100%)`
      )
    }
  }

  const handleOtherScroll = (): void => {
    if (otherInformationsRef.current) {
      const maxScroll =
        otherInformationsRef.current.scrollHeight -
        otherInformationsRef.current.offsetHeight
      const percentage = Math.ceil(
        (otherInformationsRef.current.scrollTop / maxScroll) * 30
      )
      setOtherOpacityStyle(
        `linear-gradient(to bottom, black ${
          70 + percentage
        }%, transparent 100%)`
      )
    }
  }

  return (
    <div className='rf-flex rf-flex-col large:rf-pr-4 large:rf-min-w-1/2 rf-gap-4'>
      <div
        className='rf-w-full rf-flex rf-flex-col rf-items-start'
        style={{
          maxHeight: isLandscape ? textContainerMaxHeight : undefined
        }}
      >
        {processedText && (
          <div
            ref={textRef}
            className='large-vertical:rf-max-h-[30vh] rf-font-medium rf-overflow-y-scroll rf-pb-4 rf-scroll-pb-4 rf-scrollbar-hidden'
            style={{
              WebkitMaskImage: opacityStyle,
              maxHeight: textMaxHeight || undefined
            }}
            onScroll={handleScroll}
            dangerouslySetInnerHTML={{ __html: processedText }}
          />
        )}
        {otherInformations && otherInformations.length > 0 && (
          <div
            ref={otherInformationsRef}
            className='large-vertical:rf-max-h-[18vh] rf-flex rf-flex-col rf-justify-start rf-text-lightgray rf-overflow-y-scroll rf-pb-4 rf-scroll-pb-4 rf-scrollbar-hidden'
            style={{
              WebkitMaskImage: otherOpacityStyle,
              maxHeight: otherInformationsMaxHeight || undefined
            }}
            onScroll={handleOtherScroll}
          >
            {otherInformations.map((information, key) => {
              const tempContent = Array.isArray(information.content)
                ? information.content.join(' | ')
                : information.content
              const htmlContent = tempContent.replaceAll('\n', '<br>')

              return (
                <React.Fragment key={key}>
                  <div
                    className='rf-mt-4'
                    style={{
                      color: color || 'rgb(25, 153, 177)'
                    }}
                  >
                    {information.title}
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
                </React.Fragment>
              )
            })}
          </div>
        )}
      </div>

      {/* Downloading button or Qrcode in landscape mode */}
      {isLandscape &&
        url &&
        (isUsedOnBorne ? (
          <DownloadQR url={url} />
        ) : (
          <DownloadSheet url={url} />
        ))}
    </div>
  )
}

export default DetailedText
