import React, { useState, useEffect, useRef } from 'react'
import { animated, useSpring } from '@react-spring/web'

import { setGDPRDisplay, useUtilsStore } from '../Contexts/utilsStore'
import { useViewStore } from '../Contexts/viewStore'
import { useRetorikStore } from '../Contexts/retorikStore'

import useRefDimensions from '../../hooks/useRefDimensions'

import preventEvents from '../../utils/preventEvents'

import { RetorikLogoIcon } from '../Icons/Miscellaneous'
import LoaderCallToAction from './LoaderCallToAction'
import GdprWindow from './GdprWindow'

interface GdprAndLoaderProps {
  appAvailable: boolean
  handleValidation: () => void
}

const springEnterDuration = 500
const springLeaveDuration = 1000

const GdprAndLoader = ({
  appAvailable,
  handleValidation
}: GdprAndLoaderProps): JSX.Element => {
  const displayGDPR = useUtilsStore((state) => state.GDPRDisplay)
  const isUsedOnBorne = useRetorikStore(
    (state) => state.configuration.isUsedOnBorne
  )
  const skipLoader = useRetorikStore((state) => state.skipLoader)
  const loaderClosed = useRetorikStore((state) => state.loaderClosed)
  const hideRetorikLogo = useRetorikStore(
    (state) => state.configuration.hideRetorikLogo
  )
  const isMobile = useViewStore((state) => state.isMobile)
  const isWidget = useViewStore((state) => state.isWidget)
  const themeColors = useViewStore((state) => state.themeColors)

  const [textSize, setTextSize] = useState<string>('')
  const [titleTextSize, setTitleTextSize] = useState<string>()
  const container = useRef<HTMLDivElement>(null)
  const dimensions = useRefDimensions(container)
  const animationTimerRef = useRef<NodeJS.Timeout>()

  const [spring, api] = useSpring(() => ({
    from: {
      opacity: 0
    }
  }))

  useEffect(() => {
    if (isUsedOnBorne || skipLoader) {
      setGDPRDisplay(false)
      handleValidation()
    }
  }, [isUsedOnBorne, skipLoader])

  useEffect(() => {
    api.start({
      from: {
        opacity: 0
      },
      to: {
        opacity: 1
      },
      config: {
        duration: springEnterDuration
      }
    })

    return () => {
      animationTimerRef?.current && clearTimeout(animationTimerRef.current)
    }
  }, [])

  useEffect(() => {
    if (dimensions.width && dimensions.height && dimensions.width > 600) {
      if (dimensions.width < dimensions.height) {
        setTextSize('rf-text-lgcustom')
        setTitleTextSize('rf-text-3xl')
      } else {
        setTextSize('rf-text-basecustom')
        setTitleTextSize('rf-text-4xl')
      }
    } else {
      setTextSize('rf-text-sm')
      setTitleTextSize('rf-text-2xl')
    }
  }, [dimensions])

  const handleValidate = (): void => {
    handleValidation()
    api.start({
      from: {
        opacity: 1
      },
      to: {
        opacity: 0
      },
      config: {
        duration: springLeaveDuration
      }
    })
  }

  useEffect(() => {
    container?.current && isWidget && preventEvents(container.current)
  }, [container?.current])

  return isUsedOnBorne || skipLoader ? (
    <React.Fragment />
  ) : displayGDPR || !(appAvailable || loaderClosed) ? (
    <animated.div
      id='retorik-GDPR-and-loader'
      className={`rf-absolute rf-z-modal rf-top-0 rf-left-0 rf-h-full rf-w-full rf-font-default
        ${textSize}`}
      ref={container}
      style={{
        background: themeColors.loader.background,
        color: themeColors.loader.text,
        ...spring
      }}
    >
      {displayGDPR ? (
        <GdprWindow titleTextSize={titleTextSize} />
      ) : !(appAvailable || loaderClosed) ? (
        <LoaderCallToAction handleValidation={handleValidate} />
      ) : (
        <React.Fragment />
      )}

      {/* Logo */}
      {!hideRetorikLogo &&
        (isMobile ? (
          <RetorikLogoIcon className='rf-absolute rf-bottom-10 rf-h-14' />
        ) : (
          <RetorikLogoIcon className='rf-absolute rf-left-4 rf-bottom-4 rf-h-14' />
        ))}
    </animated.div>
  ) : (
    <React.Fragment />
  )
}

export default GdprAndLoader
