import React, { useEffect, useRef, useState } from 'react'
import { Player } from '@lottiefiles/react-lottie-player'

import { useLocaleStore } from '../../Contexts/localeStore'
import {
  setFullScreenImage,
  setCurrentSubView
} from '../../Contexts/utilsStore'
import { useViewStore } from '../../Contexts/viewStore'
import { useSpeechStore } from '../../Contexts/speechStore'

import useToggleMicrophone from '../../../hooks/useToggleMicrophone'

import { CurrentSubView, RecognitionState } from '../../../models/enums'
import animation from '../../../assets/lottie/recording.json'
import getMicrophonePermission from '../../../utils/getMicrophonePermission'

import Modal from '../../Common/Modal'
import * as Icons from '../../Icons/MenuIcons'
import MicrophoneLoader from '../../Loader/MicrophoneLoader'

interface MicrophoneButtonProps {
  openMicrophone?: boolean
}

const MicrophoneButton = ({
  openMicrophone
}: MicrophoneButtonProps): JSX.Element => {
  const translation = useLocaleStore((state) => state.currentTranslations)
  const activeRecognitionState = useSpeechStore(
    (state) => state.activeRecognitionState
  )
  const toggleMicrophone = useToggleMicrophone()
  const isMobile = useViewStore((state) => state.isMobile)
  const themeColors = useViewStore((state) => state.themeColors)

  const allowedRef = useRef<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [playerRef, setPlayerRef] = useState<any>(null)

  const handleClick = (): void => {
    if (!allowedRef.current) {
      askPermission()
    } else {
      toggleMicrophone()
    }
  }

  useEffect(() => {
    openMicrophone && handleClick()
  }, [])

  useEffect(() => {
    if (playerRef) {
      activeRecognitionState === RecognitionState.Listening
        ? playerRef.lottie.play()
        : playerRef.lottie.stop()
    }

    setCurrentSubView(CurrentSubView.none)
    setFullScreenImage(undefined)
  }, [activeRecognitionState, playerRef])

  const closeModal = (): void => {
    setMessage('')
  }

  const askPermission = (): Promise<void> => {
    setMessage(translation.menu.allowMicrophone)

    return getMicrophonePermission()
      .then((permission) => {
        allowedRef.current = permission
        if (permission) {
          closeModal()
          toggleMicrophone()
        }
      })
      .catch(console.log)
  }

  return (
    <React.Fragment>
      <button
        className={`rf-relative large:rf-w-full large:rf-h-auto rf-aspect-square rf-flex rf-justify-center rf-items-center rf-border ${
          activeRecognitionState === RecognitionState.Listening
            ? isMobile
              ? 'rf-h-12 rf-border-[#00000029]'
              : 'rf-border-[#00000029]'
            : isMobile
            ? 'rf-h-8 rf-border-none'
            : 'rf-border-menuBorder'
        } rf-rounded-half rf-overflow-hidden`}
        onClick={handleClick}
        aria-label='microphone'
        style={{
          boxShadow:
            activeRecognitionState === RecognitionState.Listening
              ? 'inset 0 0 6px 3px#00000029'
              : isMobile
              ? undefined
              : '0 0 20px 1px #A4A7AF80',
          WebkitMaskImage:
            activeRecognitionState === RecognitionState.Listening
              ? '-webkit-radial-gradient(white, black)'
              : undefined
        }}
      >
        <div className='rf-absolute rf-h-full rf-w-full rf-flex rf-justify-end'>
          {activeRecognitionState === RecognitionState.Listening && (
            <Player
              lottieRef={(instance): void => {
                setPlayerRef({ lottie: instance })
              }}
              loop
              src={animation}
              className='rf-w-full'
            />
          )}
        </div>

        {/* Display microphone icon or loader depending on speech recognition state */}
        {activeRecognitionState === RecognitionState.Listening ? (
          <Icons.MicrophoneIconOn className='rf-h-3 rf-w-3 large:rf-h-4 large:rf-w-4' />
        ) : activeRecognitionState === RecognitionState.Closed ? (
          <Icons.MicrophoneIcon
            className='rf-h-5 rf-w-5'
            color={themeColors.secondary}
          />
        ) : (
          <MicrophoneLoader />
        )}
      </button>

      <Modal isOpened={!!message} onClose={closeModal}>
        {message}
      </Modal>
    </React.Fragment>
  )
}

export default MicrophoneButton
