import React, { useEffect, useState } from 'react'
import { useUtilsStore } from '../Contexts/utilsStore'
import {
  useAdaptiveCardStore,
  setValue,
  setCurrentId
} from '../Contexts/adaptiveCardStore'
import { CurrentSubView } from '../../models/enums'
import Wheeler from '../Utils/Wheeler'

interface InputTimeProps {
  id: string
  defaultValue?: string
  maxValue?: string
  minValue?: string
}

const InputTime = ({
  id,
  defaultValue,
  maxValue,
  minValue
}: InputTimeProps): JSX.Element => {
  const currentSubView = useUtilsStore((state) => state.currentSubView)
  const values = useAdaptiveCardStore((state) => state.values)
  const [hour, setHour] = useState<number>(12)
  const [minute, setMinute] = useState<number>(0)

  const [maxHour, setMaxHour] = useState<number>(23)
  const [minHour, setMinHour] = useState<number>(0)
  const [maxMinute, setMaxMinute] = useState<number>(59)
  const [minMinute, setMinMinute] = useState<number>(0)

  const [updatingTime, setUpdatingTime] = useState<boolean>(false)

  const [hourWheel, setHourWheel] = useState<number>(0)
  const [minuteWheel, setMinuteWheel] = useState<number>(0)

  useEffect(() => {
    currentSubView !== CurrentSubView.history && setCurrentId(id)
  }, [])

  /**
   * On component mount :
   *  - set hour / minute from localStorage value if there is one, or defaultValue, or 12:00 if no other data
   *  - set maxHour and maxMinute states if maxValue prop is set
   *  - set minHour and minMinute states if minValue prop is set
   */
  useEffect(() => {
    // Set hour and minute from localstorage or defaultvalue if one of those is set
    const timeFromLocalStorage = (values.get(id) as string) || defaultValue
    const timeAsArray: Array<string> = timeFromLocalStorage
      ? timeFromLocalStorage.split(':')
      : []

    if (timeAsArray.length === 2) {
      !isNaN(parseInt(timeAsArray[0])) && setHour(parseInt(timeAsArray[0]))
      !isNaN(parseInt(timeAsArray[1])) && setMinute(parseInt(timeAsArray[1]))
    }

    // Set min and max times
    if (maxValue) {
      const maxTimeAsArray = maxValue.split(':')
      setMaxHour(parseInt(maxTimeAsArray[0]))
      setMaxMinute(parseInt(maxTimeAsArray[1]))
    }

    if (minValue) {
      const minTimeAsArray = minValue.split(':')
      setMinHour(parseInt(minTimeAsArray[0]))
      setMinMinute(parseInt(minTimeAsArray[1]))
    }
  }, [])

  /**
   * On hour / minute state change :
   *  - check if a hour / minute change is needed to respect minValue or maxValue
   *  - set the value in localStorage
   *  - if an update is needed, set the updatingTime state to true to prevent concurrent modification, update, and set updatingTime back to false to release lock
   */
  useEffect(() => {
    // Check if we are in the bounds of minValue and maxValue if there are some
    if (!updatingTime && hour !== undefined && minute !== undefined) {
      let updating = false
      // Deal with hours and minutes lower than 10 : add a '0' before to keep 2-characters length for each
      const tempHour = hour < 10 ? `0${hour}` : hour
      const tempMinute = minute < 10 ? `0${minute}` : minute
      let timeAsString = `${tempHour}:${tempMinute}`
      // Check if the time is lower than the minimal value if given, or greater than the maximal one if given
      if (
        minValue &&
        (hour < minHour || (hour === minHour && minute < minMinute))
      ) {
        timeAsString = minValue
        updating = true
      } else if (
        maxValue &&
        (hour > maxHour || (hour === maxHour && minute > maxMinute))
      ) {
        timeAsString = maxValue
        updating = true
      }

      // Save time
      setValue(id, timeAsString)

      // Update states if necessary
      if (updating) {
        setUpdatingTime(true)
        const timeAsArray = timeAsString.split(':')
        setHour(parseInt(timeAsArray[0]))
        setMinute(parseInt(timeAsArray[1]))
        setUpdatingTime(false)
      }
    }
  }, [hour, minute])

  /**
   * On hourWheel change, if hourWheel !== 0 :
   *  - try to update the hour state
   *  - reset hourWheel state to 0
   */
  useEffect(() => {
    if (hourWheel !== 0 && hour !== undefined) {
      if (hourWheel > 0) {
        hour + 1 > 23 ? setHour(0) : setHour((hour): number => hour + 1)
      } else {
        hour - 1 < 0 ? setHour(23) : setHour((hour): number => hour - 1)
      }
      // Reset dayWheel
      setHourWheel(0)
    }
  }, [hourWheel])

  /**
   * On minuteWheel change, if minuteWheel !== 0 :
   *  - try to update the minute state
   *  - reset minuteWheel state to 0
   */
  useEffect(() => {
    if (minuteWheel !== 0 && minute !== undefined) {
      if (minuteWheel > 0) {
        minute + 1 > 59
          ? setMinute(0)
          : setMinute((minute): number => minute + 1)
      } else {
        minute - 1 < 0
          ? setMinute(59)
          : setMinute((minute): number => minute - 1)
      }
      // Reset monthWheel
      setMinuteWheel(0)
    }
  }, [minuteWheel])

  /**
   * On wheel event on hour / minute part :
   *  - check if the component will be rendered in history, if so bypass every interaction
   *  - call wheel state depending on the type, that will trigger the related hour / minute state update if possible
   * @param delta : number
   * @param type : string
   */
  const handleWheel = (delta, type): void => {
    if (currentSubView !== CurrentSubView.history) {
      switch (type) {
        case 'hour':
          setHourWheel(delta)
          break
        case 'minute':
          setMinuteWheel(delta)
          break
        default:
          break
      }
    }
  }

  /**
   * On click on a hour / minute :
   *  - check if the component will be rendered in history, if so bypass every interaction
   *  - set chosen hour / minute if it is within the accepted bounds
   * @param value : number
   * @param type : string
   */
  const handleClick = (value: number, type: string): void => {
    if (currentSubView !== CurrentSubView.history) {
      switch (type) {
        case 'hour':
          hour !== undefined && value !== -1 && setHour(value)
          break
        case 'minute':
          minute !== undefined && value !== -1 && setMinute(value)
          break
        default:
          break
      }
    }
  }

  return (
    <div
      className='rf-w-full rf-mt-4 rf-py-2 rf-flex rf-flex-row rf-justify-center rf-items-center rf-rounded-lg rf-subtitle-size-auto rf-text-formInputRadioCheckboxUncheckedBackground rf-unselectable'
      style={{
        background:
          'linear-gradient(rgb(0, 0, 0, 0.5) 0%, rgb(0, 0, 0, 0.5) 34%, black 34%, black 65%, rgb(0, 0, 0, 0.5) 65%, rgb(0, 0, 0, 0.5) 100%)'
      }}
    >
      <input type='time' className='rf-hidden' />
      {hour !== undefined && minute !== undefined && (
        <React.Fragment>
          {/* Hours */}
          <Wheeler
            className='rf-flex rf-flex-col rf-items-center rf-pr-1 rf-py-1'
            handleWheel={handleWheel}
            paramInHandle='hour'
          >
            <div
              className='rf-py-1 hover:rf-cursor-pointer'
              onClick={(): void =>
                handleClick(
                  minValue && hour === minHour
                    ? -1
                    : hour === 0
                    ? 23
                    : hour - 1,
                  'hour'
                )
              }
            >
              {minValue && hour === minHour
                ? '\u2800'
                : hour === 0
                ? '23'
                : hour < 11
                ? `0${hour - 1}`
                : hour - 1}
            </div>
            <div className='rf-py-1 rf-text-primary'>
              {hour < 10 ? `0${hour}` : hour}
            </div>
            <div
              className='rf-py-1 hover:rf-cursor-pointer'
              onClick={(): void =>
                handleClick(
                  maxValue && hour === maxHour
                    ? -1
                    : hour === 23
                    ? 0
                    : hour + 1,
                  'hour'
                )
              }
            >
              {maxValue && hour === maxHour
                ? '\u2800'
                : hour === 23
                ? '00'
                : hour < 9
                ? `0${hour + 1}`
                : hour + 1}
            </div>
          </Wheeler>

          {/* Empty column with colon in the middle element */}
          <div className='rf-flex rf-flex-col rf-items-center rf-py-1'>
            <div>{'\u2800'}</div>
            <div className='rf-text-primary'>:</div>
            <div>{'\u2800'}</div>
          </div>

          {/* Minutes */}
          <Wheeler
            className='rf-flex rf-flex-col rf-items-center rf-pl-1 rf-py-1'
            handleWheel={handleWheel}
            paramInHandle='minute'
          >
            <div
              className='rf-py-1 hover:rf-cursor-pointer'
              onClick={(): void =>
                handleClick(
                  minValue && hour === minHour && minute === minMinute
                    ? -1
                    : minute === 0
                    ? 59
                    : minute - 1,
                  'minute'
                )
              }
            >
              {minValue && hour === minHour && minute === minMinute
                ? '\u2800'
                : minute === 0
                ? '59'
                : minute < 11
                ? `0${minute - 1}`
                : minute - 1}
            </div>
            <div className='rf-py-1 rf-text-primary'>
              {minute < 10 ? `0${minute}` : minute}
            </div>
            <div
              className='rf-py-1 hover:rf-cursor-pointer'
              onClick={(): void =>
                handleClick(
                  maxValue && hour === maxHour && minute === maxMinute
                    ? -1
                    : minute === 59
                    ? 0
                    : minute + 1,
                  'minute'
                )
              }
            >
              {maxValue && hour === maxHour && minute === maxMinute
                ? '\u2800'
                : minute < 9
                ? `0${minute + 1}`
                : minute === 59
                ? '00'
                : minute + 1}
            </div>
          </Wheeler>
        </React.Fragment>
      )}
    </div>
  )
}

export default InputTime
