import React, { useEffect, useState } from 'react'
import { useLocaleStore } from '../../Contexts/localeStore'
import { useUtilsStore } from '../../Contexts/utilsStore'
import {
  useAdaptiveCardStore,
  setValue,
  setCurrentId
} from '../../Contexts/adaptiveCardStore'
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter'
import { CurrentSubView } from '../../../models/enums'
import { ArrowDownIcon } from '../../Icons/ArrowIcons'

interface SelectProps {
  id: string
  choices: any
  placeholder?: string
}

interface Choice {
  title: string
  value: string
}

const Select = ({ id, choices, placeholder }: SelectProps): JSX.Element => {
  const translation = useLocaleStore((state) => state.currentTranslations)
  const currentSubView = useUtilsStore((state) => state.currentSubView)
  const values = useAdaptiveCardStore((state) => state.values)
  const [display, setDisplay] = useState<Array<any>>([])
  const [open, setOpen] = useState<boolean>(false)
  const [chosen, setChosen] = useState<Choice | null>(
    (values.get(id) as Choice) || null
  )

  useEffect(() => {
    currentSubView !== CurrentSubView.history && setCurrentId(id)
  }, [])

  /**
   * On choices prop change :
   *  - if choices is defined, fill display state with choices value
   */
  useEffect(() => {
    choices && setDisplay(choices)
  }, [choices])

  /**
   * On call :
   *  - set open state to its contrary
   */
  const toggleOpen = (): void => {
    setOpen(!open)
  }

  /**
   * On call :
   *  - check if the component will be rendered in history, if so bypass every interaction
   *  - fill chosen state with choice value
   *  - call parent's handleSelect method
   *  - call toggleOpen method
   * @param choice : Choice
   */
  const handleChange = (choice: Choice): void => {
    if (currentSubView !== CurrentSubView.history) {
      setChosen(choice)
      setValue(id, choice)
      toggleOpen()
    }
  }

  return display && display.length > 0 ? (
    <div className='rf-w-full rf-mt-4 rf-text-size-auto rf-text-formInputTextDefault'>
      <div
        onClick={toggleOpen}
        className={`rf-w-full rf-flex rf-flex-row rf-justify-between rf-items-center rf-p-4 rf-border-2 rf-rounded ${
          open
            ? 'rf-border-formInputRadioCheckboxCheckedBorder'
            : 'rf-border-formInputRadioCheckboxUncheckedBorder'
        } rf-bg-formInputRadioCheckboxUncheckedBackground`}
      >
        <div className='rf-truncate'>
          {chosen?.title || placeholder || translation.inputs.choose}
        </div>
        <div className='rf-h-2'>
          <ArrowDownIcon className='rf-h-full' />
        </div>
      </div>
      {open && (
        <ul
          className='rf-mt-1 rf-max-h-48 rf-pl-0 rf-w-full rf-rounded rf-border-2 rf-border-formInputRadioCheckboxUncheckedBorder rf-bg-formInputRadioCheckboxUncheckedBackground rf-overflow-y-scroll rf-scrollbar-thin'
          style={{
            listStyle: 'none'
          }}
        >
          {display.map((choice, key) => {
            return (
              <li
                key={key}
                onClick={(): void => handleChange(choice)}
                className='rf-px-4 rf-py-2 hover:rf-text-formInputRadioCheckboxCheckedItem hover:rf-bg-formInputRadioCheckboxCheckedBackground'
              >
                {capitalizeFirstLetter(choice.title)}
              </li>
            )
          })}
        </ul>
      )}
    </div>
  ) : (
    <React.Fragment />
  )
}

export default Select
