/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useState } from 'react'
import { useViewStore } from '../../Contexts/viewStore'
import { useUtilsStore, lockClickedButton } from '../../Contexts/utilsStore'
import { sendMessage } from '../../Contexts/directLineStore'

import { AnimationSize } from '../../../models/enums'

import { ArrowRightIcon } from '../../Icons/ArrowIcons'
import Animation from '../../Loader/Animation'

interface BorderlessButtonMessageProps {
  text: string
  differentTextTosend?: string
}

const BorderlessButtonMessage = ({
  text,
  differentTextTosend
}: BorderlessButtonMessageProps): JSX.Element => {
  const themeColors = useViewStore((state) => state.themeColors)
  const unlockClickedButton = useUtilsStore(
    (state) => state.unlockClickedButton
  )
  const [hover, setHover] = useState<boolean>(false)
  const [clicked, setClicked] = useState<boolean>(false)

  const animationColor = {
    '--rf-color-loader-animation': themeColors.card.button.text.default
  } as React.CSSProperties

  const onClick = (text: string): void => {
    setClicked(true)
    lockClickedButton()
    sendMessage(text)
  }

  useEffect(() => {
    unlockClickedButton && setClicked(false)
  }, [unlockClickedButton])

  return (
    <div className='rf-w-full rf-flex rf-justify-start rf-cursor-pointer'>
      <div
        className='rf-w-full rf-flex rf-flex-row rf-items-center rf-justify-start rf-text-cardButtonTextDefault'
        onPointerEnter={(): void => setHover(true)}
        onPointerLeave={(): void => setHover(false)}
        onClick={(): void => {
          setHover(true)
          onClick(differentTextTosend || text)
        }}
      >
        {clicked ? (
          <div
            className='rf-h-6 rf-w-6 rf-flex rf-justify-center rf-items-center'
            style={{
              ...animationColor
            }}
          >
            <div className='rf-w-2 rf-h-2'>
              <Animation size={AnimationSize.small} />
            </div>
          </div>
        ) : (
          <div
            className={`rf-h-6 rf-w-6 rf-rounded ${
              hover
                ? 'rf-bg-cardButtonBackgroundHover'
                : 'rf-bg-cardButtonBackgroundDefault'
            }`}
          >
            <ArrowRightIcon
              className='rf-h-6 rf-p-1 rf-border rf-rounded rf-border-cardButtonBorderDefault'
              color={
                hover
                  ? themeColors.card.button.text.hover
                  : themeColors.card.button.text.default
              }
            />
          </div>
        )}
        <div className='rf-ml-4'>{text || ''}</div>
      </div>
    </div>
  )
}

export default BorderlessButtonMessage
