import React, { useEffect, useRef, useState } from 'react'
import {
  createSpeechRecognitionPonyfill,
  SpeechGrammarList
} from '@davi-ai/web-speech-cognitive-services-davi'
import type {
  SpeechRecognitionPonyfillType,
  SpeechRecognitionProps,
  SpeechRecognitionResultList,
  SpeechRecognitionResultListItem
} from '@davi-ai/web-speech-cognitive-services-davi'

import { useLocaleStore } from '../Contexts/localeStore'
import {
  useSpeechStore,
  setActiveRecognitionState,
  setLastRecognitionInterim
} from '../Contexts/speechStore'
import { useRetorikStore } from '../Contexts/retorikStore'
import { sendMessage } from '../Contexts/directLineStore'
import { useViewStore } from '../Contexts/viewStore'

import { Mode, RecognitionState, Routes } from '../../models/enums'
import getMicrophonePermission from '../../utils/getMicrophonePermission'

const timeoutDuration = 1000

const RecognitionManager = (): JSX.Element => {
  const locale = useLocaleStore((state) => state.locale)
  const mode = useRetorikStore((state) => state.mode)
  const appAvailable = useRetorikStore((state) => state.appAvailable)
  const loaderClosed = useRetorikStore((state) => state.loaderClosed)
  const speechRecognitionOptions = useRetorikStore(
    (state) => state.configuration.speechRecognitionOptions
  )
  const ponyfillCredentials = useSpeechStore(
    (state) => state.ponyfillCredentials
  )
  const useContinuousRecognition = useSpeechStore(
    (state) => state.useContinuousRecognition
  )
  const activeRecognitionState = useSpeechStore(
    (state) => state.activeRecognitionState
  )
  const lastRecognitionInterim = useSpeechStore(
    (state) => state.lastRecognitionInterim
  )
  const route = useViewStore((state) => state.route)

  const [ponyfill, setPonyfill] =
    useState<SpeechRecognitionPonyfillType | null>(null)
  const isActiveModeRef = useRef<boolean>()
  const timerRef = useRef<NodeJS.Timeout | null>(null)
  const [recognitionAllowed, setRecognitionAllowed] = useState<boolean>(false)
  const [ponyfillReady, setPonyfillReady] = useState<boolean>(false)

  const getCommonAndLocalizedWakeWords = (): Array<string> => {
    let commonAndlocalizedWakeWords: Array<string> = []
    if (useContinuousRecognition && speechRecognitionOptions?.wakeWords) {
      if (speechRecognitionOptions.wakeWords.common) {
        commonAndlocalizedWakeWords = [
          ...speechRecognitionOptions.wakeWords.common
        ]
      }

      if (speechRecognitionOptions.wakeWords.localized) {
        const localizedWakeWords =
          speechRecognitionOptions.wakeWords.localized[locale] || []
        localizedWakeWords.length &&
          (commonAndlocalizedWakeWords = [
            ...commonAndlocalizedWakeWords,
            ...localizedWakeWords
          ])
      }
    }

    return commonAndlocalizedWakeWords
  }

  const getCommonAndLocalizedGrammars = (): Array<string> => {
    let commonAndlocalizedGrammars: Array<string> = []
    if (speechRecognitionOptions?.grammars) {
      if (speechRecognitionOptions.grammars.common) {
        commonAndlocalizedGrammars = [
          ...speechRecognitionOptions.grammars.common
        ]
      }

      if (speechRecognitionOptions.grammars.localized) {
        const localizedGrammars =
          speechRecognitionOptions.grammars.localized[locale] || []
        localizedGrammars.length &&
          (commonAndlocalizedGrammars = [
            ...commonAndlocalizedGrammars,
            ...localizedGrammars
          ])
      }
    }

    return commonAndlocalizedGrammars
  }

  const askPermission = (): void => {
    if (recognitionAllowed) {
      ponyfill?.speechRecognition.start()
    } else {
      getMicrophonePermission()
        .then((permission) => {
          setRecognitionAllowed(permission)
        })
        .catch(console.log)
    }
  }

  useEffect(() => {
    recognitionAllowed &&
      useContinuousRecognition &&
      ponyfill?.speechRecognition.start()
  }, [recognitionAllowed])

  useEffect(() => {
    if (ponyfillCredentials) {
      const options: SpeechRecognitionProps = {
        autoStart: false,
        continuous: useContinuousRecognition,
        interimResults: true,
        grammarsList: getCommonAndLocalizedGrammars(),
        lang: locale,
        passive: useContinuousRecognition,
        wakeWords: getCommonAndLocalizedWakeWords(),
        debug: false,
        timerBeforeSpeechEnd: speechRecognitionOptions?.timerBeforeSpeechEnd
      }

      setPonyfill(
        createSpeechRecognitionPonyfill(
          { credentials: ponyfillCredentials },
          options
        )
      )
    }
  }, [ponyfillCredentials])

  useEffect(() => {
    if (ponyfill?.speechRecognition) {
      // Set wakeworkds
      ponyfill.speechRecognition.wakeWords = getCommonAndLocalizedWakeWords()

      // Set language
      activeRecognitionState === RecognitionState.Listening
        ? ponyfill.speechRecognition.changeLanguage(locale)
        : (ponyfill.speechRecognition.lang = locale)

      // Set grammar list
      const grammarList = new SpeechGrammarList()
      grammarList.phrases = getCommonAndLocalizedGrammars()
      ponyfill.speechRecognition.grammars = grammarList
    }
  }, [locale])

  /**
   * Abort recognition if we are not on the 'home' page
   */
  useEffect(() => {
    route !== Routes.Home && setActiveRecognitionState(RecognitionState.Closing)
  }, [route])

  useEffect(() => {
    if (ponyfill && appAvailable && loaderClosed) {
      if (ponyfill.speechRecognition) {
        ponyfill.speechRecognition.onstart = () => {
          setActiveRecognitionState(RecognitionState.Listening)
        }

        // onend callback is called only whithout using passive speech recognition
        ponyfill.speechRecognition.onend = () => {
          setActiveRecognitionState(RecognitionState.Closing)
        }

        ponyfill.speechRecognition.onabort = () => {
          setActiveRecognitionState(RecognitionState.Closed)
        }

        ponyfill.speechRecognition.onwakeup = () => {
          console.log('Hey, wake up !!')
        }

        ponyfill.speechRecognition.onresult = (
          result:
            | SpeechRecognitionResultList
            | Array<SpeechRecognitionResultListItem>
        ) => {
          const lastMessage: string | null =
            result.length > 0 ? result[result.length - 1].transcript : null
          // Only get result in Active mode
          if (lastMessage && isActiveModeRef.current) {
            setLastRecognitionInterim(lastMessage)
          }
        }

        setPonyfillReady(true)
      }
    }

    return () => {
      timerRef?.current && clearTimeout(timerRef.current)
      ponyfill?.speechRecognition?.stop && ponyfill.speechRecognition.stop()
    }
  }, [ponyfill, appAvailable, loaderClosed])

  useEffect(() => {
    if (ponyfillReady && mode === Mode.vocal) {
      // Start passive recognition if not done yet
      useContinuousRecognition &&
        !ponyfill?.speechRecognition.started &&
        askPermission()
    }
  }, [mode, ponyfillReady])

  const togglePassiveToActive = (): void => {
    if (ponyfill?.speechRecognition.started) {
      ponyfill.speechRecognition.toggleContinuousPassiveToActive()
    } else {
      if (ponyfill) {
        ponyfill.speechRecognition.passive = false
        ponyfill.speechRecognition.continuous = false
        ponyfill.speechRecognition.start()
      }
    }
  }

  useEffect(() => {
    isActiveModeRef.current =
      activeRecognitionState === RecognitionState.Listening

    if (ponyfill?.speechRecognition && appAvailable && loaderClosed) {
      timerRef?.current && clearTimeout(timerRef.current)
      switch (activeRecognitionState) {
        // Do nothing if we are in a transition state
        case RecognitionState.Initializing: {
          useContinuousRecognition
            ? togglePassiveToActive()
            : ponyfill.speechRecognition.start()
          break
        }
        case RecognitionState.Listening:
          break
        case RecognitionState.Closing: {
          if (lastRecognitionInterim) {
            timerRef.current = setTimeout(() => {
              sendMessage(lastRecognitionInterim)

              setActiveRecognitionState(RecognitionState.Closed)
            }, timeoutDuration)
          } else {
            setActiveRecognitionState(RecognitionState.Closed)
          }
          break
        }
        case RecognitionState.ForceClosing: {
          setLastRecognitionInterim('')

          // If an interim was processed, a 'end' event will be fired. If not, we must abort the current recognition
          if (!lastRecognitionInterim) {
            ponyfill.speechRecognition.started
              ? ponyfill.speechRecognition.abort?.()
              : setActiveRecognitionState(RecognitionState.Closed)
          }
          break
        }
        case RecognitionState.Closed: {
          if (useContinuousRecognition && mode === Mode.vocal) {
            timerRef.current = setTimeout(() => {
              ponyfill.speechRecognition.toggleContinuousActiveToPassive()
            }, timeoutDuration)
          }
          setLastRecognitionInterim('')
          break
        }
      }
    }
  }, [activeRecognitionState])

  return <React.Fragment />
}

export default RecognitionManager
