import React, { useEffect, useRef } from 'react'
import { useLocaleStore } from '../../Contexts/localeStore'
import { useViewStore } from '../../Contexts/viewStore'
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter'
import { BackButton, Dashboard, Parameters } from '../Common'
import preventEvents from '../../../utils/preventEvents'

interface ParametersAndDashboardContainerProps {
  handleBack: () => void
  hideMenu: boolean
}

const ParametersAndDashboardContainer = ({
  handleBack,
  hideMenu = false
}: ParametersAndDashboardContainerProps): JSX.Element => {
  const translation = useLocaleStore((state) => state.currentTranslations)
  const locale = useLocaleStore((state) => state.locale)
  const isWidget = useViewStore((state) => state.isWidget)
  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    divRef?.current && isWidget && preventEvents(null, divRef)
  }, [divRef.current])

  return (
    <div className='rf-w-full rf-h-full rf-flex rf-flex-col rf-py-4 rf-bg-truewhite rf-overflow-y-hidden rf-pointer-events-auto'>
      {/* Back to conversation button */}
      <BackButton
        title={translation.menu.backconversation}
        className='rf-px-4 rf-text-sm'
        handleBack={handleBack}
      />

      <div
        ref={divRef}
        className='rf-w-full rf-flex-rf-flex-col rf-overflow-y-scroll rf-scrollbar-hidden'
      >
        {/* Dashboard */}
        {!hideMenu && (
          <React.Fragment>
            <Dashboard toggleDashboard={handleBack} />
            <div className='rf-h-6 rf-bg-[#F0F0F0] rf-border-y rf-border-y-menuBorder' />
          </React.Fragment>
        )}

        {/* Parameters container */}
        <div className='rf-w-full rf-px-5 rf-flex rf-flex-col rf-justify-start'>
          {/* Title */}
          <div className='rf-py-4 rf-text-lightgray'>
            {capitalizeFirstLetter(translation.common.parameters, locale)}
          </div>

          {/* Parameters */}
          <Parameters handleBack={handleBack} />
        </div>
      </div>
    </div>
  )
}

export default ParametersAndDashboardContainer
