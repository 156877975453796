{
  "1": "accueil",
  "2": "chaîne info live",
  "3": "météo",
  "4": "numéros d'urgence",
  "1000": "historique",
  "1001": "tutoriel",

  "common": {
    "abort": "annuler",
    "all": "tous",
    "back": "retour",
    "close": "fermer",
    "continue": "continuer",
    "download": "télécharger",
    "downloadsheet": "télécharger cette fiche",
    "downloadsheetmobile": "télécharger cette fiche sur mobile",
    "filter": "filtrer",
    "history": "historique",
    "lang": "langues",
    "menu": "menu",
    "mode": "mode",
    "needhelp": "besoin d'aide ?",
    "parameters": "paramètres",
    "print": "imprimer",
    "printsheet": "imprimer cette fiche",
    "result": "résultat",
    "results": "résultats",
    "singleLang": "langue",
    "sound": "son",
    "submit": "valider",
    "subtitle": "sous-titre",
    "tutorial": "tutoriel",
    "validate": "valider"
  },

  "emergency": {
    "samu": "samu",
    "police": "police",
    "firefighter": "pompiers",
    "general": "général",
    "citypolice": "Police municipale",
    "hospital": "Hôpital",
    "homeless": "Sans abris (appel gratuit)",
    "poison": "Centre anti-poison",
    "emergency": "Urgences européennes"
  },

  "error": {
    "title": "une erreur est survenue",
    "button": "Relancer l'application"
  },

  "history": {
    "title": "historique de conversation"
  },

  "inputs": {
    "choose": "Choisissez une réponse"
  },

  "language": {
    "change": "changer la langue",
    "select": "sélectionnez la langue de votre choix"
  },

  "link": {
    "buttonText": "Voir le document",
    "goto": "Accéder à l'adresse",
    "click": "Cliquez-ici",
    "scanQR": "en scannant le QrCode"
  },

  "loader": {
    "loader": {
      "optimal": "Pour une expérience optimale,",
      "classic": "Pour une expérience plus classique,",
      "vocal": "j'échange de vive voix avec l'agent.",
      "text": "j'échange à l'écrit avec l'agent."
    },
    "modes": {
      "vocal": "mode vocal",
      "text": "mode texte"
    },
    "loaderValidation": "Démarrer la conversation",
    "locale": "vérification de la langue",
    "supported": "vérification des langues disponibles",
    "store": "création du store",
    "directline": "connection au directline",
    "ponyfill": "création du ponyfill",
    "addressdata": "récupération des données"
  },

  "menu": {
    "message": "Saisissez votre message",
    "allowMicrophone": "Autorisez votre navigateur à utiliser votre micro pour continuer",
    "backconversation": "retour à la conversation",
    "answer": " répond à vos questions"
  },

  "mobileLandscapePreventer": {
    "firstline": "Pour une expérience optimale,",
    "secondline": "basculez votre écran"
  },

  "news": {
    "warningtext": "Vous allez accéder à la chaîne info, le son de votre appareil va être activé",
    "live": "direct",
    "continuous": "en continu"
  },

  "poi": {
    "comment": "avis clients",
    "route": "y Aller",
    "nodata": "Aucun contenu ne correspond à votre recherche",
    "openinghours": "Horaires",
    "prices": "Tarifs",
    "paymentmethods": "Modes de paiement",
    "numberofplaces": "nombre de couverts",
    "openroutemobile": "ouvrir l'itinéraire Google Maps sur mobile",
    "FoodEstablishment": "Où manger",
    "LodgingBusiness": "Où dormir",
    "TouristDestination": "A voir",
    "TouristAttraction": "A faire",
    "Product": "Produits locaux",
    "Service": "Services",
    "Event": "Evènements",
    "City": "Villes",
    "Wine": "Vins"
  },

  "suggestion": {
    "consult": "Consultez",
    "thetutorial": "le tutoriel"
  },

  "template": {
    "reset": "effacer tout",
    "showresults": "Afficher les résultats",
    "suggestiontitle": "Suggestions de recherches",
    "reminder": "N'oubliez pas,\n je peux répondre à vos questions."
  },

  "GDPR": {
    "title": "traitement des données personnelles transmises au bot",
    "message": "en acceptant ce dialogue, vous consentez à ce que toutes les données transmises au chatbot soient exploitées à des fins d'analyse de l'utilisation de l'outil et d'enrichissement de la base de connaissance.\n\nVos conversations sont conservées 6 mois avant de faire l'objet d'une suppression.",
    "link": "lien pour consulter les politiques de confidentialité",
    "buttonAccept": "j'ai compris et j'accepte",
    "buttonRefuse": "je refuse",
    "messageAccepted": "votre consentement est bien pris en compte",
    "messageRefused": "votre refus est bien pris en compte"
  }
}