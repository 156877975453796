import React from 'react'
import { useRetorikStore } from '../Contexts/retorikStore'

interface LogoProps {
  className?: string
}

const Logo = ({ className }: LogoProps): JSX.Element => {
  const configuration = useRetorikStore((state) => state.configuration)

  return configuration.logo?.src ? (
    <div
      className={`${
        className || 'rf-absolute rf-m-4 large:rf-m-8 rf-top-0 rf-left-0'
      }`}
    >
      <img
        src={configuration.logo?.src}
        alt='Logo'
        className='rf-max-h-12 rf-max-w-16 large:rf-max-h-28 large:rf-max-w-[12rem] large-vertical:rf-max-h-36'
      />
    </div>
  ) : (
    <React.Fragment />
  )
}

export default Logo
