/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useState } from 'react'
import QRCode from 'qrcode'

import { useLocaleStore } from '../Contexts/localeStore'
import { useViewStore } from '../Contexts/viewStore'
import { setModalFullscreenContent } from '../Contexts/utilsStore'
import { useRetorikStore } from '../Contexts/retorikStore'

import type { UrlData } from '../../models/linkAttachment'
import { getTextToDisplayOnLink } from '../../utils/getTextToDisplayOnLink'
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter'
import { checkFileNameInUrl } from '../../utils/checkFileNameInUrl'

import ClassicButton from './Utils/ClassicButton'
import BorderlessButton from './Utils/BorderlessButton'
import { FileIcon, InfoWithCircleIcon } from '../Icons/Miscellaneous'
import QRCodeComponent from '../Utils/QRCodeComponent'
import PrintingPreview from './PrintingPreview'

interface LinkAttachmentProps {
  urlData: UrlData
  fileName?: string
  qrCode?: string
  description?: string
  height?: number
}

const LinkAttachment = ({
  urlData,
  fileName,
  qrCode,
  description,
  height
}: LinkAttachmentProps): JSX.Element => {
  const translation = useLocaleStore((state) => state.currentTranslations)
  const themeColors = useViewStore((state) => state.themeColors)
  const isUsedOnBorne = useRetorikStore(
    (state) => state.configuration.isUsedOnBorne
  )
  const enableDocumentPrinting = useRetorikStore(
    (state) => state.configuration.enableDocumentPrinting
  )
  const locale = useLocaleStore((state) => state.locale)
  !fileName && (fileName = checkFileNameInUrl(urlData.url))
  const title = urlData.title || fileName
  const [qrCodeData, setQrCodeData] = useState<string | undefined>(qrCode)
  const [textToDisplay, setTextToDisplay] = useState<string>('')

  useEffect(() => {
    setTextToDisplay(
      getTextToDisplayOnLink({
        translation,
        isUsedOnBorne,
        data: urlData,
        fileName
      })
    )
  }, [isUsedOnBorne, locale, urlData])

  /**
   * On component creation :
   *  - call setQrCodeDataAsync only if used on a borne
   */
  useEffect(() => {
    !qrCode && isUsedOnBorne && setQrCodeDataAsync()
  }, [])

  /**
   * On call :
   *  - generate a qrcode as dataURL
   *  - set qrCodeData state with the data
   */
  const setQrCodeDataAsync = async (): Promise<void> => {
    const qrCodeString = await QRCode.toDataURL(urlData.url)
    setQrCodeData(qrCodeString)
  }

  const handleClick = (): void => {
    setModalFullscreenContent(
      <PrintingPreview
        url={urlData.url}
        printingAllowed={!!enableDocumentPrinting}
      />
    )
  }

  return (
    <div
      className={`rf-w-full rf-p-[25px] rf-flex rf-flex-col rf-items-center rf-gap-4 ${
        title || description ? 'rf-justify-between' : 'rf-justify-around'
      } rf-rounded rf-bg-cardFrameBackground rf-text-cardFrameText rf-overflow-y-scroll rf-scrollbar-thin`}
      style={{
        height: height,
        touchAction: 'pan-y'
      }}
    >
      {title && (
        // Title on the left if there is a description, middle otherwise
        <div
          className={`rf-w-full ${
            description ? 'rf-text-left' : 'rf-text-center'
          } rf-title-size-auto ${
            isUsedOnBorne ? 'rf-truncate' : 'rf-line-clamp-2'
          }`}
        >
          {capitalizeFirstLetter(title)}
        </div>
      )}
      {isUsedOnBorne ? (
        <React.Fragment>
          {urlData.url.length > 4 &&
            urlData.url.substring(urlData.url.length - 4) === '.pdf' && (
              <div className='rf-w-full rf-pb-10 rf-flex rf-items-center rf-justify-center'>
                <button
                  className='rf-px-4 rf-py-1 rf-flex rf-flex-row rf-gap-2 rf-items-center rf-border-2 rf-text-primary rf-border-primary hover:rf-text-truewhite hover:rf-border-truewhite rf-rounded-lg rf-text-sm rf-font-bold'
                  onClick={handleClick}
                >
                  <FileIcon color={themeColors.primary} />
                  {translation.link.buttonText}
                </button>
              </div>
            )}
          <div className='rf-w-full rf-flex rf-flex-row rf-items-center rf-justify-center'>
            {description && <div>{description}</div>}
            {qrCodeData && <QRCodeComponent src={qrCodeData} />}
          </div>
          <div className='rf-relative rf-text-center rf-mx-8 rf-italic'>
            <InfoWithCircleIcon className='rf-absolute rf-top-[0.125rem] -rf-left-6 rf-h-4' />
            {textToDisplay}
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {description && <div className='rf-mb-4'>{description}</div>}
          {urlData.download && urlData.title && textToDisplay.length >= 40 ? (
            <BorderlessButton
              url={urlData.url}
              text={textToDisplay}
              download={true}
            />
          ) : (
            <ClassicButton
              url={urlData.url}
              text={textToDisplay}
              description={!!description}
              download={!!urlData.download}
            />
          )}
        </React.Fragment>
      )}
    </div>
  )
}

export default LinkAttachment
