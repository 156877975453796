import React, { useEffect, useState } from 'react'
import {
  RetorikMainComponentProps,
  RetorikWidgetProps
} from '../../models/retorikTypes'
import { ContainerParent } from '../../models/enums'
import { useLocaleStore } from '../Contexts/localeStore'
import { setLayout, useRetorikStore } from '../Contexts/retorikStore'
import {
  toggleDisplay,
  toggleWidget,
  useWidgetStore
} from '../Contexts/widgetStore'
import {
  getWidgetBorderStyle,
  getWidgetButtonStyle
} from '../../utils/getWidgetStyles'
import { widgetDefault } from '../../models/colors'
import { WidgetFrame } from '../../models/types'
import CloseIcon from '../Icons/Miscellaneous/CloseIcon'
import ExtendIcon from '../Icons/Miscellaneous/ExtendIcon'

interface LayoutHandlerProps
  extends RetorikMainComponentProps,
    Partial<RetorikWidgetProps> {
  children: any
}

const LayoutHandler = (props: LayoutHandlerProps) => {
  const translation = useLocaleStore((state) => state.currentTranslations)
  const currentLayout = useRetorikStore((state) => state.currentLayout)
  const displayChangeLayoutButton = useRetorikStore(
    (state) => state.configuration
  ).allowSwitchLayout
  const { open, loaded, widgetDisplayType } = useWidgetStore()
  const [currentConfiguration, setCurrentConfiguration] = useState<
    WidgetFrame | undefined
  >(props.widgetConfig?.large)
  const [widgetButton, setWidgetButton] = useState<string>('')
  const [widgetBorder, setWidgetBorder] = useState<string>('')
  const [mobileWidth, setMobileWidth] = useState<boolean>(
    window.innerWidth < 600
  )
  const [frameStyle, setFrameStyle] = useState<Record<string, any>>({})

  const handleResize = (): void => {
    setMobileWidth(window.innerWidth < 600)
  }

  useEffect(() => {
    setWidgetButton(getWidgetButtonStyle(props.widgetConfig?.button))
    window.addEventListener('resize', handleResize)

    return (): void => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    mobileWidth
      ? setFrameStyle({
          width: open ? '100%' : '0px',
          height: '100%'
        })
      : setFrameStyle({
          top: currentConfiguration?.position?.top,
          bottom: currentConfiguration?.position?.bottom
            ? currentConfiguration?.position?.bottom
            : currentConfiguration?.position?.top ||
              currentConfiguration?.position?.left ||
              currentConfiguration?.position?.right
            ? undefined
            : '1rem',
          left: currentConfiguration?.position?.left,
          right: currentConfiguration?.position?.right
            ? currentConfiguration?.position?.right
            : currentConfiguration?.position?.top ||
              currentConfiguration?.position?.bottom ||
              currentConfiguration?.position?.left
            ? undefined
            : '1rem',
          borderColor: currentConfiguration?.border?.color || widgetDefault.rgb
        })
  }, [mobileWidth, currentConfiguration])

  useEffect(() => {
    switch (widgetDisplayType) {
      case 'large':
        setCurrentConfiguration(props.widgetConfig?.large)
        setWidgetBorder(getWidgetBorderStyle(props.widgetConfig?.large?.border))
        break
      case 'thumbnail':
        setCurrentConfiguration(
          props.widgetConfig?.thumbnail || props.widgetConfig?.large
        )
        setWidgetBorder(
          getWidgetBorderStyle(
            props.widgetConfig?.thumbnail?.border ||
              props.widgetConfig?.large?.border
          )
        )
        break
      default:
        break
    }
  }, [widgetDisplayType])

  const extendButtonHandler = (): void => {
    // if different display type available
    if (props.widgetConfig?.large && props.widgetConfig?.thumbnail) {
      toggleDisplay()
    } else {
      setLayout(ContainerParent.agent)
    }
  }

  return (
    <>
      {props.widgetConfig?.button?.display !== false && (
        <button
          className={`rf-fixed rf-px-2 rf-flex rf-flex-row rf-items-center ${widgetButton}`}
          style={{
            color: props.widgetConfig?.button?.text?.color || widgetDefault.rgb,
            backgroundColor:
              props.widgetConfig?.button?.background?.color || 'transparent',
            borderWidth:
              props.widgetConfig?.button?.border?.thickness !== undefined
                ? `${props.widgetConfig?.button?.border?.thickness}px`
                : '1px',
            borderColor:
              props.widgetConfig?.button?.border?.color || widgetDefault.rgb,
            top: props.widgetConfig?.button?.position?.top,
            bottom: props.widgetConfig?.button?.position?.bottom
              ? props.widgetConfig?.button?.position?.bottom
              : props.widgetConfig?.button?.position?.top ||
                props.widgetConfig?.button?.position?.left ||
                props.widgetConfig?.button?.position?.right
              ? undefined
              : '1rem',
            left: props.widgetConfig?.button?.position?.left,
            right: props.widgetConfig?.button?.position?.right
              ? props.widgetConfig?.button?.position?.right
              : props.widgetConfig?.button?.position?.top ||
                props.widgetConfig?.button?.position?.bottom ||
                props.widgetConfig?.button?.position?.left
              ? undefined
              : '1rem'
          }}
          onClick={toggleWidget}
          id='retorik-widget-button'
        >
          {/* Image on the left if there is a URL and either position = 'left' or no position */}
          {props.widgetConfig?.button?.image?.url &&
            props.widgetConfig?.button?.image?.position !== 'right' && (
              <img
                className={`${
                  props.widgetConfig?.button?.image.marginY === false
                    ? ''
                    : 'rf-my-1'
                }`}
                src={`${props.widgetConfig.button.image.url}`}
                alt='Retorik widget'
                style={{
                  height: props.widgetConfig?.button?.image.height || '2rem'
                }}
              />
            )}
          {/* Text content */}
          <div className='rf-py-2 rf-ml-1'>
            {props.widgetConfig?.button?.text?.content ||
              translation.common.needhelp}
          </div>
          {/* Image on the right if there is a URL and position = 'right' */}
          {props.widgetConfig?.button?.image?.url &&
            props.widgetConfig?.button?.image?.position === 'right' && (
              <img
                className={`${
                  props.widgetConfig?.button?.image.marginY === false
                    ? ''
                    : 'rf-my-1'
                }`}
                src={`${props.widgetConfig.button.image.url}`}
                alt='Retorik widget'
                style={{
                  height: props.widgetConfig?.button?.image.height || '2rem'
                }}
              />
            )}
        </button>
      )}
      {loaded && (
        <div
          className={`${
            currentLayout === ContainerParent.widget
              ? 'rf-fixed rf-pt-8 rf-height-fill-available rf-transition-all rf-overflow-hidden rf-max-w-[100vw]'
              : ''
          }`}
          style={
            currentLayout === ContainerParent.widget
              ? {
                  ...frameStyle,
                  width: open
                    ? mobileWidth
                      ? '100%'
                      : currentConfiguration?.width || '400px'
                    : '0px',
                  height:
                    currentConfiguration?.height ||
                    (mobileWidth ? '100%' : '630px')
                }
              : {}
          }
          data-testid='widget-wrapper'
        >
          {currentLayout === ContainerParent.widget && (
            <React.Fragment>
              {/* Layout change button */}
              {!!displayChangeLayoutButton && !mobileWidth && (
                <button
                  onClick={extendButtonHandler}
                  aria-label='Extend'
                  className='rf-absolute rf-z-exitwidget rf-flex rf-right-8 rf-top-0 rf-p-1 rf-bg-truewhite rf-border rf-border-trueblack rf-rounded-lg'
                >
                  <ExtendIcon
                    className='rf-h-4 rf-w-4'
                    color='#000000'
                    extended={false}
                  />
                </button>
              )}

              {/* Close button */}
              <button
                onClick={toggleWidget}
                aria-label='Close'
                className='rf-absolute rf-z-exitwidget rf-flex rf-right-0 rf-top-0 rf-p-1 rf-bg-truewhite rf-border rf-border-trueblack rf-rounded-lg'
              >
                <CloseIcon className='rf-h-4 rf-w-4' color='#000000' />
              </button>
            </React.Fragment>
          )}

          <div
            className={`${
              currentLayout === ContainerParent.widget
                ? `rf-relative rf-h-full rf-w-full rf-overflow-hidden ${
                    !mobileWidth && widgetBorder
                  }`
                : ''
            }`}
            style={
              currentLayout === ContainerParent.widget
                ? {
                    borderColor: frameStyle.borderColor
                  }
                : {}
            }
          >
            {props.children}
          </div>
        </div>
      )}
    </>
  )
}

export default LayoutHandler
