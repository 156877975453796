/* eslint-disable react/jsx-boolean-value */
import React from 'react'
import { jsxToHtmlElement } from '../../../utils/jsxToHtmlElement'
import * as AC from 'adaptivecards'
import { ChoiceWithUrl } from './ChoiceWithUrl'
import MultiSelect from '../ChoiceSetImages/MultiSelect'
import MonoSelect from '../ChoiceSetImages/MonoSelect'

export class ImageChoiceSetClass extends AC.Input {
  private _value

  static readonly JsonTypeName = 'Retorik.ImageChoiceSet'

  getJsonTypeName(): string {
    return ImageChoiceSetClass.JsonTypeName
  }

  // Add custom preperties to schema
  static readonly isMultiSelectProperty = new AC.BoolProperty(
    AC.Versions.v1_0,
    'isMultiSelect',
    false
  )

  static readonly smallImagesProperty = new AC.BoolProperty(
    AC.Versions.v1_0,
    'smallImages',
    false
  )

  static readonly showCaptionsProperty = new AC.BoolProperty(
    AC.Versions.v1_0,
    'showCaptions',
    false
  )

  static readonly choicesWithUrlProperty =
    new AC.SerializableObjectCollectionProperty(
      AC.Versions.v1_0,
      'choicesWithUrl',
      ChoiceWithUrl
    )

  // multiSelect property accessors
  @AC.property(ImageChoiceSetClass.isMultiSelectProperty)
  get isMultiSelect(): boolean {
    return !!this.getValue(ImageChoiceSetClass.isMultiSelectProperty)
  }

  set isMultiSelect(value: boolean) {
    if (this.isMultiSelect !== value) {
      this.setValue(ImageChoiceSetClass.isMultiSelectProperty, value)
      this.updateLayout()
    }
  }

  // smallImages property accessors
  @AC.property(ImageChoiceSetClass.smallImagesProperty)
  get smallImages(): boolean {
    return !!this.getValue(ImageChoiceSetClass.smallImagesProperty)
  }

  set smallImages(value: boolean) {
    if (this.smallImages !== value) {
      this.setValue(ImageChoiceSetClass.smallImagesProperty, value)
      this.updateLayout()
    }
  }

  // showCaptions property accessors
  @AC.property(ImageChoiceSetClass.showCaptionsProperty)
  get showCaptions(): boolean {
    return !!this.getValue(ImageChoiceSetClass.showCaptionsProperty)
  }

  set showCaptions(value: boolean) {
    if (this.smallImages !== value) {
      this.setValue(ImageChoiceSetClass.showCaptionsProperty, value)
      this.updateLayout()
    }
  }

  // choicesWithUrl property accessors
  @AC.property(ImageChoiceSetClass.choicesWithUrlProperty)
  get choicesWithUrl(): ChoiceWithUrl {
    return this.getValue(ImageChoiceSetClass.choicesWithUrlProperty) || []
  }

  set choicesWithUrl(value: ChoiceWithUrl) {
    if (this.choicesWithUrl !== value) {
      this.setValue(ImageChoiceSetClass.choicesWithUrlProperty, value)
      this.updateLayout()
    }
  }

  public get value(): any {
    return this._value
  }

  public isSet(): any {
    return this._value
  }

  protected internalRender(): HTMLElement {
    const element = jsxToHtmlElement(this.renderReact())
    return element
  }

  protected renderReact = (): JSX.Element => {
    if (!this.id) {
      return <React.Fragment />
    }

    if (this.isMultiSelect) {
      return (
        <MultiSelect
          id={this.id}
          choices={this.choicesWithUrl}
          smallImages={this.smallImages}
          showCaptions={this.showCaptions}
        />
      )
    } else {
      return (
        <MonoSelect
          id={this.id}
          choices={this.choicesWithUrl}
          smallImages={this.smallImages}
          showCaptions={this.showCaptions}
        />
      )
    }
  }
}
