import React, { useEffect, useRef } from 'react'
import * as AC from 'adaptivecards'
import { useAdaptiveCardStore } from '../Contexts/adaptiveCardStore'

interface AdaptiveCardAttachmentProps {
  content: any
  height?: number
}

const adaptiveCard = new AC.AdaptiveCard()

const AdaptiveCardAttachment = ({
  content,
  height
}: AdaptiveCardAttachmentProps): JSX.Element => {
  const adaptiveCardHostConfig = useAdaptiveCardStore(
    (state) => state.adaptiveCardHostConfig
  )
  const divRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    adaptiveCard.hostConfig = adaptiveCardHostConfig
  }, [])

  useEffect(() => {
    content.minHeight = height ? `${height}px` : '0px'
    content.verticalContentAlignment = 'Center'

    adaptiveCard.parse(content)
    const htmlValue = adaptiveCard.render()

    if (divRef.current) {
      divRef.current.firstChild?.remove()
      htmlValue && divRef.current.appendChild(htmlValue)
    }
  }, [content, height])

  return <div ref={divRef} />
}

export default AdaptiveCardAttachment
