import React, { useEffect, useRef, useState } from 'react'
import QRCode from 'qrcode'
import type { FeatureCollection } from 'geojson'

import { useLocaleStore } from '../../Contexts/localeStore'
import { useRetorikStore } from '../../Contexts/retorikStore'

import {
  openRoute,
  openRouteAPIKey,
  googleMapsAddress
} from '../../../models/constants'
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter'

import RoutesItem from './RoutesItem'
import { OpenLocationIcon } from '../../Icons/MapIcons'

interface RoutesProps {
  coordinates: number[][]
  setMapLayerData: (data: FeatureCollection | undefined) => void
  color?: string
  setQrCodeUrl: (url: string) => void
}

const Routes = ({
  coordinates,
  setMapLayerData,
  color,
  setQrCodeUrl
}: RoutesProps): JSX.Element => {
  const translation = useLocaleStore((state) => state.currentTranslations)
  const locale = useLocaleStore((state) => state.locale)
  const isUsedOnBorne = useRetorikStore(
    (state) => state.configuration.isUsedOnBorne
  )

  const [drivingCar, setDrivingCar] = useState<FeatureCollection | undefined>(
    undefined
  )
  const [cyclingRegular, setCyclingRegular] = useState<
    FeatureCollection | undefined
  >(undefined)
  const [footWalking, setFootWalking] = useState<FeatureCollection | undefined>(
    undefined
  )
  const [currentChosen, setCurrentChosen] = useState<string | undefined>(
    undefined
  )

  const travelModeRef = useRef<string>()

  /**
   * On component mount :
   *  - call methods to retrieve data from openroute API
   */
  useEffect(() => {
    calculateForProfile('driving-car')
    calculateForProfile('cycling-regular')
    calculateForProfile('foot-walking')
  }, [])

  /**
   * On currentChosen state change :
   *  - switch on currentChosen value to display the associated data on the map
   */
  useEffect(() => {
    switch (currentChosen) {
      case 'drivingCar':
        drivingCar && drivingCar.features && setMapLayerData(drivingCar)
        travelModeRef.current = 'driving'
        break
      case 'cyclingRegular':
        cyclingRegular &&
          cyclingRegular.features &&
          setMapLayerData(cyclingRegular)
        travelModeRef.current = 'bicycling'
        break
      case 'footWalking':
        footWalking && footWalking.features && setMapLayerData(footWalking)
        travelModeRef.current = 'walking'
        break
      default:
        break
    }
  }, [currentChosen])

  /**
   * On drivingCar state change :
   *  - if driving car data were retrieved and no course has been chosen, set currentChosen state to 'driving-car'
   */
  useEffect(() => {
    drivingCar && !currentChosen && setCurrentChosen('drivingCar')
  }, [drivingCar])

  /**
   * On call :
   *  - call the openroute API to retrieve data for the current course
   * @param profile : string
   */
  const calculateForProfile = async (profile: string): Promise<void> => {
    const url = `${openRoute}/${profile}/geojson`
    const data = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: openRouteAPIKey,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ coordinates: coordinates })
    })
      .then((result) => {
        return result.json()
      })
      .catch(() => {
        return null
      })

    if (data && !data.error) {
      switch (profile) {
        case 'driving-car':
          setDrivingCar(data)
          break
        case 'cycling-regular':
          setCyclingRegular(data)
          break
        case 'foot-walking':
          setFootWalking(data)
          break
        default:
          break
      }
    }
  }

  const handleClick = async (): Promise<void> => {
    if (travelModeRef?.current && coordinates.length === 2) {
      // Create an URL to Google Maps with coordinates and travelling mode
      const coordinatesInUrl = `origin=${coordinates[0][1]},${coordinates[0][0]}&destination=${coordinates[1][1]},${coordinates[1][0]}`
      const fullUrl = `${googleMapsAddress}${coordinatesInUrl}&travelmode=${travelModeRef.current}`

      if (isUsedOnBorne) {
        const url = await QRCode.toDataURL(fullUrl)
        setQrCodeUrl(url)
      } else {
        // If we are not on a borne, open a new tab in browser
        window.open(fullUrl, '_blank')
      }
    }
  }

  return drivingCar || cyclingRegular || footWalking ? (
    <div className='rf-absolute rf-bottom-2 rf-w-full rf-flex rf-flex-row rf-gap-4 rf-justify-center'>
      {/* Data per choice : Car / Cycle / Foot */}
      <div className='rf-grid rf-grid-cols-routes rf-pl-6 rf-pr-4 rf-py-2 rf-gap-4 rf-rounded-lg rf-bg-truewhite rf-text-xs large:rf-text-sm rf-shadow-[0_6px_6px_#00000029]'>
        <RoutesItem
          summary={{
            type: 'drivingCar',
            distance: drivingCar?.features?.length
              ? drivingCar.features[0].properties?.summary?.distance
              : undefined,
            duration: drivingCar?.features?.length
              ? drivingCar.features[0].properties?.summary?.duration
              : undefined
          }}
          chosen={currentChosen === 'drivingCar'}
          color={color}
          onClick={setCurrentChosen}
        />
        <RoutesItem
          summary={{
            type: 'cyclingRegular',
            distance: cyclingRegular?.features?.length
              ? cyclingRegular.features[0].properties?.summary?.distance
              : undefined,
            duration: cyclingRegular?.features?.length
              ? cyclingRegular.features[0].properties?.summary?.duration
              : undefined
          }}
          chosen={currentChosen === 'cyclingRegular'}
          color={color}
          onClick={setCurrentChosen}
        />
        <RoutesItem
          summary={{
            type: 'footWalking',
            distance: footWalking?.features?.length
              ? footWalking.features[0].properties?.summary?.distance
              : undefined,
            duration: footWalking?.features?.length
              ? footWalking.features[0].properties?.summary?.duration
              : undefined
          }}
          chosen={currentChosen === 'footWalking'}
          color={color}
          onClick={setCurrentChosen}
        />
      </div>

      {/* Redirection button */}
      <button
        className='rf-aspect-square rf-w-3/20 rf-min-w-3/20 rf-max-w-3/20 large:rf-w-1/8 large:rf-min-w-1/8 large:rf-max-w-1/8 rf-overflow-y-hidden rf-p-1 rf-flex rf-flex-col rf-justify-evenly rf-items-center rf-border-2 rf-border-trueblack rf-rounded-lg rf-font-bold rf-text-xs large:rf-text-sm rf-bg-truewhite'
        onClick={handleClick}
      >
        <OpenLocationIcon className='rf-w-5 rf-h-5' />
        {capitalizeFirstLetter(translation.poi.route, locale)}
      </button>
    </div>
  ) : (
    <React.Fragment />
  )
}

export default Routes
