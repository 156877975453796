/* eslint-disable react/jsx-boolean-value */
import React from 'react'
import { useLocaleStore } from '../../Contexts/localeStore'
import type { RetorikActivity } from '../../../models/activityTypes'
import DisplayStandard from './DisplayStandard'
import { useRetorikStore } from '../../Contexts/retorikStore'
import AnimationContainer from '../../Loader/AnimationContainer'
import { AnimationSize } from '../../../models/enums'

interface ConversationDisplayProps {
  userQuestion: RetorikActivity | undefined
  botResponses?: Array<RetorikActivity>
  userQuestionTime?: Date
  isMobile?: boolean
  triggerScroll: () => void
  canBeStreaming: boolean
}

const ConversationDisplay = ({
  userQuestion,
  botResponses,
  userQuestionTime,
  isMobile,
  triggerScroll,
  canBeStreaming
}: ConversationDisplayProps): JSX.Element => {
  const locale = useLocaleStore((state) => state.locale)
  const isWaitingForResponse = useRetorikStore(
    (state) => state.isWaitingForResponse
  )

  return (
    <React.Fragment>
      {userQuestion?.text && (
        <div
          id='retorik-userQuestion'
          key={userQuestion.id}
          className='rf-pb-4 rf-text-textModePanelConversationUser rf-animate-slideBottom rf-flex rf-flex-col rf-justify-start rf-text-size-auto rf-gap-2'
        >
          {/* Time */}
          {userQuestionTime && (
            <div className='rf-px-4 rf-text-[#9A9A9A]'>
              {userQuestionTime.toLocaleString(locale, {
                hour: 'numeric',
                minute: 'numeric'
              })}
            </div>
          )}

          {/* Text */}
          <div className='rf-px-4'>{userQuestion.text}</div>
        </div>
      )}

      {isWaitingForResponse && (!isMobile || (isMobile && canBeStreaming)) && (
        <AnimationContainer
          classname='rf-flex rf-justify-start rf-pl-8 rf-items-center'
          subClassname='rf-w-4 rf-h-4'
          useTextColor={true}
          size={AnimationSize.medium}
          triggerScroll={triggerScroll}
        />
      )}

      <div
        id='retorik-botResponses'
        className='rf-flex rf-flex-col rf-text-size-auto rf-gap-2 rf-scrollbar-hidden'
      >
        <DisplayStandard
          triggerScroll={triggerScroll}
          botResponses={botResponses || []}
          isMobile={isMobile}
          canBeStreaming={canBeStreaming}
        />
      </div>
    </React.Fragment>
  )
}

export default ConversationDisplay
