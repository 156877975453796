import { SpeechMarkdown } from '@davi-ai/speechmarkdown-davi-js'
import { SpeechSynthesisUtterance } from '@davi-ai/web-speech-cognitive-services-davi'
import type { CreateUtteranceParams } from '../models/speechTypes'
import { SayAsItem } from '../models/types'

const buildSSML = (
  activity: any,
  speechMarkdownParser: SpeechMarkdown,
  sayAsWords?: Array<SayAsItem>
): string => {
  let speech: string = activity.speak || activity.text

  // Replace character not recognized in Microsoft TTS
  speech = speech.replaceAll('’', "'")

  if (sayAsWords && sayAsWords.length) {
    const wordsUsed: Array<string> = []
    sayAsWords.forEach((tuple) => {
      if (!wordsUsed.includes(tuple.text)) {
        const regex = new RegExp(`\\b${tuple.text}\\b`, 'gi')
        speech = speech.replaceAll(regex, `(${tuple.text})[ipa:"${tuple.ipa}"]`)
        wordsUsed.push(tuple.text)
      }
    })
  }

  let textParsedAsSSML = ''
  try {
    textParsedAsSSML = speechMarkdownParser.toSSML(speech)
  } catch (e) {
    console.warn('Retorik Framework > Error in speechmarkdown parser : ', e)
  }

  return (
    `<speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="https://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" xml:lang="${activity.locale}">` +
    textParsedAsSSML +
    '</speak>'
  )
}

export default function createUtterance({
  activity,
  voice,
  locale,
  sayAsWords
}: CreateUtteranceParams): SpeechSynthesisUtterance {
  // If there is no text to be read, create an empty utterance that will be put in the array of ended activities
  if (!voice || (!activity.speak && !activity.text)) {
    return new SpeechSynthesisUtterance('')
  }

  const options = {
    platform: 'microsoft-azure',
    includeSpeakTag: false,
    globalVoiceAndLang: {
      voice: voice.name,
      lang: voice.name.toLowerCase().includes('multilingual')
        ? locale
        : undefined
    }
  }
  const speechMarkdownParser = new SpeechMarkdown(options)

  const utterance = new SpeechSynthesisUtterance(
    buildSSML(activity, speechMarkdownParser, sayAsWords)
  )

  return utterance
}
