import React from 'react'
import { useViewStore } from '../Contexts/viewStore'
import { sendMessage } from '../Contexts/directLineStore'

import type { POIContentType } from '../../models/attachmentTypes'
import { PhotoCameraIcon, FavoriteLightIcon } from '../Icons/DetailedPOIIcons'
import CommentsPennantMini from './DetailedAttachment/CommentsPennantMini'

const ReviewsAndFavorite = ({ reviews, favorite }): JSX.Element => {
  return (
    <React.Fragment>
      {reviews && (
        <CommentsPennantMini
          customerReviews={reviews}
          className='rf-absolute rf-top-0 rf-right-2'
        />
      )}
      {favorite && (
        <FavoriteLightIcon className='rf-absolute rf-h-6 large:rf-h-8 rf-bottom-2 rf-right-2' />
      )}
    </React.Fragment>
  )
}

const POIAttachment = ({
  media,
  title,
  subtitle,
  favorite,
  customerReviews,
  tap,
  distance
}: POIContentType): JSX.Element => {
  const themeColors = useViewStore((state) => state.themeColors)

  const handleClick = (): void => {
    tap && sendMessage(undefined, tap)
  }

  return (
    <div
      className='rf-w-full rf-h-full rf-flex rf-flex-col rf-rounded hover:rf-cursor-pointer'
      onClick={handleClick}
    >
      {media && media[0]?.url ? (
        <div
          className='rf-relative rf-w-full rf-rounded rf-aspect-video'
          style={{
            backgroundImage: `url(${media[0].url.replaceAll("'", '%27')})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
          }}
        >
          <ReviewsAndFavorite reviews={customerReviews} favorite={favorite} />
        </div>
      ) : (
        <div className='rf-relative rf-w-full rf-bg-silver rf-rounded rf-aspect-video'>
          <PhotoCameraIcon className='rf-absolute rf-h-1/3 rf-top-1/3 rf-left-1/2 rf-transform -rf-translate-x-1/2' />
          <ReviewsAndFavorite reviews={customerReviews} favorite={favorite} />
        </div>
      )}

      {/* Subtitle 2 lines max / title 2 lines max -> agenda only */}
      {subtitle && (
        <p
          className='rf-mt-2 rf-font-bold rf-line-clamp-2'
          style={{
            color: themeColors.agendaColor
          }}
        >
          {subtitle}
        </p>
      )}
      {/* Distance -> all POI but agenda */}
      {distance && (
        <p className='rf-mt-2 rf-font-bold rf-text-gray'>{`${(
          distance / 1000
        ).toFixed(1)} km`}</p>
      )}
      {/* Title */}
      <p className='rf-my-2 rf-font-bold rf-line-clamp-2'>{title || ''}</p>
    </div>
  )
}

export default POIAttachment
