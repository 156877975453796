import React, { useEffect, useMemo, useState } from 'react'
import { useLocaleStore } from '../../Contexts/localeStore'
import { useSpeechStore } from '../../Contexts/speechStore'
import { useRetorikStore } from '../../Contexts/retorikStore'

import type { RetorikActivity } from '../../../models/activityTypes'
import { getTextWithoutSpeechMarkdown } from '../../../utils/speechMarkdownUtils'
import { checkListDisplay } from '../../../utils/checkListDisplay'

import Attachments from '../../Attachments/Attachments'
import DisplayStreaming from './DisplayStreaming'

interface DisplayStandardProps {
  triggerScroll: () => void
  botResponses: Array<RetorikActivity>
  isMobile?: boolean
  canBeStreaming: boolean
}

const DisplayStandard = ({
  botResponses,
  isMobile,
  triggerScroll,
  canBeStreaming
}: DisplayStandardProps): JSX.Element => {
  const locale = useLocaleStore((state) => state.locale)
  const currentStreaming = useSpeechStore((state) => state.currentStreaming)
  const streamingId = useSpeechStore((state) => state.streamingReplyToId)
  const forceMobileView = useRetorikStore(
    (state) => state.configuration.forceMobileView
  )

  const [streamingActivity, setStreamingActivity] = useState<
    RetorikActivity | undefined
  >(undefined)
  const [streamingEnded, setStreamingEnded] = useState<boolean>(false)

  const nonStreamingActivities = useMemo<Array<RetorikActivity>>(() => {
    if (streamingActivity) {
      return botResponses.filter(
        (act) =>
          act.id !== streamingActivity.id &&
          act.replyToId === streamingActivity.replyToId
      )
    }

    return botResponses
  }, [streamingActivity, botResponses])

  useEffect(() => {
    if (botResponses.length && currentStreaming) {
      setStreamingActivity(
        botResponses.find(
          (act) => act.replyToId === streamingId && act.value?.streamingCount
        )
      )
    } else {
      setStreamingActivity(undefined)
    }
  }, [botResponses, currentStreaming, streamingId])

  return (
    <React.Fragment>
      {canBeStreaming && (
        <DisplayStreaming
          triggerScroll={triggerScroll}
          setStreamingEnded={setStreamingEnded}
          streamingActivity={streamingActivity}
          currentStreaming={currentStreaming}
          isMobile={isMobile}
        />
      )}

      {(!streamingActivity || (streamingActivity && streamingEnded)) &&
        nonStreamingActivities.map((activity, key) => {
          const answerTime = activity.localTimestamp
            ? new Date(activity.localTimestamp)
            : activity.timestamp
            ? new Date(activity.timestamp)
            : new Date()

          return (
            <div
              key={key}
              className={`${
                key > 0 && 'rf-pt-2'
              } rf-text-textModePanelConversationBot rf-animate-slideBottom rf-flex rf-flex-col rf-items-start rf-gap-2`}
            >
              {/* Time */}
              {isMobile && (
                <div className='rf-px-4 rf-text-[#9A9A9A]'>
                  {answerTime.toLocaleString(locale, {
                    hour: 'numeric',
                    minute: 'numeric'
                  })}
                </div>
              )}

              {/* Text */}
              {activity.htmlText ? (
                <div
                  className='rf-px-4'
                  dangerouslySetInnerHTML={{
                    __html: activity.htmlText
                  }}
                />
              ) : activity.text ? (
                <div className='rf-px-4'>
                  {getTextWithoutSpeechMarkdown(activity.text)}
                </div>
              ) : (
                <React.Fragment />
              )}

              {/* Attachments */}
              {!!activity?.attachments?.length &&
                !checkListDisplay(activity) && (
                  <div
                    className={`${
                      forceMobileView ? 'rf-m-auto' : 'rf-w-full'
                    } ${
                      activity.attachments.length <= 1 && 'rf-px-4'
                    } rf-flex rf-flex-col rf-gap-2`}
                  >
                    <Attachments activity={activity} />
                  </div>
                )}
            </div>
          )
        })}
    </React.Fragment>
  )
}

export default DisplayStandard
