import React, { useEffect, useState } from 'react'
import { useLocaleStore } from '../../Contexts/localeStore'
import { useViewStore } from '../../Contexts/viewStore'
import { useUtilsStore, setCurrentSubView } from '../../Contexts/utilsStore'

import { CurrentSubView } from '../../../models/enums'
import languageInMotherTongue from '../../../translations/languageCodeToMotherTongue.json'
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter'
import { getMainLocale } from '../../../utils/checkLocale'

import ParameterButton from './ParameterButton'

const LanguageButton = (): JSX.Element => {
  const translation = useLocaleStore((state) => state.currentTranslations)
  const locale = useLocaleStore((state) => state.locale)
  const supported = useLocaleStore((state) => state.supported)
  const isMobile = useViewStore((state) => state.isMobile)
  const themeColors = useViewStore((state) => state.themeColors)
  const currentSubView = useUtilsStore((state) => state.currentSubView)

  const [mainLocale, setMainLocale] = useState<string>(
    getMainLocale(locale).toUpperCase()
  )

  useEffect(() => {
    setMainLocale(getMainLocale(locale).toUpperCase())
  }, [locale])

  /**
   * On call :
   *  - change RetorikContext's currentSubView state
   */
  const handleClick = (): void => {
    setCurrentSubView(
      currentSubView === CurrentSubView.languages
        ? CurrentSubView.none
        : CurrentSubView.languages
    )
  }

  return supported.length > 1 ? (
    isMobile ? (
      <ParameterButton
        title={translation.common.lang}
        icon='lang'
        color={
          currentSubView === CurrentSubView.languages
            ? themeColors.secondary
            : '#A4A7AF'
        }
        onClick={handleClick}
      />
    ) : (
      <button
        className='rf-absolute rf-min-w-24 rf-bottom-16 large-vertical:rf-bottom-[25vh] rf-right-0 rf-px-2 rf-flex rf-flex-row rf-items-center rf-rounded-l-lg'
        style={{
          background: 'linear-gradient(#F8F8FC, #EDEEF3)'
        }}
        onClick={handleClick}
      >
        {/* Flag */}
        <div className='rf-h-8 rf-mr-2'>
          <img
            className='rf-h-full'
            src={`https://flagsapi.com/${mainLocale}/flat/64.png`}
          />
        </div>

        {/* Texts */}
        <div className='rf-flex rf-flex-col rf-items-start rf-text-xsm'>
          <div className='rf-text-trueblack'>
            {capitalizeFirstLetter(translation.common.singleLang)}
          </div>
          <div className='rf-text-lightgray'>
            {capitalizeFirstLetter(
              languageInMotherTongue[locale.split('-')[0]]
            )}
          </div>
        </div>
      </button>
    )
  ) : (
    <React.Fragment />
  )
}

export default LanguageButton
