import React, { useEffect, useState } from 'react'
import {
  RetorikMainComponentConfiguration,
  RetorikMainComponentProps
} from '../../models/retorikTypes'
import RetorikContainer from './RetorikContainer'
import getRetorikConfigs from '../../utils/getConfigs'
import Loader from '../Loader/Loader'
import { AvailableViews, ContainerParent } from '../../models/enums'
import { DEFAULT_VIEWS } from '../../models/constants'
import getThemeColors, { deepMerge } from '../../utils/getThemeColors'
import { fetchEmergencyData } from '../../utils/fetchEmergencyData'
import { EmergencyTypes } from '../../models/emergencyTypes'
import { getRetorikGDPR } from '../../utils/getGDPR'
import {
  setGDPRDisplay,
  setGDPRUserConsent,
  setGDPRWindowData
} from '../Contexts/utilsStore'

interface RetorikConfigHandlerProps extends RetorikMainComponentProps {
  parent: number
}

const RetorikConfigHandler = (props: RetorikConfigHandlerProps) => {
  const [fetchedConfig, setFetchedConfig] =
    useState<RetorikMainComponentConfiguration>()
  const [mainConfig, setMainConfig] = useState<
    RetorikMainComponentConfiguration | undefined
  >(undefined)
  const [isConfigUpdated, setIsConfigUpdated] = useState<boolean>(false)

  /**
   * Check if emergency data can be retrieved to remove the button in menu if no data available
   * @param configuration RetorikMainComponentConfiguration
   */
  const checkEmergencyAndSetConfig = async (
    configuration: RetorikMainComponentConfiguration
  ): Promise<void> => {
    const data: EmergencyTypes = await fetchEmergencyData(
      configuration.addressData
    )

    const tempConfiguration = { ...configuration }

    if (!data) {
      const views = tempConfiguration.config?.baseMenu || DEFAULT_VIEWS
      const tempBaseMenu = views.filter(
        (menu) =>
          // @ts-ignore : error on menu.view that doesn't exist in type CustomMenu
          !menu.view || menu.view !== AvailableViews.emergency
      )
      tempConfiguration.config &&
        (tempConfiguration.config.baseMenu = tempBaseMenu)
    }

    setMainConfig(tempConfiguration)
    setIsConfigUpdated(true)
  }

  const fetchConfigurationFromRetorikStudio = async (): Promise<void> => {
    const fetchedConfig = await getRetorikConfigs(props)
    console.log('Retorik Framework > configuration fetched from Retorik Studio')
    fetchedConfig && setFetchedConfig(fetchedConfig)
  }

  const checkGDPR = async (): Promise<void> => {
    const data = await getRetorikGDPR({
      configurationData: props.GDPR,
      addressData: props.addressData
    })

    // Set GDPR data in utilsStore
    data.data && setGDPRWindowData(data.data)
    setGDPRDisplay(data.displayGDPR)
    typeof data.userConsent === 'boolean' &&
      setGDPRUserConsent(data.userConsent)
  }

  /**
   * Fetch configuration once and det GDPR data from Retorik Studio and set them in store
   */
  useEffect(() => {
    props && fetchConfigurationFromRetorikStudio()
    props.addressData && checkGDPR()
  }, [])

  useEffect(() => {
    if (fetchedConfig && props) {
      const {
        skipLoader,
        defaultMode,
        config: _config,
        viewsConfig,
        chatbotData,
        chatbotDataWidget,
        agentData,
        ponyfillFactoryCredentials,
        customVoice,
        colors
      } = fetchedConfig

      const mergedMainConfig: RetorikMainComponentConfiguration = {
        config:
          props.config !== undefined
            ? deepMerge(props.config, _config)
            : _config,
        viewsConfig:
          props.viewsConfig !== undefined
            ? deepMerge(props.viewsConfig, viewsConfig)
            : viewsConfig,
        chatbotData:
          props.chatbotData !== undefined
            ? { ...chatbotData, ...props.chatbotData }
            : chatbotData,
        chatbotDataWidget:
          props.chatbotDataWidget !== undefined
            ? {
                ...chatbotDataWidget,
                ...props.chatbotDataWidget
              }
            : chatbotDataWidget,
        agentData: props.agentData !== undefined ? props.agentData : agentData,
        ponyfillFactoryCredentials:
          props.ponyfillFactoryCredentials !== undefined
            ? props.ponyfillFactoryCredentials
            : ponyfillFactoryCredentials,
        customVoice:
          props.customVoice !== undefined
            ? { ...customVoice, ...props.customVoice }
            : customVoice,
        colors:
          props.colors !== undefined
            ? deepMerge(props.colors, getThemeColors(colors))
            : getThemeColors(colors),
        skipLoader:
          props.skipLoader !== undefined ? props.skipLoader : skipLoader,
        defaultMode:
          props.defaultMode !== undefined ? props.defaultMode : defaultMode,
        // Data not retrieved from Retorik Studio
        addressData: props.addressData,
        userData: props.userData,
        externalEventHandler: props.externalEventHandler,
        externalComponents: props.externalComponents,
        width: props.width,
        height: props.height
      }

      // Prevent menus from being copied again after each modification of configuration
      if (mergedMainConfig.config) {
        mergedMainConfig.config.baseMenu =
          props.config?.baseMenu || props.menuConfig?.baseMenu
        mergedMainConfig.config.customMenu =
          props.config?.customMenu || props.menuConfig?.customMenu
        mergedMainConfig.config.subMenu =
          props.config?.subMenu || props.menuConfig?.subMenu
      }

      checkEmergencyAndSetConfig(mergedMainConfig)
    }
  }, [props, fetchedConfig])

  return mainConfig ? (
    <RetorikContainer parent={props.parent} {...mainConfig} />
  ) : (
    <Loader
      className={isConfigUpdated ? 'rf-animate-loaderFadeOut' : ''}
      parent={props.parent}
      width={props.width}
      height={props.height}
      fullSize={
        !!(
          props.parent !== ContainerParent.widget &&
          props?.config?.fullSize !== false
        )
      }
    />
  )
}

export default RetorikConfigHandler
