import React, { useEffect, useState } from 'react'
import { useUtilsStore } from '../../Contexts/utilsStore'
import {
  useAdaptiveCardStore,
  setValue,
  setCurrentId
} from '../../Contexts/adaptiveCardStore'
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter'
import { CurrentSubView } from '../../../models/enums'

interface MonoSelectProps {
  id: string
  choices: any
  smallImages: boolean
  showCaptions: boolean
}

const MonoSelect = ({
  id,
  choices,
  smallImages,
  showCaptions
}: MonoSelectProps): JSX.Element => {
  const currentSubView = useUtilsStore((state) => state.currentSubView)
  const values = useAdaptiveCardStore((state) => state.values)
  const [display, setDisplay] = useState<Array<any>>([])
  const [chosen, setChosen] = useState<string>((values.get(id) as string) || '')
  const [overflow, setOverflow] = useState<boolean>(false)
  const [style, setStyle] = useState<string>('')

  useEffect(() => {
    currentSubView !== CurrentSubView.history && setCurrentId(id)
  }, [])

  /**
   * On choices props change :
   *  - fill display state with choices value
   *  - set overflow and style states depending on the smallImages boolean prop and the number of choices
   */
  useEffect(() => {
    if (choices) {
      setDisplay(choices)
      if (smallImages) {
        if (choices.length > 6) {
          setOverflow(true)
          setStyle(
            'rf-flex rf-flex-row rf-justify-start rf-align-center rf-overflow-x-scroll rf-pb-4'
          )
        } else {
          setOverflow(false)
          setStyle(
            `rf-grid ${choices.length > 3 && 'rf-grid-rows-2'} rf-grid-cols-3`
          )
        }
      } else {
        if (choices.length > 2) {
          setOverflow(true)
          setStyle(
            'rf-flex rf-flex-row rf-justify-start rf-align-center rf-overflow-x-scroll rf-pb-4'
          )
        } else {
          setOverflow(false)
          setStyle('rf-grid rf-grid-rows-1 rf-grid-cols-2')
        }
      }
    }
  }, [choices])

  /**
   * On call:
   *  - check if the component will be rendered in history, if so bypass every interaction
   *  - fill chosen state with value
   *  - call parent's handleSelect method
   * @param value : string
   */
  const handleChange = (value: string): void => {
    if (currentSubView !== CurrentSubView.history) {
      setChosen(value)
      setValue(id, value)
    }
  }

  return display && display.length > 0 ? (
    <div
      className={`rf-w-full rf-gap-4 rf-text-size-auto rf-text-white ${style} rf-scrollbar-x-custom`}
    >
      {display.map((choice, key) => {
        return (
          <div
            key={key}
            className={`rf-h-fit rf-flex rf-flex-col rf-items-start ${
              overflow && 'rf-flex-[0_0_40%]'
            }`}
          >
            <label
              className={`rf-w-full rf-h-full rf-flex rf-justify-center rf-items-center rf-border-2 rf-rounded-lg ${
                choice.value === chosen
                  ? 'rf-border-primary'
                  : 'rf-border-formInputRadioCheckboxUncheckedBorder'
              } hover:rf-cursor-pointer rf-aspect-square rf-overflow-hidden`}
            >
              <img src={choice.url} className='rf-max-w-full rf-max-h-full' />
              <input
                type='radio'
                value={choice.value}
                checked={choice.value === chosen}
                className='rf-input-hidden'
                onChange={(): void => handleChange(choice.value)}
              />
            </label>
            {showCaptions && (
              <div className='rf-pt-2 rf-w-full rf-line-clamp-2'>
                {capitalizeFirstLetter(choice.title)}
              </div>
            )}
          </div>
        )
      })}
    </div>
  ) : (
    <React.Fragment />
  )
}

export default MonoSelect
