import React, { useState } from 'react'
import { useViewStore } from '../../Contexts/viewStore'
import useCloseButtonTop from '../../../hooks/useCloseButtonTop'
import DashboardButton from '../../Menu/Remote/DashboardButton'

interface AbsoluteLargeClosingButtonProps {
  dashboardVisible: boolean
  zIndexClassname?: string
  customComponent?: boolean
  onClick: () => void
}

const AbsoluteLargeClosingButton = ({
  dashboardVisible,
  zIndexClassname,
  customComponent,
  onClick
}: AbsoluteLargeClosingButtonProps): JSX.Element => {
  const isMobile = useViewStore((state) => state.isMobile)
  const top = useCloseButtonTop()
  const [hidden, setHidden] = useState<boolean>(false)

  const handleHideAndClose = (): void => {
    dashboardVisible && !customComponent && setHidden(true)
    onClick()
  }

  return isMobile ? (
    <React.Fragment />
  ) : (
    <div
      className={`rf-absolute ${
        hidden && 'rf-hidden'
      } rf-w-24 rf-flex rf-justify-center rf-right-0 ${zIndexClassname}`}
      style={{
        top: top
      }}
    >
      <DashboardButton
        dashboardVisible={dashboardVisible}
        onClick={handleHideAndClose}
      />
    </div>
  )
}

export default AbsoluteLargeClosingButton
