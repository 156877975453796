import React from 'react'
import { useLocaleStore } from '../../Contexts/localeStore'
import ParameterButton from './ParameterButton'

interface TutorialButtonProps {
  handleBack?: () => void
}

const TutorialButton = ({ handleBack }: TutorialButtonProps): JSX.Element => {
  const translation = useLocaleStore((state) => state.currentTranslations)

  const handleClick = (): void => {
    handleBack && handleBack()
    console.log('Launch tutorial')
  }

  return (
    <ParameterButton
      title={translation.common.tutorial}
      icon='tutorial'
      onClick={handleClick}
    />
  )
}

export default TutorialButton
