import React, { useState, useEffect } from 'react'
import { ConnectionStatus } from 'botframework-directlinejs'

import { setRetrievingConversation } from '../Contexts/activityStore'
import {
  useDirectLineStore,
  createDirectLine,
  resetDirectlineStore
} from '../Contexts/directLineStore'
import { useLocaleStore } from '../Contexts/localeStore'
import { useRetorikStore, setAppAvailable } from '../Contexts/retorikStore'

import type { WithChildren } from '../../models/utils'
import type { AddressData, UserData } from '../../models/types'
import type { DirectLineCreationData } from '../../models/directLine'
import { fetchDirectLineToken } from '../../utils/fetchDirectLineToken'

import SendActivityEventListener from '../Utils/SendActivityEventListener'

type RetorikComposerProps = WithChildren<{
  addressData: AddressData
  userData: UserData
  externalEventHandler?: (action: any, dispatch?: any) => boolean
  height?: number | 'full'
  width?: number | 'full'
  isRetorikNews: boolean
}>

const defaultUser = {
  name: 'default',
  id: `defaultId_${Date.now()}`,
  username: 'default',
  nom: 'defaultName',
  prenom: 'à vous',
  email: '',
  token: '',
  referrer: '',
  ipaddress: ''
}

const RetorikComposer = ({
  addressData,
  userData,
  externalEventHandler,
  children,
  isRetorikNews
}: RetorikComposerProps): JSX.Element => {
  const directLine = useDirectLineStore((state) => state.directLine)
  const connectionStatus = useDirectLineStore((state) => state.directLineStatus)
  const [directLineCreationdata, setDirectLineCreationData] =
    useState<DirectLineCreationData>()
  const configuration = useRetorikStore((state) => state.configuration)
  const loaderClosed = useRetorikStore((state) => state.loaderClosed)
  const agentData = useRetorikStore((state) => state.agentData)
  const locale = useLocaleStore((state) => state.locale)
  const supported = useLocaleStore((state) => state.supported)

  const [hasConversationCookie, setHasConversationCookie] =
    useState<boolean>(false)

  const processDirectLine = async (): Promise<void> => {
    fetchDirectLineToken(addressData).then((token) => {
      let test = true
      if (isRetorikNews) {
        const fullTenantName = (
          addressData.baseURI ||
          `${addressData.prefix || ''}${addressData.tenant}`
        ).replace(/;|,|\s/g, '')

        const newsConversationData = localStorage.getItem(
          `Retorik.News.Conversation.${fullTenantName}`
        )

        if (newsConversationData) {
          const data = JSON.parse(newsConversationData)
          if (data && data.conversationId) {
            setDirectLineCreationData({
              token: token,
              conversationId: data.conversationId
            })
            test = false
          }
        }
      } else if (configuration.enableConversationCookie !== false) {
        const conversationCookie = document.cookie
          .split('; ')
          .find((row) => row.startsWith('retorikConversationCookie='))
          ?.split('=')[1]

        if (conversationCookie) {
          const splitCookieData = conversationCookie.split('||')
          if (splitCookieData.length === 2) {
            const cookieConversationId = splitCookieData[0]
            const cookieTenantName = splitCookieData[1]
            const fullTenantName = (
              addressData.baseURI ||
              `${addressData.prefix || ''}${addressData.tenant}`
            ).replace(/;|,|\s/g, '')

            if (cookieTenantName === fullTenantName) {
              setDirectLineCreationData({
                token: token,
                conversationId: cookieConversationId,
                watermark: '0'
              })

              // Tell the activity store that we will retrieve several activities from an existing conversation, so that it displays only the last one
              setRetrievingConversation(true)
              setHasConversationCookie(true)
              test = false
            }
          }
        }
      }

      test &&
        setDirectLineCreationData({
          token: token
        })
    })
  }

  useEffect(() => {
    if (directLineCreationdata && locale) {
      !directLine &&
        createDirectLine({
          ...directLineCreationdata,
          conversationStartProperties: {
            locale: locale
          },
          userId: userData.id || defaultUser.id,
          externalActivityHandler: externalEventHandler
        })
    }
  }, [locale, directLineCreationdata])

  useEffect(() => {
    processDirectLine()

    return () => {
      console.log('Retorik Framework > Disconnect and end directline')
      resetDirectlineStore()
    }
  }, [])

  useEffect(() => {
    if (
      locale &&
      supported &&
      agentData &&
      connectionStatus === ConnectionStatus.Online &&
      (isRetorikNews || loaderClosed)
    ) {
      setAppAvailable(true)
    }
  }, [locale, supported, agentData, loaderClosed, connectionStatus])

  return directLine && agentData ? (
    <React.Fragment>
      <SendActivityEventListener
        isRetorikNews={isRetorikNews}
        hasConversationCookie={hasConversationCookie}
        userData={userData || defaultUser}
      />
      {children}
    </React.Fragment>
  ) : (
    <React.Fragment />
  )
}

export default RetorikComposer
