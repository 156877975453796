import React from 'react'
import { jsxToHtmlElement } from '../../../utils/jsxToHtmlElement'
import Likert from '../CustomElements/Likert'
import * as AC from 'adaptivecards'

export class LikertClass extends AC.Input {
  private _value = this.ratingMin

  static readonly JsonTypeName = 'Retorik.Likert'

  getJsonTypeName(): string {
    return LikertClass.JsonTypeName
  }

  public get value(): any {
    return this._value
  }

  public isSet(): any {
    return this._value
  }

  static readonly ratingMinProperty = new AC.NumProperty(
    AC.Versions.v1_0,
    'ratingMin',
    1
  )

  static readonly ratingMaxProperty = new AC.NumProperty(
    AC.Versions.v1_0,
    'ratingMax',
    5
  )

  static readonly emojisProperty = new AC.StringArrayProperty(
    AC.Versions.v1_0,
    'emojis',
    []
  )

  static readonly strokeColorProperty = new AC.StringProperty(
    AC.Versions.v1_0,
    'strokeColor',
    true
  )

  // ratingMin property accessors
  @AC.property(LikertClass.ratingMinProperty)
  get ratingMin(): number {
    return this.getValue(LikertClass.ratingMinProperty) || ''
  }

  set ratingMin(val: number) {
    if (this.ratingMin !== val) {
      this.setValue(LikertClass.ratingMinProperty, val)
    }
  }

  // ratingMax property accessors
  @AC.property(LikertClass.ratingMaxProperty)
  get ratingMax(): number {
    return this.getValue(LikertClass.ratingMaxProperty) || ''
  }

  set ratingMax(val: number) {
    if (this.ratingMax !== val) {
      this.setValue(LikertClass.ratingMaxProperty, val)
    }
  }

  // emojis property accessors
  @AC.property(LikertClass.emojisProperty)
  get emojis(): Array<string> {
    return this.getValue(LikertClass.emojisProperty) || ''
  }

  set emojis(val: Array<string>) {
    if (this.emojis !== val) {
      this.setValue(LikertClass.emojisProperty, val)
    }
  }

  // strokeColor property accessors
  @AC.property(LikertClass.strokeColorProperty)
  get strokeColor(): string {
    return this.getValue(LikertClass.strokeColorProperty) || ''
  }

  set strokeColor(val: string) {
    if (this.strokeColor !== val) {
      this.setValue(LikertClass.strokeColorProperty, val)
    }
  }

  protected internalRender(): HTMLElement {
    const element = jsxToHtmlElement(this.renderReact())
    return element
  }

  protected renderReact = (): JSX.Element => {
    return this.id ? (
      <Likert
        id={this.id}
        ratingMin={this.ratingMin}
        ratingMax={this.ratingMax}
        emojis={this.emojis}
        strokeColor={this.strokeColor}
      />
    ) : (
      <React.Fragment />
    )
  }
}
