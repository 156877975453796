/* eslint-disable react/jsx-boolean-value */
import React, { useEffect } from 'react'
import RetorikComposer from './RetorikComposer'
import RetorikRouter from './RetorikRouter'
import Container from './Container'
import ContextProvider from '../Contexts/ContextProvider'
import getThemeColors from '../../utils/getThemeColors'
import type { RetorikMainComponentConfiguration } from '../../models/retorikTypes'
import NewsView from '../Views/NewsView'
import SpeechManager from '../Speech/SpeechManager'
import FocusManager from '../Speech/FocusManager'
import MobileLandscapePreventer from '../Utils/MobileLandscapePreventer'
import ErrorCollector from '../Telemetry/ErrorCollector'
import { ContainerParent, Mode } from '../../models/enums'
import SendTypingIndicator from '../Utils/SendTypingIndicator'
import { useRetorikStore } from '../Contexts/retorikStore'
import { pdfjs } from 'react-pdf'
import * as pdfJsWorker from 'pdfjs-dist/build/pdf.worker.js'
import { setThemeColors } from '../Contexts/viewStore'
import { useRetorikUI } from '../CustomAdaptiveCard'

// Configure react-pdf worker
pdfjs.GlobalWorkerOptions.workerSrc = pdfJsWorker
// Overload adaptive card rendering classes
useRetorikUI()

interface RetorikContainerProps extends RetorikMainComponentConfiguration {
  parent: number
}

const RetorikContainer = ({
  config,
  viewsConfig,
  chatbotData,
  chatbotDataWidget,
  agentData,
  ponyfillFactoryCredentials,
  addressData,
  userData,
  skipLoader,
  defaultMode,
  customVoice,
  externalEventHandler,
  externalComponents,
  width,
  height,
  colors,
  parent
}: RetorikContainerProps): JSX.Element => {
  useEffect(() => {
    setThemeColors(getThemeColors(colors))
  }, [])

  const currentLayout: ContainerParent = useRetorikStore(
    (state) => state.currentLayout
  )

  return config &&
    viewsConfig &&
    chatbotData &&
    chatbotDataWidget &&
    agentData &&
    ponyfillFactoryCredentials ? (
    <ContextProvider
      skipLoader={skipLoader}
      mode={defaultMode || Mode.vocal}
      viewsConfiguration={viewsConfig}
      configuration={config}
      addressData={addressData}
      agentSource={agentData}
      ponyfillFactoryCredentials={ponyfillFactoryCredentials}
      customVoice={customVoice}
    >
      {!config.forceMobileView && <MobileLandscapePreventer />}
      <RetorikComposer
        addressData={addressData}
        userData={userData}
        externalEventHandler={externalEventHandler}
        isRetorikNews={parent === ContainerParent.news}
      >
        <ErrorCollector>
          <SpeechManager isRetorikNews={parent === ContainerParent.news} />
          <FocusManager />
          <Container
            fullSize={
              parent !== ContainerParent.widget && config.fullSize !== false
            }
            width={width}
            height={height}
            parent={parent}
          >
            {parent === ContainerParent.news ? (
              <NewsView
                chatbotData={
                  currentLayout === ContainerParent.agent
                    ? chatbotData
                    : chatbotDataWidget
                }
                hideMenu={true}
                isRetorikNews={true}
              />
            ) : (
              <RetorikRouter
                chatbotData={
                  currentLayout === ContainerParent.agent
                    ? chatbotData
                    : chatbotDataWidget
                }
                externalComponents={externalComponents}
              />
            )}
          </Container>
          <SendTypingIndicator
            isRetorikNews={parent === ContainerParent.news}
          />
        </ErrorCollector>
      </RetorikComposer>
    </ContextProvider>
  ) : (
    <React.Fragment />
  )
}

export default RetorikContainer
