/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useMemo, useState } from 'react'
import QRCode from 'qrcode'

import { useLocaleStore } from '../Contexts/localeStore'
import { useViewStore } from '../Contexts/viewStore'
import { sendMessage, sendEvent } from '../Contexts/directLineStore'
import { useAdaptiveCardStore } from '../Contexts/adaptiveCardStore'
import { useRetorikStore } from '../Contexts/retorikStore'
import {
  lockClickedButton,
  setModalFullscreenContent,
  useUtilsStore
} from '../Contexts/utilsStore'

import { AnimationSize, CurrentSubView } from '../../models/enums'
import { getTextToDisplayOnLink } from '../../utils/getTextToDisplayOnLink'
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter'

import { ArrowRightIcon } from '../Icons/ArrowIcons'
import { InfoWithCircleIcon } from '../Icons/Miscellaneous'
import Animation from '../Loader/Animation'
import Animation2 from '../Loader/Animation2'
import QRCodeComponent from '../Utils/QRCodeComponent'
import PrintingPreview from '../Attachments/PrintingPreview'

interface ActionButtonProps {
  text?: string
  fullWidth: boolean
  position: string
  display: string
  urlToOpen?: string
  data?: any
}

const ActionButton = ({
  text,
  fullWidth,
  position,
  display,
  urlToOpen,
  data
}: ActionButtonProps): JSX.Element => {
  const translation = useLocaleStore((state) => state.currentTranslations)
  const currentSubView = useUtilsStore((state) => state.currentSubView)
  const unlockClickedButton = useUtilsStore(
    (state) => state.unlockClickedButton
  )
  const isUsedOnBorne = useRetorikStore(
    (state) => state.configuration.isUsedOnBorne
  )
  const enableDocumentPrinting = useRetorikStore(
    (state) => state.configuration.enableDocumentPrinting
  )
  const isMobile = useViewStore((state) => state.isMobile)
  const locale = useLocaleStore((state) => state.locale)
  const currentValue = useAdaptiveCardStore((state) => state.currentValue)
  const themeColors = useViewStore((state) => state.themeColors)

  const [style, setStyle] = useState<string>()
  const [clicked, setClicked] = useState<boolean>(false)
  const [hover, setHover] = useState<boolean>(false)
  const [qrCodeData, setQrCodeData] = useState<any>()
  const [textToDisplay, setTextToDisplay] = useState<string>()
  const [triedToValidateWithoutValue, setTriedToValidateWithoutValue] =
    useState<boolean>(false)

  const animationColor = {
    '--rf-color-loader-animation': themeColors.card.button.text.default
  } as React.CSSProperties

  const defaultText = useMemo<string>(() => {
    return (
      text || (urlToOpen ? translation.link.goto : translation.common.submit)
    )
  }, [text, locale])

  const setQrCodeDataAsync = async (): Promise<void> => {
    if (urlToOpen) {
      const qrCodeString = await QRCode.toDataURL(encodeURI(urlToOpen))
      setQrCodeData(qrCodeString)
    }
  }

  useEffect(() => {
    setTextToDisplay(capitalizeFirstLetter(defaultText, locale))
  }, [defaultText, translation, locale])

  useEffect(() => {
    if (triedToValidateWithoutValue && currentValue) {
      const values = Object.values(currentValue)
      if (!values.find((value) => Array.isArray(value) && !value.length)) {
        setTextToDisplay(capitalizeFirstLetter(defaultText, locale))
      }
    }
  }, [triedToValidateWithoutValue, currentValue])

  useEffect(() => {
    if (urlToOpen && isUsedOnBorne) {
      setQrCodeDataAsync()
    }
  }, [urlToOpen, isUsedOnBorne])

  useEffect(() => {
    unlockClickedButton && setClicked(false)
  }, [unlockClickedButton])

  useEffect(() => {
    // Set width : full or fit
    let temp = fullWidth ? 'rf-w-full' : 'rf-w-fit'
    // Set position if not in full width
    if (!fullWidth && position) {
      switch (position) {
        case 'right':
          temp += ' rf-float-right'
          break
        case 'center':
          temp += ' rf-mx-auto'
          break
        case 'left':
        default:
          break
      }
    }

    setStyle(temp)
  }, [])

  const handleOpenPreview = (): void => {
    if (currentSubView !== CurrentSubView.history) {
      urlToOpen &&
        setModalFullscreenContent(
          <PrintingPreview
            url={urlToOpen}
            printingAllowed={!!enableDocumentPrinting}
          />
        )
    }
  }

  const handleClick = (): void => {
    if (currentSubView !== CurrentSubView.history && !clicked) {
      if (urlToOpen) {
        window.open(urlToOpen, '_blank', 'noopener noreferrer')
      } else {
        if (data) {
          if (data.type) {
            if (data.type.toLowerCase() === 'message') {
              data.text && sendMessage(data.text)
            } else if (data.type.toLowerCase() === 'event') {
              sendEvent(data.name, data.value)
            }
          } else {
            if (typeof data === 'string') {
              sendMessage(data)
            } else if (typeof data === 'object') {
              sendMessage(undefined, data)
            }
          }
        } else if (currentValue) {
          const values = Object.values(currentValue)
          // Check if a value is an empty array. If so, break the process
          if (values.find((value) => Array.isArray(value) && !value.length)) {
            setTriedToValidateWithoutValue(true)
            setTextToDisplay(
              capitalizeFirstLetter(translation.inputs.noaction, locale)
            )
            return
          } else {
            sendMessage(undefined, currentValue)
          }
        } else {
          setTriedToValidateWithoutValue(true)
          setTextToDisplay(
            capitalizeFirstLetter(translation.inputs.noaction, locale)
          )
          return
        }

        setClicked(true)
        setHover(false)
        lockClickedButton()
      }
    }
  }

  const handleEnter = (): void => {
    if (currentSubView !== CurrentSubView.history && !clicked) {
      setHover(true)
    }
  }

  const handleLeave = (): void => {
    if (currentSubView !== CurrentSubView.history && !clicked) {
      setHover(false)
    }
  }

  return urlToOpen && isUsedOnBorne ? (
    <div className='rf-w-full rf-flex rf-flex-col rf-justify-center rf-items-center rf-text-cardButtonTextDefault'>
      {urlToOpen.length > 4 &&
        urlToOpen.substring(urlToOpen.length - 4) === '.pdf' && (
          <div className='rf-w-full rf-pb-6 rf-flex rf-justify-start rf-text-primary rf-underline rf-italic rf-text-sm'>
            <p className='rf-cursor-pointer' onClick={handleOpenPreview}>
              {translation.link.buttonText}
            </p>
          </div>
        )}
      {qrCodeData ? (
        <React.Fragment>
          <QRCodeComponent src={qrCodeData} />
          <div className='rf-relative rf-text-center rf-mx-8 rf-mt-2 rf-italic'>
            <InfoWithCircleIcon className='rf-absolute rf-top-[0.125rem] -rf-left-6 rf-h-4' />
            {getTextToDisplayOnLink({
              translation,
              isUsedOnBorne,
              data: {
                url: urlToOpen,
                download: true
              }
            })}
          </div>
        </React.Fragment>
      ) : (
        <div className='rf-w-6 rf-h-6'>
          <Animation2
            color='gray'
            loaderColor={themeColors.primary}
            backgroundColor='transparent'
          />
        </div>
      )}
    </div>
  ) : text && display.toLowerCase() === 'link' ? (
    <div
      className={`rf-w-full rf-mt-2 rf-flex rf-justify-start rf-items-center rf-text-cardButtonTextDefault ${
        currentSubView !== CurrentSubView.history && 'rf-cursor-pointer'
      }`}
      onPointerEnter={handleEnter}
      onPointerLeave={handleLeave}
      onClick={handleClick}
    >
      <div
        className={`rf-h-6 rf-w-6 rf-rounded ${
          hover
            ? 'rf-bg-cardButtonBackgroundHover'
            : 'rf-bg-cardButtonBackgroundDefault'
        }`}
      >
        {clicked && !urlToOpen ? (
          <div
            className='rf-h-6 rf-w-6 rf-flex rf-justify-center rf-items-center'
            style={{
              ...animationColor
            }}
          >
            <div className='rf-w-2 rf-h-2'>
              <Animation size={AnimationSize.small} />
            </div>
          </div>
        ) : (
          <ArrowRightIcon
            className={`rf-h-6 rf-p-1 rf-border rf-rounded ${
              hover
                ? 'rf-border-cardButtonBorderHover'
                : 'rf-border-cardButtonBorderDefault'
            }`}
            color={
              hover
                ? themeColors.card.button.text.hover
                : themeColors.card.button.text.default
            }
          />
        )}
      </div>
      <div className='rf-ml-4'>{capitalizeFirstLetter(text, locale)}</div>
    </div>
  ) : (
    <div
      className={`rf-relative ${
        currentSubView !== CurrentSubView.history
          ? clicked
            ? 'rf-card-button-clicked'
            : 'rf-card-button'
          : 'rf-card-button-history'
      } rf-mt-2 ${fullWidth ? 'rf-text-center' : 'rf-text-left'} ${style}`}
      onClick={handleClick}
    >
      {clicked && !urlToOpen ? (
        <div
          className={`rf-w-full rf-h-[1.25rem] large:rf-h-[1.3rem] rf-flex rf-justify-center rf-items-center ${
            fullWidth ? '' : 'rf-pl-6 large:rf-pl-12'
          }`}
          style={{
            ...animationColor
          }}
        >
          <div className='rf-w-4 rf-h-4 large:rf-w-6 large:rf-h-6'>
            <Animation
              size={isMobile ? AnimationSize.medium : AnimationSize.large}
            />
          </div>
        </div>
      ) : (
        textToDisplay
      )}
    </div>
  )
}

export default ActionButton
