import React, { useEffect, useState } from 'react'
import { useRetorikStore } from '../Contexts/retorikStore'
import { useSpeechStore } from '../Contexts/speechStore'
import { useUtilsStore, setListClosed } from '../Contexts/utilsStore'
import { useViewStore } from '../Contexts/viewStore'

import ListTemplate from '../Templates/List'
import Attachments from '../Attachments/Attachments'

const BotResponse = (): JSX.Element => {
  const showHomeAttachments = useViewStore((state) => state.showHomeAttachments)
  const speaking = useSpeechStore((state) => state.speaking)
  const lastRecognitionInterim = useSpeechStore(
    (state) => state.lastRecognitionInterim
  )
  const currentOrLastPlayedActivity = useSpeechStore(
    (state) => state.currentOrLastPlayedActivity
  )
  const displaySubtitles = useRetorikStore((state) => state.displaySubtitles)
  const listClosed = useUtilsStore((state) => state.listClosed)
  const [list, setList] = useState<boolean>()
  const [rowSpanVertical, setRowSpanVertical] = useState<string>(
    'rf-row-span-6 small-mobile:rf-row-span-7'
  )

  useEffect(() => {
    setRowSpanVertical(
      displaySubtitles && speaking
        ? 'rf-row-span-5 small-mobile:rf-row-span-6'
        : 'rf-row-span-6 small-mobile:rf-row-span-7'
    )
  }, [displaySubtitles, speaking])

  useEffect(() => {
    if (currentOrLastPlayedActivity) {
      if (
        currentOrLastPlayedActivity.attachmentLayout?.toLowerCase() ===
          'davilist' ||
        (currentOrLastPlayedActivity.attachmentLayout !== 'carousel' &&
          currentOrLastPlayedActivity.attachments &&
          currentOrLastPlayedActivity.attachments.length > 1)
      ) {
        setList(true)
      } else {
        setList(false)
      }
    }
  }, [currentOrLastPlayedActivity])

  return showHomeAttachments &&
    (showHomeAttachments as any).attachments?.length > 0 ? (
    <div
      id='retorik-botResponse'
      className={`rf-relative rf-w-full rf-h-full
        rf-col-start-1 large-vertical:rf-col-start-1 large:rf-col-start-5 rf-col-span-full
        rf-row-start-6 small-mobile:rf-row-start-5 large:rf-row-start-5 large-vertical:rf-row-start-7 ${rowSpanVertical} large:rf-row-span-full large-vertical:rf-row-span-4
        rf-overflow-y-scroll rf-scrollbar-thin
      `}
    >
      <div className='rf-w-full rf-h-full rf-flex rf-justify-center'>
        <Attachments activity={showHomeAttachments} />
      </div>
    </div>
  ) : currentOrLastPlayedActivity ? (
    list ? (
      lastRecognitionInterim === '' && !listClosed ? (
        <ListTemplate
          activity={currentOrLastPlayedActivity}
          onClose={(): void => setListClosed(true)}
        />
      ) : (
        <React.Fragment />
      )
    ) : (
      <div
        id='retorik-botResponse'
        className={`rf-relative rf-w-full rf-h-full
            rf-col-start-1 large-vertical:rf-col-start-1 large:rf-col-start-5 rf-col-span-full
            rf-row-start-6 small-mobile:rf-row-start-5 large:rf-row-start-5 large-vertical:rf-row-start-7 ${rowSpanVertical} large:rf-row-span-full large-vertical:rf-row-span-4
            rf-overflow-y-scroll rf-scrollbar-thin
          `}
      >
        <div className='rf-w-full rf-h-full rf-flex rf-justify-center'>
          {!!currentOrLastPlayedActivity?.attachments?.length && (
            <Attachments activity={currentOrLastPlayedActivity} />
          )}
        </div>
      </div>
    )
  ) : (
    <React.Fragment />
  )
}

export default BotResponse
