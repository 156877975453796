import { useState, useEffect } from 'react'
import { useRetorikStore } from '../components/Contexts/retorikStore'
import { useViewStore } from '../components/Contexts/viewStore'
import { DeviceType } from '../models/enums'

export default function useCurrentPagination(): number {
  const configuration = useRetorikStore((state) => state.configuration)
  const currentDeviceType = useViewStore((state) => state.currentDeviceType)

  const [currentPagination, setCurrentPagination] = useState<number>(10)

  useEffect(() => {
    if (configuration && currentDeviceType) {
      switch (currentDeviceType) {
        case DeviceType.borne:
          setCurrentPagination(configuration.pagination?.borne || 15)
          break
        case DeviceType.landscape:
          setCurrentPagination(configuration.pagination?.landscape || 20)
          break
        case DeviceType.mobile:
        case DeviceType.widget:
          setCurrentPagination(configuration.pagination?.mobile || 12)
          break
        default:
          break
      }
    } else {
      setCurrentPagination(10)
    }
  }, [configuration, currentDeviceType])

  return currentPagination
}
