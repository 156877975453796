import { defaultThemeDark, defaultThemeLight } from '../models/colors'
import type { RetorikThemeColors, ThemeColors } from '../models/colorTypes'

const deepMerge = (target, source): any => {
  const isObject = (obj): boolean => obj && typeof obj === 'object'

  if (!isObject(target) || !isObject(source)) {
    return source
  }

  Object.keys(source).forEach((key) => {
    const targetValue = target[key]
    const sourceValue = source[key]

    if (targetValue || targetValue === false) {
      if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
        source[key] = targetValue.concat(sourceValue)
      } else if (isObject(targetValue) && isObject(sourceValue)) {
        source[key] = deepMerge(targetValue, sourceValue)
      } else {
        source[key] = targetValue
      }
    }
  })

  return source
}

const getThemeColors = (colors?: RetorikThemeColors): ThemeColors => {
  let defaultColors: ThemeColors =
    colors?.theme === 'light' ? defaultThemeLight : defaultThemeDark
  defaultColors = deepMerge(colors, defaultColors)

  return defaultColors
}

export default getThemeColors
export { deepMerge }
