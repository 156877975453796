/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useSpring, easings } from '@react-spring/web'

import { setRetorikEvent } from '../Contexts/utilsStore'
import { useRetorikStore } from '../Contexts/retorikStore'
import { useLocaleStore } from '../Contexts/localeStore'
import { useViewStore } from '../Contexts/viewStore'
import { sendMessage, sendEvent } from '../Contexts/directLineStore'

import useShowPOI from '../../hooks/useShowPOI'

import type { POIContentType } from '../../models/attachmentTypes'
import { getColorFromCategoryId } from '../../utils/getColorFromCategoryId'
import { Mode, RetorikEvent } from '../../models/enums'
import preventEvents from '../../utils/preventEvents'

import Template from '../Templates/FullScreenRemoteClosing'
import DetailedTitle from './DetailedAttachment/DetailedTitle'
import DetailedText from './DetailedAttachment/DetailedText'
import DetailedMedia from './DetailedAttachment/DetailedMedia'
import DetailedAddress from './DetailedAttachment/DetailedAddress'
import DownloadSheet from './DetailedAttachment/DownloadSheet'

const springEnterDuration = 1500
const springLeaveDuration = 2500

const AttachmentDetails = (): JSX.Element => {
  const detailedItemContent = useShowPOI()
  const locale = useLocaleStore((state) => state.locale)
  const isUsedOnBorne = useRetorikStore(
    (state) => state.configuration.isUsedOnBorne
  )
  const baseAddress = useRetorikStore((state) => state.baseAddress)
  const mode = useRetorikStore((state) => state.mode)
  const isMobile = useViewStore((state) => state.isMobile)
  const isWidget = useViewStore((state) => state.isWidget)
  const currentDeviceType = useViewStore((state) => state.currentDeviceType)
  const themeColors = useViewStore((state) => state.themeColors)

  const [content, setContent] = useState<POIContentType | undefined>(undefined)
  const [color, setColor] = useState<string>(themeColors.secondary)
  const [titleHeight, setTitleHeight] = useState<number>(0)
  const [spring, api] = useSpring(() => ({
    from: {
      transform: 'translateX(0%)'
    }
  }))
  const timerRef = useRef<NodeJS.Timer | null>(null)
  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setContent(detailedItemContent)
    if (detailedItemContent) {
      setRetorikEvent(RetorikEvent.DetailViewOpen)
    }
  }, [detailedItemContent])

  useLayoutEffect(() => {
    if (divRef?.current) {
      isWidget && preventEvents(null, divRef)
    }
  })

  useEffect(() => {
    if (content) {
      content.category?.id &&
        setColor(getColorFromCategoryId(content.category.id, themeColors))
      api.start({
        from: {
          transform: 'translateX(-100%)'
        },
        to: {
          transform: 'translateX(0%)'
        },
        config: {
          duration: springEnterDuration,
          easing: easings.easeInBack
        }
      })
    }
  }, [content])

  const handleClose = (): void => {
    api.start({
      from: {
        transform: 'translateX(0%)'
      },
      to: {
        transform: 'translateX(-100%)'
      },
      config: {
        duration: springLeaveDuration,
        easing: easings.easeOutExpo
      }
    })

    if (timerRef) {
      timerRef.current && clearTimeout(timerRef.current)
      timerRef.current = setTimeout(() => {
        sendEvent('Davi.CloseWindow', null)
        sendEvent('Davi.CloseWindowDetailed', null)
        setContent(undefined)
        setRetorikEvent(RetorikEvent.DetailViewClose)
      }, springLeaveDuration)
    }
  }

  const sendPostBack = (value: any): void => {
    sendMessage(undefined, value)
  }

  return content ? (
    <Template
      handleClose={handleClose}
      className='rf-z-detailedPOI'
      closeButtonClassName='rf-z-util'
      remoteCloseButtonZIndex='rf-z-detailedPOI'
      animation={spring}
    >
      {/* Window containing data */}
      <div
        ref={divRef}
        className='rf-relative rf-flex rf-flex-row vertical:rf-flex-col rf-h-full rf-overflow-y-scroll rf-scrollbar-hidden'
      >
        {detailedItemContent?.template ? (
          <React.Fragment>
            <detailedItemContent.template
              props={{
                ...content,
                locale: locale,
                isUsedOnBorne: isUsedOnBorne,
                baseAddress: baseAddress,
                sendPostBack: sendPostBack
              }}
              currentDeviceType={currentDeviceType}
              handleClose={handleClose}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className='rf-px-6 large:rf-px-8 rf-w-2/3 vertical:rf-w-full rf-mb-4 large:rf-mb-0 rf-flex rf-flex-col rf-border-r rf-border-r-silver vertical:rf-border-r-0'>
              {/* Title + category */}
              <DetailedTitle
                category={content.category}
                title={content.title}
                infoBanner={content.infoBanner}
                color={color}
                customerReviews={content.customerReviews}
                favorite={content.favorite}
                handleClose={handleClose}
                setTitleHeight={setTitleHeight}
              />
              <div className='rf-flex rf-flex-col large:rf-flex-row desktop:rf-h-full'>
                {/* Text + other data */}
                <DetailedText
                  text={content.text}
                  otherInformations={content.otherInformations}
                  color={color}
                  url={content.downloadUri}
                  titleHeight={titleHeight}
                />
                {/* Media + comment if there is at least one media or comment */}
                {((content.media && content.media.length > 0) ||
                  content.review) && (
                  <DetailedMedia
                    media={content.media}
                    review={content.review}
                    color={color}
                  />
                )}
              </div>
            </div>
            <DetailedAddress
              address={content.addressStructured}
              phoneNumber={content.phoneNumber}
              coordinates={content.coordinates}
              color={color}
              customerReviews={content.customerReviews}
              url={content.downloadUri}
            />
            {isMobile && (
              <div className={mode === Mode.text ? 'rf-mb-28' : 'rf-mb-16'}>
                {content.downloadUri && (
                  <DownloadSheet
                    className='rf-justify-center'
                    url={content.downloadUri}
                  />
                )}
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </Template>
  ) : (
    <React.Fragment />
  )
}

export default AttachmentDetails
