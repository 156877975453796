import React from 'react'
import { jsxToHtmlElement } from '../../../utils/jsxToHtmlElement'
import { DateInput } from 'adaptivecards/lib/card-elements'
import InputDate from '../InputDate'
import InputCalendar from '../InputCalendar'
import * as AC from 'adaptivecards'

export class DateInputClass extends DateInput {
  // Add custom property to schema
  static readonly calendarProperty = new AC.BoolProperty(
    AC.Versions.v1_0,
    'calendar',
    false
  )

  // Calendar property accessors
  @AC.property(DateInputClass.calendarProperty)
  get calendar(): boolean {
    return !!this.getValue(DateInputClass.calendarProperty)
  }

  protected internalRender(): HTMLElement {
    const element = jsxToHtmlElement(this.renderReact())
    return element
  }

  protected renderReact = (): JSX.Element => {
    return this.id ? (
      this.calendar ? (
        <InputCalendar
          id={this.id}
          defaultValue={this.defaultValue}
          maxValue={this.max}
          minValue={this.min}
        />
      ) : (
        <InputDate
          id={this.id}
          defaultValue={this.defaultValue}
          maxValue={this.max}
          minValue={this.min}
        />
      )
    ) : (
      <React.Fragment />
    )
  }
}
