import { RetorikActivity } from '../../models/activityTypes'

const activityMiddleware = (activity: RetorikActivity): RetorikActivity => {
  if (
    activity?.type === 'message' &&
    activity.attachments?.length &&
    activity.attachments[0].contentType ===
      'application/vnd.davi.retorik.card.suggestedaction' &&
    activity.attachments[0].content?.title?.indexOf(activity.text) > -1
  ) {
    // Hack for Suggested Action : Only show text in card, not in message
    activity.text = ''
    activity.htmlText = ''
  }

  return activity
}

export default activityMiddleware
