import React, { useEffect, useState } from 'react'
import { useUtilsStore } from '../../Contexts/utilsStore'
import {
  useAdaptiveCardStore,
  setValue,
  setCurrentId
} from '../../Contexts/adaptiveCardStore'
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter'
import { CurrentSubView } from '../../../models/enums'

interface RadioAsButtonProps {
  id: string
  choices: any
}

const RadioAsButton = ({ id, choices }: RadioAsButtonProps): JSX.Element => {
  const currentSubView = useUtilsStore((state) => state.currentSubView)
  const values = useAdaptiveCardStore((state) => state.values)
  const [display, setDisplay] = useState<Array<any>>([])
  const [chosen, setChosen] = useState<string>((values.get(id) as string) || '')

  useEffect(() => {
    currentSubView !== CurrentSubView.history && setCurrentId(id)
  }, [])

  /**
   * On choices prop change :
   *  - if choices is defined, fill display state with choices value
   */
  useEffect(() => {
    choices && setDisplay(choices)
  }, [choices])

  /**
   * On call:
   *  - check if the component will be rendered in history, if so bypass every interaction
   *  - fill chosen state with value
   *  - call parent's handleSelect method
   * @param value : string
   */
  const handleChange = (value: string): void => {
    if (currentSubView !== CurrentSubView.history) {
      setChosen(value)
      setValue(id, value)
    }
  }

  return display && display.length > 0 ? (
    <div className='rf-w-full rf-mt-4 rf-flex rf-flex-row rf-flex-wrap rf-justify-center rf-items-center rf-text-size-auto rf-text-white'>
      {display.map((choice, key) => {
        return (
          <label
            key={key}
            className={`rf-mx-2 rf-flex rf-justify-center rf-items-center rf-px-4 rf-py-2 large:rf-px-5 large:rf-py-3 rf-border rf-rounded-lg ${
              choice.value === chosen
                ? 'rf-bg-formInputButtonBackgroundHover rf-text-formInputButtonTextHover rf-border-formInputButtonBorderHover'
                : 'rf-bg-formInputButtonBackgroundDefault rf-text-formInputButtonTextDefault rf-border-formInputButtonBorderDefault'
            } rf-mb-4 hover:rf-cursor-pointer`}
          >
            <input
              type='radio'
              value={choice.value}
              checked={choice.value === chosen}
              className='rf-input-hidden'
              onChange={(): void => handleChange(choice.value)}
            />
            {capitalizeFirstLetter(choice.title)}
          </label>
        )
      })}
    </div>
  ) : (
    <React.Fragment />
  )
}

export default RadioAsButton
