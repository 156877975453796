import { useEffect, useState } from 'react'
import {
  setDisplaySubtitles,
  setUserAlreadySet,
  useRetorikStore
} from '../Contexts/retorikStore'
import {
  useUtilsStore,
  sendActivity as sendUtilActivity
} from '../Contexts/utilsStore'
import { useLocaleStore } from '../Contexts/localeStore'
import { setCookieInBrowser } from '../Contexts/activityStore'
import { sendMessage, sendEvent } from '../Contexts/directLineStore'

import useToggleMicrophone from '../../hooks/useToggleMicrophone'

import { UserData } from '../../models/types'

interface SendActivityEventListenerProps {
  isRetorikNews: boolean
  hasConversationCookie: boolean
  userData: UserData
}

interface EventReceived {
  type: EventsReceived
  detail?: any
}

enum EventsReceived {
  'retorikSendActivity' = 1,
  'retorikSendEvent',
  'retorikStartListening',
  'retorikDisplaySubtitles'
}

const SendActivityEventListener = ({
  isRetorikNews,
  hasConversationCookie,
  userData
}: SendActivityEventListenerProps): null => {
  const locale = useLocaleStore((state) => state.locale)
  const activityToSend = useUtilsStore((state) => state.activityToSend)
  const displayGDPR = useUtilsStore((state) => state.GDPRDisplay)
  const userConsent = useUtilsStore((state) => state.GDPRUserConsent)
  const loaderClosed = useRetorikStore((state) => state.loaderClosed)
  const appAvailable = useRetorikStore((state) => state.appAvailable)
  const skipWelcome = useRetorikStore(
    (state) => state.configuration.skipWelcome
  )
  const userAlreadySet = useRetorikStore((state) => state.userAlreadySet)

  const toggleMicrophone = useToggleMicrophone()

  const [eventReceived, setEventReceived] = useState<EventReceived | null>(null)

  useEffect(() => {
    if (eventReceived) {
      try {
        switch (eventReceived.type) {
          case EventsReceived.retorikSendActivity:
            eventReceived.detail && sendMessage(undefined, eventReceived.detail)
            break
          case EventsReceived.retorikDisplaySubtitles:
            eventReceived.detail && setDisplaySubtitles(!!eventReceived.detail)
            break
          case EventsReceived.retorikStartListening:
            toggleMicrophone()
            break
          case EventsReceived.retorikSendEvent:
            eventReceived.detail &&
              sendEvent(eventReceived.detail.name, eventReceived.detail.value)
        }
      } catch (e) {
        console.warn('Retorik Framework > Error on event reception')
      }
    }
  }, [eventReceived])

  const handleEvents = (event: CustomEvent): void => {
    setEventReceived({
      type: EventsReceived[event.type],
      detail: event.detail
    })
  }

  useEffect(() => {
    document.addEventListener('retorikSendActivity', handleEvents)
    document.addEventListener('retorikSendEvent', handleEvents)
    document.addEventListener('retorikStartListening', handleEvents)
    document.addEventListener('retorikDisplaySubtitles', handleEvents)

    return (): void => {
      document.removeEventListener('retorikSendActivity', handleEvents)
      document.removeEventListener('retorikStartListening', handleEvents)
      document.removeEventListener('retorikDisplaySubtitles', handleEvents)
    }
  }, [])

  useEffect(() => {
    if (isRetorikNews || hasConversationCookie) {
      setUserAlreadySet(true)
    } else if (!userAlreadySet && appAvailable && !displayGDPR) {
      setUserAlreadySet(true)

      // Send mandatory events : setUser to start conversation, Davi.GDPRConsent for GDPR module
      sendEvent('setUser', {
        user: { ...userData, gdprConsent: true },
        skipWelcome: !!skipWelcome
      })
      setTimeout(() => {
        sendEvent('Davi.GDPRConsent', {
          userConsent: userConsent
        })
      }, 100)
      // Set cookie in browser data now
      setCookieInBrowser()
    }
  }, [appAvailable, loaderClosed, displayGDPR])

  useEffect(() => {
    if (activityToSend) {
      if (
        activityToSend.type === 'message' &&
        activityToSend.text &&
        typeof activityToSend.text !== 'string'
      ) {
        const tempActivity = { ...activityToSend }
        tempActivity.text = activityToSend.text[locale] || ''
        sendMessage(tempActivity.text)
      } else {
        sendMessage(undefined, activityToSend.value || activityToSend)
      }

      sendUtilActivity(undefined)
    }
  }, [activityToSend])

  return null
}

export default SendActivityEventListener
