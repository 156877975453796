import React, { useEffect, useMemo } from 'react'
import { useViewStore } from '../Contexts/viewStore'
import { AnimationSize } from '../../models/enums'
import Animation from './Animation'

interface AnimationContainerProps {
  classname?: string
  subClassname?: string
  size?: AnimationSize
  useTextColor?: boolean
  triggerScroll?: () => void
}

const AnimationContainer = ({
  classname,
  subClassname,
  size,
  useTextColor,
  triggerScroll
}: AnimationContainerProps): JSX.Element => {
  const themeColors = useViewStore((state) => state.themeColors)
  const isMobile = useViewStore((state) => state.isMobile)

  const processedClassName = useMemo<string>(() => {
    return (
      classname ||
      'rf-w-full rf-h-full rf-flex rf-justify-center rf-items-center'
    )
  }, [classname])

  const processedSubClassName = useMemo<string>(() => {
    return subClassname || 'rf-w-4 rf-h-4 large:rf-w-6 large:rf-h-6'
  }, [subClassname])

  const processedAnimationColor = useMemo<React.CSSProperties>(() => {
    return {
      '--rf-color-loader-animation': useTextColor
        ? themeColors.textMode.panel.conversationBot
        : themeColors.card.frame.text
    } as React.CSSProperties
  }, [themeColors, useTextColor])

  useEffect(() => {
    triggerScroll?.()
  }, [])

  return (
    <div
      className={processedClassName}
      style={{
        ...processedAnimationColor
      }}
    >
      <div className={processedSubClassName}>
        <Animation
          size={size || (isMobile ? AnimationSize.medium : AnimationSize.large)}
        />
      </div>
    </div>
  )
}

export default AnimationContainer
