/* eslint-disable react/jsx-boolean-value */
import React from 'react'
import {
  useRetorikStore,
  setDisplaySubtitles
} from '../../Contexts/retorikStore'
import { useViewStore } from '../../Contexts/viewStore'
import { useLocaleStore } from '../../Contexts/localeStore'
import { useSpeechStore, setMuted } from '../../Contexts/speechStore'

import { Mode } from '../../../models/enums'

import { HistoryButton, LanguageButton, TutorialButton } from '.'
import { Toggle } from '../../Utils'
import DisplaySubViews from '../ViewsDisplay/DisplaySubViews'

interface ParametersProps {
  handleBack?: () => void
}

const Parameters = ({ handleBack }: ParametersProps): JSX.Element => {
  const translation = useLocaleStore((state) => state.currentTranslations)
  const mode = useRetorikStore((state) => state.mode)
  const displaySubtitles = useRetorikStore((state) => state.displaySubtitles)
  const disableSound = useRetorikStore((state) => state.disableSound)
  const muted = useSpeechStore((state) => state.muted)
  const isMobile = useViewStore((state) => state.isMobile)

  const handleSoundChange = (val: boolean) => {
    setMuted(!val)
  }

  return (
    <React.Fragment>
      {/* Sound */}
      {!disableSound && (
        <Toggle
          className='rf-py-3 large:rf-py-0'
          title={translation.common.sound}
          handleChange={handleSoundChange}
          defaultChecked={!muted}
        />
      )}

      {/* Separating line only in mobile / widget modes */}
      {isMobile && (
        <div className='rf-h-0 rf-w-full rf-border-t rf-border-t-menuBorder' />
      )}

      {/* Subtitle */}
      {mode !== Mode.text && (
        <Toggle
          className='rf-py-3 large:rf-py-0'
          title={translation.common.subtitle}
          handleChange={setDisplaySubtitles}
          defaultChecked={displaySubtitles}
        />
      )}

      {/* Languages / Tutorial / History */}
      {isMobile && (
        <React.Fragment>
          <LanguageButton />
          <TutorialButton handleBack={handleBack} />
          <HistoryButton handleBack={handleBack} />
          <DisplaySubViews handleClick={handleBack} />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default Parameters
