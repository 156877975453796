import { create } from 'zustand'
import { setUserTyping, setLastListActivity } from './utilsStore'
import { setMuted } from './speechStore'
import type { AddressData, Configuration, AgentData } from '../../models/types'
import { ContainerParent, Mode } from '../../models/enums'
import fetchAgentData from '../../utils/fetchAgentData'

interface RetorikStore {
  configuration: Configuration
  addressData: AddressData
  sendBoxRef: HTMLTextAreaElement | null
  canFocusSendBox: boolean
  mode: Mode
  baseAddress: string
  subtitlesInConfiguration: boolean
  displaySubtitles: boolean
  agentData: AgentData | undefined
  loaderClosed: boolean
  appAvailable: boolean
  currentLayout: ContainerParent
  userAlreadySet: boolean
  disableSound: boolean
  isWaitingForResponse: boolean
  skipLoader: boolean
}

const defaultConfiguration: Configuration = {
  subtitles: false
}

const initialState: RetorikStore = {
  configuration: defaultConfiguration,
  addressData: {},
  canFocusSendBox: false,
  sendBoxRef: null,
  mode: Mode.text,
  baseAddress: '',
  subtitlesInConfiguration: false,
  displaySubtitles: false,
  agentData: undefined,
  loaderClosed: false,
  appAvailable: false,
  currentLayout: ContainerParent.agent,
  userAlreadySet: false,
  disableSound: false,
  isWaitingForResponse: false,
  skipLoader: false
}

let timer: NodeJS.Timeout | null = null

export const useRetorikStore = create<RetorikStore>()(() => {
  return initialState
})

export const setConfiguration = (value: Configuration) => {
  console.log('Config change : ', value)
  useRetorikStore.setState({ configuration: value })
}

export const setAddressData = (value: AddressData): void => {
  useRetorikStore.setState({ addressData: value })
}

export const setSendBoxRef = (value: HTMLTextAreaElement | null) => {
  useRetorikStore.setState({ sendBoxRef: value })
}
export const focusSendBox = () => {
  const sendBox = useRetorikStore.getState().sendBoxRef
  sendBox && document.activeElement !== sendBox && sendBox.focus()
}

export const setCanFocusSendBox = (value: boolean) => {
  useRetorikStore.setState({ canFocusSendBox: value })
}

export const setDisableSound = (value: boolean): void => {
  useRetorikStore.setState({ disableSound: value })
}

export const setLayout = (value: ContainerParent): void => {
  useRetorikStore.setState({ currentLayout: value })
}

export const setUserAlreadySet = (value: boolean): void => {
  useRetorikStore.setState({ userAlreadySet: value })
}

export const setMode = (value: Mode): void => {
  useRetorikStore.setState({ mode: value })
  !useRetorikStore.getState().subtitlesInConfiguration &&
    useRetorikStore.setState({ displaySubtitles: false })
  setLastListActivity(undefined)
  value !== Mode.text && setUserTyping(false)
  !useRetorikStore.getState().disableSound && setMuted(value === Mode.text)
}

export const setBaseAddress = (value: string): void => {
  useRetorikStore.setState({ baseAddress: value })
}

export const setLoaderClosed = (value: boolean): void => {
  useRetorikStore.setState({ loaderClosed: value })
}

export const setAppAvailable = (value: boolean): void => {
  useRetorikStore.setState({ appAvailable: value })
}

export const setSubtitlesInConfiguration = (value: boolean): void => {
  useRetorikStore.setState({ subtitlesInConfiguration: value })
  useRetorikStore.setState({
    displaySubtitles: value && useRetorikStore.getState().mode === Mode.vocal
  })
}

export const setDisplaySubtitles = (value: boolean): void => {
  useRetorikStore.setState({ displaySubtitles: value })
}

export const setAgentData = async (
  value: string | { url: string; name?: string }
): Promise<void> => {
  const url = typeof value === 'string' ? value : value.url
  const data = await fetchAgentData(url)
  if (data) {
    typeof value !== 'string' && value.name && (data.name = value.name)
  }

  useRetorikStore.setState({ agentData: data })
}

export const setIsWaitingForResponse = (value: boolean): void => {
  if (value) {
    !timer &&
      (timer = setTimeout(() => {
        useRetorikStore.setState({ isWaitingForResponse: value })
        timer = null
      }, 1000))
  } else {
    timer && clearTimeout(timer)
    timer = null
    useRetorikStore.setState({ isWaitingForResponse: value })
  }
}

export const setSkipLoader = (value: boolean): void => {
  useRetorikStore.setState({ skipLoader: value })
}

export const resetRetorikStore = (): void => {
  useRetorikStore.setState({ ...initialState })
}
