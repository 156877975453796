/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useRef, useState } from 'react'
import { animated, useSpring } from '@react-spring/web'

import { useSpeechStore } from '../Contexts/speechStore'
import { useViewStore } from '../Contexts/viewStore'
import { useRetorikStore } from '../Contexts/retorikStore'

import { AnimationSize } from '../../models/enums'

import AnimationContainer from '../Loader/AnimationContainer'

const springDuration = 1050

interface VoiceInputProps {
  className?: string
}

const VoiceInput = ({ className }: VoiceInputProps): JSX.Element => {
  const isMobile = useViewStore((state) => state.isMobile)
  const lastRecognitionInterim = useSpeechStore(
    (state) => state.lastRecognitionInterim
  )
  const isWaitingForResponse = useRetorikStore(
    (state) => state.isWaitingForResponse
  )
  const [remainingLastRecognitionInterim, setRemainingLastRecognitionInterim] =
    useState<string | null>(null)
  const animationTimerRef = useRef<NodeJS.Timeout>()

  const [spring, api] = useSpring(() => ({
    from: {
      opacity: 1
    }
  }))

  useEffect(() => {
    return () => {
      animationTimerRef?.current && clearTimeout(animationTimerRef.current)
    }
  }, [])

  useEffect(() => {
    if (lastRecognitionInterim) {
      setRemainingLastRecognitionInterim(lastRecognitionInterim)
    } else if (remainingLastRecognitionInterim) {
      api.start({
        from: {
          opacity: 1
        },
        to: {
          opacity: 0
        },
        config: {
          duration: springDuration
        }
      })

      animationTimerRef?.current && clearTimeout(animationTimerRef.current)
      animationTimerRef.current = setTimeout(() => {
        api.set({ opacity: 1 })
        setRemainingLastRecognitionInterim(null)
      }, springDuration)
    }
  }, [lastRecognitionInterim])

  return (
    <React.Fragment>
      {isWaitingForResponse ? (
        <AnimationContainer
          classname={`rf-flex ${
            isMobile ? 'rf-justify-center' : 'rf-pl-8 rf-justify-start'
          } rf-py-3 rf-items-center`}
          subClassname='rf-w-4 rf-h-4'
          size={AnimationSize.medium}
          useTextColor={true}
        />
      ) : (
        remainingLastRecognitionInterim &&
        (isMobile ? (
          <animated.p className={className} style={{ ...spring }}>
            {remainingLastRecognitionInterim}
          </animated.p>
        ) : (
          <animated.div
            className='rf-w-full rf-px-4 rf-py-3 rf-subtitle-size-auto rf-font-bold rf-text-truewhite rf-text-shadow-black'
            style={{ ...spring }}
          >
            {remainingLastRecognitionInterim}
          </animated.div>
        ))
      )}
    </React.Fragment>
  )
}

export default VoiceInput
