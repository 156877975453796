import React, { useEffect, useState, useRef } from 'react'
import { useSpring, animated } from '@react-spring/web'
import { MapContainer, TileLayer, Marker, GeoJSON } from 'react-leaflet'
import type { FeatureCollection } from 'geojson'
import type { GeoJSON as GeoJSONType } from 'leaflet'
import L from 'leaflet'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'

import { useLocaleStore } from '../../Contexts/localeStore'
import { useRetorikStore } from '../../Contexts/retorikStore'

import { imageFiles } from '../../../utils/imageFiles'
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter'

import Routes from './Routes'
import MapUser from './MapUser'
import QRCodeComponent from '../../Utils/QRCodeComponent'
import CloseButton from '../../Common/CloseButton'
import { RoundIcon } from '../../Icons/DetailedPOIIcons'

interface MapProps {
  latitude: number
  longitude: number
  color?: string
}

const userIcon = L.icon({
  iconUrl: `${imageFiles.mapDeparture}`,
  shadowUrl: iconShadow,
  iconSize: [32, 41],
  iconAnchor: [16, 41]
})

const springDuration = 500

const Map = ({ latitude, longitude, color }: MapProps): JSX.Element => {
  const translation = useLocaleStore((state) => state.currentTranslations)
  const locale = useLocaleStore((state) => state.locale)
  const configuration = useRetorikStore((state) => state.configuration)

  const [mapLayerData, setMapLayerData] = useState<
    FeatureCollection | undefined
  >(undefined)
  const geoJsonLayer = useRef<GeoJSONType | null>(null)
  const coordinates =
    configuration.position?.latitude && configuration.position?.longitude
      ? [
          [configuration.position.longitude, configuration.position.latitude],
          [longitude, latitude]
        ]
      : undefined
  const [qrCodeUrl, setQrCodeUrl] = useState<string | null>()

  const fadeInTimerRef = useRef<NodeJS.Timer | null>(null)
  const [spring, api] = useSpring(() => ({
    from: {
      opacity: 0
    }
  }))

  useEffect(() => {
    if (geoJsonLayer.current) {
      mapLayerData && geoJsonLayer.current.clearLayers().addData(mapLayerData)
    }
  }, [mapLayerData])

  useEffect(() => {
    qrCodeUrl &&
      api.start({
        from: {
          opacity: 0
        },
        to: {
          opacity: 1
        },
        config: {
          duration: springDuration
        }
      })

    return () => {
      fadeInTimerRef?.current && clearTimeout(fadeInTimerRef.current)
    }
  }, [qrCodeUrl])

  const handleCloseQrCode = (): void => {
    api.start({
      from: {
        opacity: 1
      },
      to: {
        opacity: 0
      },
      config: {
        duration: springDuration
      }
    })

    fadeInTimerRef?.current && clearTimeout(fadeInTimerRef.current)
    fadeInTimerRef.current = setTimeout(() => {
      setQrCodeUrl(null)
    }, springDuration)
  }

  return (
    <React.Fragment>
      <MapContainer
        center={[latitude, longitude]}
        zoom={12}
        scrollWheelZoom={false}
        style={{ height: '100%', width: '100%', zIndex: 0 }}
        attributionControl={false}
      >
        <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
        <Marker position={[latitude, longitude]} />
        {configuration.position?.latitude &&
          configuration.position?.longitude && (
            <Marker
              position={[
                configuration.position.latitude,
                configuration.position.longitude
              ]}
              icon={userIcon}
            />
          )}
        {mapLayerData && <GeoJSON ref={geoJsonLayer} data={mapLayerData} />}
        <MapUser
          configurationPosition={[
            configuration.position?.latitude || latitude,
            configuration.position?.longitude || longitude
          ]}
          coordinates={[latitude, longitude]}
          data={mapLayerData}
        />
      </MapContainer>

      {/* Available routes if there are origin and destination coordinates */}
      {coordinates && (
        <Routes
          coordinates={coordinates}
          setMapLayerData={setMapLayerData}
          color={color}
          setQrCodeUrl={setQrCodeUrl}
        />
      )}

      {/* QRcode covering the map in borne mode */}
      {qrCodeUrl && (
        <animated.div
          className='rf-absolute rf-h-full rf-w-full rf-top-0 rf-left-0 rf-z-ui rf-flex rf-flex-col rf-justify-center rf-items-center rf-gap-4 rf-text-truewhite'
          style={{
            background: 'rgba(18,18,18,0.7)',
            ...spring
          }}
        >
          <CloseButton onClick={handleCloseQrCode} showLabel={false} />
          <div className='rf-flex rf-flex-row rf-items-start rf-gap-1 rf-text-truewhite rf-italic'>
            <RoundIcon className='rf-h-4 rf-mt-[0.2rem]' color='#FFF' />
            <div className='rf-w-fit rf-flex rf-flex-col rf-items-center'>
              <div>
                {capitalizeFirstLetter(translation.poi.openroutemobile, locale)}
              </div>
              <div>{translation.link.scanQR}</div>
            </div>
          </div>
          <QRCodeComponent src={qrCodeUrl} />
        </animated.div>
      )}
    </React.Fragment>
  )
}

export default Map
