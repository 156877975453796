import React, { useEffect, useMemo, useRef, useState } from 'react'
import { animated, useSpring } from '@react-spring/web'

import { useLocaleStore } from '../..'
import { useViewStore } from '../Contexts/viewStore'
import {
  setGDPRDisplay,
  setGDPRUserConsent,
  useUtilsStore
} from '../Contexts/utilsStore'

import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter'

interface Texts {
  title: string
  message: string
  link: string
  buttonAccept: string
  buttonRefuse: string
  messageAccepted: string
  messageRefused: string
}

const springDuration = 500
const finalMessageDisplayDuration = 2500

const GdprWindow = ({ titleTextSize }): JSX.Element => {
  const data = useUtilsStore((state) => state.GDPRWindowData)
  const translation = useLocaleStore((state) => state.currentTranslations)
  const locale = useLocaleStore((state) => state.locale)
  const isMobile = useViewStore((state) => state.isMobile)
  const themeColors = useViewStore((state) => state.themeColors)

  const [finalMessage, setFinalMessage] = useState<string | null>(null)
  const finalMessageTimerRef = useRef<NodeJS.Timeout>()
  const animationTimerRef = useRef<NodeJS.Timeout>()

  const [spring, api] = useSpring(() => ({
    from: {
      opacity: 0
    }
  }))

  useEffect(() => {
    api.start({
      from: {
        opacity: 0
      },
      to: {
        opacity: 1
      },
      config: {
        duration: springDuration
      }
    })

    return () => {
      finalMessageTimerRef?.current &&
        clearTimeout(finalMessageTimerRef.current)
      animationTimerRef?.current && clearTimeout(animationTimerRef.current)
    }
  }, [])

  const texts = useMemo<Texts>(() => {
    return {
      title: data?.title?.[locale] || translation.GDPR.title,
      message: data?.message?.[locale] || translation.GDPR.message,
      link: data?.link?.[locale] || translation.GDPR.link,
      buttonAccept:
        data?.buttonAccept?.[locale] || translation.GDPR.buttonAccept,
      buttonRefuse:
        data?.buttonRefuse?.[locale] || translation.GDPR.buttonRefuse,
      messageAccepted:
        data?.messageAccepted?.[locale] || translation.GDPR.messageAccepted,
      messageRefused:
        data?.messageRefused?.[locale] || translation.GDPR.messageRefused
    }
  }, [translation, locale])

  const handleCheck = (value: boolean): void => {
    setGDPRUserConsent(value)
    setFinalMessage(value ? texts.messageAccepted : texts.messageRefused)

    finalMessageTimerRef.current = setTimeout(() => {
      api.start({
        from: {
          opacity: 1
        },
        to: {
          opacity: 0
        },
        config: {
          duration: springDuration
        }
      })
    }, finalMessageDisplayDuration)

    animationTimerRef.current = setTimeout(() => {
      setGDPRDisplay(false)
    }, springDuration + finalMessageDisplayDuration)
  }

  return (
    <animated.div
      className='rf-h-full rf-w-full rf-flex rf-flex-col rf-gap-12 rf-justify-center rf-items-center rf-text-center'
      style={{
        ...spring
      }}
    >
      {/* Title */}
      <div className={`${isMobile ? 'rf-w-3/4' : 'rf-w-1/2'} ${titleTextSize}`}>
        {capitalizeFirstLetter(texts.title, locale)}
      </div>

      {/* Message + Link */}
      <div
        className={`${
          isMobile ? 'rf-w-3/4' : 'rf-w-1/2'
        } rf-flex rf-flex-col rf-items-center rf-gap-4`}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: capitalizeFirstLetter(texts.message, locale).replaceAll(
              '\n',
              '<br>'
            )
          }}
        />

        {data?.linkURL && (
          <a
            href={data.linkURL}
            target='_blank'
            rel='noreferrer noopener'
            style={{
              color: themeColors.primary,
              textDecoration: 'underline'
            }}
          >
            {capitalizeFirstLetter(texts.link, locale)}
          </a>
        )}
      </div>

      {/* Buttons */}
      <div className='rf-w-full rf-h-16 rf-flex rf-flex-row rf-justify-center rf-gap-4'>
        {finalMessage ? (
          <div
            className={`${
              isMobile ? 'rf-w-3/4' : 'rf-w-1/2'
            } rf-animate-fadeIn`}
          >
            {capitalizeFirstLetter(finalMessage, locale)}
          </div>
        ) : (
          <React.Fragment>
            <button
              className='rf-h-fit rf-px-4 rf-py-2 rf-rounded-lg'
              onClick={() => handleCheck(true)}
              style={{
                color: themeColors.loader.toggle.text,
                background: themeColors.loader.toggle.background,
                borderColor: themeColors.loader.toggle.background
              }}
            >
              {capitalizeFirstLetter(texts.buttonAccept, locale)}
            </button>
            <button
              className='rf-h-fit rf-px-4 rf-py-2 rf-rounded-lg'
              onClick={() => handleCheck(false)}
              style={{
                color: themeColors.loader.toggle.text,
                background: themeColors.loader.toggle.background,
                borderColor: themeColors.loader.toggle.background
              }}
            >
              {capitalizeFirstLetter(texts.buttonRefuse, locale)}
            </button>
          </React.Fragment>
        )}
      </div>
    </animated.div>
  )
}

export default GdprWindow
