import React, { useEffect, useRef } from 'react'
import { isMobile } from 'react-device-detect'
import {
  useSpeechStore,
  setMuted,
  setCancel,
  setActiveRecognitionState
} from '../Contexts/speechStore'
import { useViewStore } from '../Contexts/viewStore'
import { useWidgetStore } from '../Contexts/widgetStore'
import { RecognitionState } from '../../models/enums'

const FocusManager = (): JSX.Element => {
  const isWidget = useViewStore((state) => state.isWidget)
  const open = useWidgetStore((state) => state.open)
  const muted = useSpeechStore((state) => state.muted)
  const activeRecognitionState = useSpeechStore(
    (state) => state.activeRecognitionState
  )
  const focusedRef = useRef<boolean>(true)
  const oldMutedRef = useRef<boolean>(muted)
  const recognitionStateRef = useRef<RecognitionState>(activeRecognitionState)

  useEffect(() => {
    focusedRef.current && (oldMutedRef.current = muted)
  }, [muted])

  useEffect(() => {
    recognitionStateRef.current = activeRecognitionState
  }, [activeRecognitionState])

  useEffect(() => {
    if (isMobile && isWidget) {
      if (open) {
        focusedRef.current = true
        oldMutedRef.current === false && setMuted(false)
      } else {
        focusedRef.current = false
        setCancel(true)
        setMuted(true)
        recognitionStateRef.current === RecognitionState.Listening &&
          setActiveRecognitionState(RecognitionState.ForceClosing)
      }
    }
  }, [open, isWidget, isMobile])

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      focusedRef.current = true
      oldMutedRef.current === false && setMuted(false)
    } else {
      focusedRef.current = false
      setCancel(true)
      setMuted(true)
      recognitionStateRef.current === RecognitionState.Listening &&
        setActiveRecognitionState(RecognitionState.ForceClosing)
    }
  }

  useEffect(() => {
    isMobile &&
      document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [isMobile])

  return <React.Fragment />
}

export default FocusManager
