import { create } from 'zustand'
import { HostConfig } from 'adaptivecards'
import createAdaptiveCardHostConfig from '../../utils/createAdaptiveCardHostConfig'

interface Choice {
  title: string
  value: string
}

interface AdaptiveCardStore {
  adaptiveCardHostConfig: any
  values: Map<
    string,
    string | number | Array<string> | Array<number> | Choice | boolean
  >
  currentId: string | undefined
  currentToggleIds: Array<string>
  currentValue:
    | Record<
        string,
        string | number | Array<string> | Array<number> | Choice | boolean
      >
    | undefined
  handleButtonClick: (data: any) => void
}

const initialState: AdaptiveCardStore = {
  adaptiveCardHostConfig: {},
  values: new Map(),
  currentId: undefined,
  currentToggleIds: [],
  currentValue: undefined,
  handleButtonClick: () => {}
}

export const useAdaptiveCardStore = create<AdaptiveCardStore>()(() => {
  return initialState
})

export const setAdaptiveCardHostConfig = (
  primary: string,
  background: string
): void => {
  const customAdaptiveCardHostConfig = createAdaptiveCardHostConfig(
    primary,
    background
  )
  useAdaptiveCardStore.setState({
    adaptiveCardHostConfig: new HostConfig(customAdaptiveCardHostConfig)
  })
}

export const setValue = (
  id: string,
  value: string | number | Array<string> | Array<number> | Choice | boolean
): void => {
  console.log('Set value : ', id, value)
  const currentState = useAdaptiveCardStore.getState()
  if (id === currentState.currentId) {
    const currentValues = currentState.values
    currentValues.set(id, value)

    useAdaptiveCardStore.setState({
      values: currentValues,
      currentValue: { [id]: value }
    })
  }
}

export const setToggleValue = (id: string, value: boolean): void => {
  const currentState = useAdaptiveCardStore.getState()
  if (currentState.currentToggleIds.includes(id)) {
    const currentValues = currentState.values
    currentValues.set(id, value)

    const tempCurrentValue = currentState.currentValue || {}
    currentState.currentToggleIds.forEach((id) => {
      const value = (currentValues.get(id) as boolean) || false
      tempCurrentValue[id] = value
    })

    useAdaptiveCardStore.setState({
      values: currentValues,
      currentValue: tempCurrentValue
    })
  }
}

export const setCurrentId = (value: string | undefined): void => {
  useAdaptiveCardStore.setState({ currentId: value })
  resetCurrentValues()
}

export const addCurrentToggleId = (id: string, defaultValue: boolean): void => {
  const currentState = useAdaptiveCardStore.getState()
  const currentValues = currentState.values
  const currentToggleIds = currentState.currentToggleIds
  currentValues.set(id, defaultValue)

  useAdaptiveCardStore.setState({
    values: currentValues,
    currentToggleIds: [...currentToggleIds, id]
  })
}

export const resetCurrentValues = (): void => {
  useAdaptiveCardStore.setState({
    currentValue: undefined,
    currentToggleIds: []
  })
}

export const resetAdaptiveCardStore = (): void => {
  useAdaptiveCardStore.setState({ ...initialState })
}
