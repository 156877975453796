import React, { useLayoutEffect, useState } from 'react'

interface Dimensions {
  width: number
  height: number
}

/**
 * Use javascript ResizeOberver object to catch element's resize and return current size
 * @param {React.RefObject<HTMLElement>} ref element for which we want to get the size when resized
 * @returns {Dimensions} dimension data (width, height)
 */
const useRefDimensions = (ref: React.RefObject<HTMLElement>): Dimensions => {
  const [dimensions, setDimensions] = useState<Dimensions>({
    width: 1,
    height: 1
  })
  const [observer, setObserver] = useState<ResizeObserver>()

  const handleResizeObserverTick = (
    entries: Array<ResizeObserverEntry>
  ): void => {
    entries.length &&
      entries[0].contentBoxSize.length &&
      setDimensions({
        width: entries[0].contentBoxSize[0].inlineSize,
        height: entries[0].contentBoxSize[0].blockSize
      })
  }

  useLayoutEffect(() => {
    if (!observer) {
      const obs = new ResizeObserver(handleResizeObserverTick)
      if (ref?.current) {
        obs.observe(ref.current)
        setObserver(obs)
      }
    }

    return () => {
      observer?.disconnect()
    }
  }, [])

  return dimensions
}

export default useRefDimensions
