import React from 'react'
import { jsxToHtmlElement } from '../../../utils/jsxToHtmlElement'
import * as AC from 'adaptivecards'
import ActionButton from '../ActionButton'

export class ActionShowCardClass extends AC.ShowCardAction {
  private internalRenderedElement: HTMLElement = document.createElement('div')

  static readonly fullWidthProperty = new AC.BoolProperty(
    AC.Versions.v1_0,
    'fullWidth',
    false
  )

  static readonly positionProperty = new AC.StringProperty(
    AC.Versions.v1_0,
    'position',
    true,
    undefined,
    'left'
  )

  static readonly displayProperty = new AC.StringProperty(
    AC.Versions.v1_0,
    'display',
    true,
    undefined,
    'button'
  )

  // fullWidth property accessors
  @AC.property(ActionShowCardClass.fullWidthProperty)
  get fullWidth(): boolean {
    return this.getValue(ActionShowCardClass.fullWidthProperty)
  }

  set fullWidth(value: boolean) {
    if (this.fullWidth !== value) {
      this.setValue(ActionShowCardClass.fullWidthProperty, value)
    }
  }

  // position property accessors
  @AC.property(ActionShowCardClass.positionProperty)
  get position(): string {
    return this.getValue(ActionShowCardClass.positionProperty)
  }

  set position(value: string) {
    if (this.position !== value) {
      this.setValue(ActionShowCardClass.positionProperty, value)
    }
  }

  // display property accessors
  @AC.property(ActionShowCardClass.displayProperty)
  get display(): string {
    return this.getValue(ActionShowCardClass.displayProperty)
  }

  set display(value: string) {
    if (this.display !== value) {
      this.setValue(ActionShowCardClass.displayProperty, value)
    }
  }

  get renderedElement(): HTMLElement {
    return this.internalRenderedElement
  }

  public render(): void {
    this.internalRenderedElement = jsxToHtmlElement(this.renderReact())
  }

  protected renderReact = (): JSX.Element => {
    const props = {
      text: this.title,
      fullWidth: this.fullWidth,
      position: this.position,
      display: this.display
    }
    return <ActionButton {...props} />
  }
}
