/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useState } from 'react'
import { useLocaleStore } from '../Contexts/localeStore'
import { useUtilsStore, lockClickedButton } from '../Contexts/utilsStore'
import { useViewStore } from '../Contexts/viewStore'
import { sendMessage } from '../Contexts/directLineStore'

import type { Action } from '../../models/suggestedActionAttachment'
import { AnimationSize } from '../../models/enums'
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter'

import Animation from '../Loader/Animation'

interface SuggestedActionAttachmentProps {
  title: string
  actions: Array<Action>
  history: boolean
  height?: number
}

const SuggestedActionAttachment = ({
  title,
  actions,
  history,
  height
}: SuggestedActionAttachmentProps): JSX.Element => {
  const locale = useLocaleStore((state) => state.locale)
  const themeColors = useViewStore((state) => state.themeColors)
  const isMobile = useViewStore((state) => state.isMobile)
  const unlockClickedButton = useUtilsStore(
    (state) => state.unlockClickedButton
  )
  const [clicked, setClicked] = useState<number | null>(null)

  const animationColor = {
    '--rf-color-loader-animation': themeColors.card.button.text.default
  } as React.CSSProperties

  useEffect(() => {
    unlockClickedButton && setClicked(null)
  }, [unlockClickedButton])

  const handleClick = (value, index): void => {
    lockClickedButton()
    setClicked(index)
    sendMessage(value)
  }

  return (
    <div
      className='rf-w-full rf-p-4 rf-flex rf-flex-col rf-items-center rf-justify-center rf-rounded rf-bg-cardFrameBackground'
      style={{
        height: height
      }}
    >
      {/* Title */}
      <div className='rf-w-full rf-pb-4 rf-text-center rf-text-cardFrameText rf-subtitle-size-auto'>
        {capitalizeFirstLetter(title, locale)}
      </div>
      <div className='rf-w-full rf-pt-4 rf-flex rf-flex-row rf-justify-center rf-items-center rf-gap-4 rf-flex-wrap'>
        {actions.map((action, key) => {
          return (
            <button
              key={key}
              className={
                clicked === key ? 'rf-card-button-clicked' : 'rf-card-button'
              }
              onClick={
                history
                  ? (): void => {}
                  : (): void => handleClick(action.action, key)
              }
            >
              {clicked === key ? (
                <div
                  className='rf-w-full rf-h-[1.25rem] large:rf-h-[1.3rem] rf-flex rf-justify-center rf-items-center'
                  style={{
                    ...animationColor
                  }}
                >
                  <div className='rf-w-2 rf-h-2 large:rf-w-4 large:rf-h-4'>
                    <Animation
                      size={
                        isMobile ? AnimationSize.small : AnimationSize.medium
                      }
                    />
                  </div>
                </div>
              ) : (
                action.title || '?'
              )}
            </button>
          )
        })}
      </div>
    </div>
  )
}

export default SuggestedActionAttachment
