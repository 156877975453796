import React, { useEffect, useState } from 'react'
import { useViewStore } from '../../Contexts/viewStore'
import { useUtilsStore } from '../../Contexts/utilsStore'
import {
  useAdaptiveCardStore,
  setValue,
  setCurrentId
} from '../../Contexts/adaptiveCardStore'
import { CurrentSubView } from '../../../models/enums'

interface SliderProps {
  id: string
  minValue?: number
  maxValue?: number
  minLabel?: string
  maxLabel?: string
}

const Slider = ({
  id,
  minValue,
  maxValue,
  minLabel,
  maxLabel
}: SliderProps): JSX.Element => {
  const themeColors = useViewStore((state) => state.themeColors)
  const currentSubView = useUtilsStore((state) => state.currentSubView)
  const values = useAdaptiveCardStore((state) => state.values)
  const [sliderValue, setSliderValue] = useState<number>(
    (values.get(id) as number) || ((minValue || 0) + (maxValue || 100)) / 2
  )
  const [sliderStyle, setSliderStyle] = useState<string>(
    `linear-gradient(to right, ${themeColors.primary} ${
      ((sliderValue - (minValue || 0)) /
        ((maxValue || 100) - (minValue || 0))) *
      100
    }%, #7878805C ${
      ((sliderValue - (minValue || 0)) /
        ((maxValue || 100) - (minValue || 0))) *
      100
    }%)`
  )

  useEffect(() => {
    currentSubView !== CurrentSubView.history && setCurrentId(id)
  }, [])

  /**
   * On slider value change :
   *  - check if the component will be rendered in history, if so bypass every interaction
   *  - set sliderValue state
   *  - call parent function with event value
   * @param e : changeEvent
   */
  const handleChange = (e): void => {
    if (currentSubView !== CurrentSubView.history) {
      setSliderValue(e.target.value)
      setValue(id, e.target.value)
    }
  }

  /**
   * On value input in slider :
   *  - check if the component will be rendered in history, if so bypass every interaction
   *  - get value as percentage
   *  - set the slider color depending on the percentage obtained
   * @param e : inputEvent
   */
  const handleOnInput = (e): void => {
    if (currentSubView !== CurrentSubView.history) {
      // Value as percentage, we substract minValue up and down to get the right percentage if minValue !== 0
      const valPourcentage =
        ((e.target.value - (minValue || 0)) /
          (e.target.max - (minValue || 0))) *
        100
      setSliderStyle(
        `linear-gradient(to right, ${themeColors.primary} ${valPourcentage}%, #7878805C ${valPourcentage}%)`
      )
    }
  }

  return (
    <div
      className={`rf-w-full rf-px-6 rf-pt-14 ${
        minLabel || maxLabel ? 'rf-pb-5' : 'rf-pb-14'
      } rf-rounded-lg rf-text-size-auto rf-text-white`}
      style={{ background: 'rgba(0, 0, 0, 0.5)' }}
    >
      {/* Range input */}
      <input
        style={{ background: sliderStyle }}
        onInput={handleOnInput}
        onChange={handleChange}
        value={sliderValue}
        type='range'
        min={minValue || 0}
        max={maxValue || 100}
        className='rf-slider-custom'
      />

      {/* Labels */}
      {(minLabel || maxLabel) && (
        <div className='rf-w-full rf-mt-5 rf-flex rf-flex-row rf-justify-between rf-items-center'>
          {minLabel && <div>{minLabel}</div>}
          {maxLabel &&
            (minLabel ? (
              <div>{maxLabel}</div>
            ) : (
              <React.Fragment>
                {/* If there is only the max label, create a div with empty character inside to have 2 elements, to use parent's flex justify-between property */}
                <div>{'\u2800'}</div>
                <div>{maxLabel}</div>
              </React.Fragment>
            ))}
        </div>
      )}
    </div>
  )
}

export default Slider
