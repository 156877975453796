interface DirectLineCreationData {
  token: string
  conversationId?: string
  watermark?: string
  conversationStartProperties?: {
    locale: string
  }
  userId?: string
  externalActivityHandler?: (action: any) => boolean
}

interface DirectLineAttachment {
  content?: any
  contentType: string
  contentUrl?: string
  name?: string
  thumbnailUrl?: string
}

interface DirectLineCardAction {
  type:
    | 'call'
    | 'downloadFile'
    | 'imBack'
    | 'messageBack'
    | 'openUrl'
    | 'playAudio'
    | 'playVideo'
    | 'postBack'
    | 'showImage'
    | 'signin'
  image?: string
  title?: string
  displayText?: string
  text?: string
  value?: Record<string, any> | string
}

interface DataAddedToChannelData {
  pageSize: string | number
  location?: {
    latitude: number
    longitude: number
  }
  position?: {
    latitude: number
    longitude: number
  }
}

enum LegacyDirectLineActivityType {
  UNKNOWN = 'DIRECT_LINE/UNKNOWN',
  INCOMING_ACTIVITY = 'DIRECT_LINE/INCOMING_ACTIVITY',
  POST_ACTIVITY = 'DIRECT_LINE/POST_ACTIVITY',
  POST_ACTIVITY_FULFILLED = 'DIRECT_LINE/POST_ACTIVITY_FULFILLED',
  SEND_MESSAGE = 'WEB_CHAT/SEND_MESSAGE',
  SEND_EVENT = 'WEB_CHAT/SEND_EVENT',
  TYPING = 'WEB_CHAT/EMIT_TYPING_INDICATOR',
  MARK = 'WEB_CHAT/MARK_ACTIVITY',
  SET_LANGUAGE = 'WEB_CHAT/SET_LANGUAGE'
}

const LegacyDirectLineStatus = {
  0: 'DIRECT_LINE/CONNECT',
  1: 'DIRECT_LINE/CONNECT_PENDING',
  2: 'DIRECT_LINE/CONNECT_FULFILLED',
  3: 'DIRECT_LINE/EXPIRED_TOKEN',
  4: 'DIRECT_LINE/CONNECT_FAILED',
  5: 'DIRECT_LINE/CONNECT_ENDED'
}

export type {
  DirectLineCreationData,
  DirectLineAttachment,
  DirectLineCardAction,
  DataAddedToChannelData
}
export { LegacyDirectLineActivityType, LegacyDirectLineStatus }
