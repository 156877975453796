import { create } from 'zustand'
import type { AddressData } from '../../models/types'
import type { TranslationFile } from '../../models/translationsType'
import fetchAvailableLanguages from '../../utils/fetchAvailableLanguages'
import * as translations from '../../translations'

interface LocaleStore {
  locale: string
  defaultLocale: string
  supported: string[]
  defaultTranslations: TranslationFile
  currentTranslations: TranslationFile
}

const initialState: LocaleStore = {
  locale: '',
  defaultLocale: 'fr-FR',
  supported: [],
  defaultTranslations: translations.fr_FR,
  currentTranslations: translations.fr_FR
}

export const useLocaleStore = create<LocaleStore>()(() => {
  return initialState
})

export const getLocale = (): string => {
  return useLocaleStore((state) => state.locale)
}

export const setLocale = (locale: string): void => {
  useLocaleStore.setState({ locale: locale })
  const changeLocaleEvent = new CustomEvent('retorikChangeLocale', {
    detail: {
      locale: locale
    }
  })
  document.dispatchEvent(changeLocaleEvent)

  const defaultTranslation = useLocaleStore.getState().defaultTranslations
  const tempTranslation = translations[locale.replace('-', '_')]
  if (tempTranslation) {
    useLocaleStore.setState({
      currentTranslations: { ...defaultTranslation, ...tempTranslation }
    })
  } else {
    useLocaleStore.setState({ currentTranslations: defaultTranslation })
  }
}

export const fetchSupportedLanguages = async (
  addressData: AddressData
): Promise<void> => {
  fetchAvailableLanguages(addressData).then((languages) => {
    useLocaleStore.setState({
      defaultLocale: languages.default,
      supported: languages.all
    })
  })
}

export const resetLocaleStore = (): void => {
  useLocaleStore.setState({ ...initialState })
}
