/* eslint-disable react/jsx-boolean-value */
import React from 'react'
import { useViewStore } from '../Contexts/viewStore'
import type { TemplateDefaultProps } from '../../models/types'
import { FullScreenRemote } from '.'
import CloseButton from '../Common/CloseButton'
import AbsoluteLargeClosingButton from '../Utils/SubView/AbsoluteLargeClosingButton'
import { BackButton } from '../Menu/Common'

type FullScreenRemoteClosingProps = TemplateDefaultProps & {
  closeButtonClassName?: string
  remoteCloseButtonZIndex?: string
  mobileReturnTitle?: string
  handleClose: () => void
  onClick?: () => void
}

const FullScreenRemoteClosing = ({
  className,
  background,
  closeButtonClassName,
  remoteCloseButtonZIndex,
  mobileReturnTitle,
  handleClose,
  onClick,
  animation,
  children
}: FullScreenRemoteClosingProps): JSX.Element => {
  const isMobile = useViewStore((state) => state.isMobile)
  const isLandscape = useViewStore((state) => state.isLandscape)
  const isBorne = useViewStore((state) => state.isBorne)

  return (
    <React.Fragment>
      <FullScreenRemote
        className={className}
        background={background}
        onClick={onClick}
        animation={animation}
      >
        {/* Close button for landscape and borne modes */}
        {isLandscape && (
          <CloseButton
            onClick={handleClose}
            className={closeButtonClassName}
            showLabel={true}
          />
        )}

        {/* Close button for mobile / widget modes if it is especilly demanded */}
        {isMobile && mobileReturnTitle && (
          <BackButton
            title={mobileReturnTitle}
            handleBack={handleClose}
            className='rf-p-4 rf-text-size-auto'
          />
        )}

        {children}
      </FullScreenRemote>

      {isBorne && (
        <AbsoluteLargeClosingButton
          dashboardVisible={true}
          zIndexClassname={remoteCloseButtonZIndex}
          onClick={handleClose}
        />
      )}
    </React.Fragment>
  )
}

export default FullScreenRemoteClosing
