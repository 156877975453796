import { useActivityStore } from '../components/Contexts/activityStore'

const useShowMap = (): boolean => {
  const lastActivity = useActivityStore((state) => state.lastActivity)

  if (
    lastActivity?.type === 'event' &&
    lastActivity.name === 'Hospitality.ShowMap'
  ) {
    return true
  }

  return false
}

export default useShowMap
