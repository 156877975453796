import React from 'react'
import { useLocaleStore } from '../Contexts/localeStore'
import { useViewStore } from '../Contexts/viewStore'
import type { TemplateDefaultProps } from '../../models/types'
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter'
import { SubMenu as Template } from '.'
import { BackButton } from '../Menu/Common'

type SubMenuClosingProps = TemplateDefaultProps & {
  className?: string
  handleClose: () => void
  backIsConversation?: boolean
  title?: string
}

const SubMenuClosing = ({
  className,
  background,
  handleClose,
  backIsConversation,
  title,
  children
}: SubMenuClosingProps): JSX.Element => {
  const translation = useLocaleStore((state) => state.currentTranslations)
  const locale = useLocaleStore((state) => state.locale)
  const isMobile = useViewStore((state) => state.isMobile)

  return (
    <Template background={background} onClick={handleClose}>
      {/* Back button for mobile / widget / non-tactile desktop */}
      <BackButton
        title={
          backIsConversation
            ? translation.menu.backconversation
            : isMobile
            ? translation.common.back
            : translation.menu.backconversation
        }
        handleBack={handleClose}
        className='rf-relative large:rf-absolute large-tactile:rf-hidden rf-pb-6 large:rf-left-4 large:rf-top-8 rf-text-size-auto'
      />
      <div
        className={`rf-w-full large:rf-w-fit large:rf-max-w-full large-vertical:rf-max-w-2/3 large:rf-px-6 rf-flex rf-flex-col large-vertical:rf-text-size-auto rf-overflow-y-scroll rf-scrollbar-hidden ${className}`}
      >
        {/* Back button for large tactiles */}
        <BackButton
          title={translation.menu.backconversation}
          handleBack={handleClose}
          className='rf-hidden large-tactile:rf-flex rf-pt-4 rf-pb-6'
        />

        {/* Title is given in props */}
        {title && (
          <div className='large:rf-mt-20 large-tactile:rf-mt-0 rf-mb-6 rf-title-bold-size-auto rf-font-bold'>
            {capitalizeFirstLetter(title, locale)}
          </div>
        )}

        {children}
      </div>
    </Template>
  )
}

export default SubMenuClosing
