import React, { useMemo } from 'react'
import { AnimationSize } from '../../models/enums'

interface AnimationProps {
  size?: AnimationSize
}

const Animation = ({ size }: AnimationProps): JSX.Element => {
  const customClassName = useMemo<string>(() => {
    switch (size) {
      case AnimationSize.small:
        return 'rf-loader-mini rf-animate-loaderMini rf-h-2 -rf-top-2'
      case AnimationSize.medium:
        return 'rf-loader-medium rf-animate-loaderMedium rf-h-4 -rf-top-4'
      case AnimationSize.large:
      default:
        return 'rf-loader rf-animate-loader rf-h-6 -rf-top-6'
    }
  }, [size])

  return <div className={`rf-relative ${customClassName} rf-rounded-half`} />
}

export default Animation
