import { CDNCharactersAddress } from '../models/constants'
import {
  RetorikMainComponentConfiguration,
  RetorikMainComponentProps
} from '../models/retorikTypes'
import processBaseURI from './studioRetorikAddressFromBaseURI'
import processPrefix from './studioRetorikAddressFromPrefix'

const getRetorikConfigs = async (
  props: RetorikMainComponentProps
): Promise<RetorikMainComponentConfiguration | null> => {
  const retorikProps = { ...props }
  let res: RetorikMainComponentConfiguration | null = null

  const baseAddress = `${
    retorikProps.addressData.baseURI &&
    retorikProps.addressData.baseURI.length > 0
      ? processBaseURI(retorikProps.addressData.baseURI)
      : processPrefix(retorikProps.addressData.prefix)
  }/${
    retorikProps.addressData.tenant ? retorikProps.addressData.tenant + '/' : ''
  }`

  const fullAddress = `${baseAddress}api/integration/framework`

  try {
    const response = await fetch(fullAddress)
    const json = await response.json()
    res = processJson(json, retorikProps, baseAddress)
  } catch (error) {
    console.log(error)
  }

  return res
}

const processJson = (
  json: any,
  retorikProps: RetorikMainComponentProps,
  baseAddress: string
): RetorikMainComponentConfiguration | null => {
  if (json) {
    // Get full address for backgrounds & logo
    let logo, background, newsBackground, personnage
    if (json.personnage) {
      personnage = `${CDNCharactersAddress}/${json.personnage}`
    } else {
      personnage = `${CDNCharactersAddress}/long-shot/zahra`
    }
    if (
      retorikProps.addressData.tenant &&
      baseAddress.indexOf(`/${retorikProps.addressData.tenant}`)
    ) {
      baseAddress = baseAddress.replace(
        `/${retorikProps.addressData.tenant}`,
        ''
      )
    }
    baseAddress = baseAddress.endsWith('/')
      ? baseAddress.slice(0, -1)
      : baseAddress
    if (json.logo) {
      logo = `${baseAddress}${json.logo}`
    }
    if (json.background && json.background.length > 0) {
      background = `${baseAddress}${json.background}`
    } else {
      background = new URL('../assets/images/background.webp', import.meta.url)
    }
    if (json.newsBackground && json.newsBackground.length > 0) {
      newsBackground = `${baseAddress}${json.newsBackground}`
    } else {
      newsBackground = new URL(
        '../assets/images/background.webp',
        import.meta.url
      )
    }

    // Handle size & height : string or number
    let size = json.agentSize || undefined
    if (json.agentSize && !isNaN(+json.agentSize)) {
      size = +json.agentSize // convert to int
    }
    let height = json.agentHeight || undefined
    if (json.agentHeight && !isNaN(+json.agentHeight)) {
      height = +json.agentHeight // convert to int
    }

    // For widget
    // Handle size & height : string or number
    let sizeWidget = json.agentSizeWidget || json.agentSize || undefined
    if (json.agentSizeWidget && !isNaN(+json.agentSizeWidget)) {
      sizeWidget = +json.agentSizeWidget // convert to int
    }
    let heightWidget = json.agentHeightWidget || json.agentHeight || undefined
    if (json.agentHeightWidget && !isNaN(+json.agentHeightWidget)) {
      heightWidget = +json.agentHeightWidget // convert to int
    }

    const props: RetorikMainComponentConfiguration = {
      config: {
        fullSize: undefined,
        isUsedOnBorne: undefined,
        locales: {
          getDefaultFromBrowser: undefined
        },
        position: {
          searchForLocation: json.detectLocation,
          latitude: undefined,
          longitude: undefined,
          city: undefined,
          country: undefined
        },
        subtitles: json.subtitles,
        logo: json.logo ? { src: logo } : undefined,
        answerpanel: undefined,
        skipWelcome: json.skipWelcome,
        preventExpectedInputHint: json.preventExpectedInputHint,
        pagination: undefined,
        remote: undefined,
        companyName: json.companyName,
        companyNameLocalized: json.companyNameLocalized,
        subCompanyMessage: json.subCompanyMessage,
        subCompanyMessageLocalized: json.subCompanyMessageLocalized,
        loaderInformationTexts: {
          text: {
            top: json.textModeHomeTitle,
            bottom: json.textModeHomeSubtitle
          },
          vocal: {
            top: json.voiceModeHomeTitle,
            bottom: json.voiceModeHomeSubtitle
          }
        },
        loaderInformationTextsLocalized: {
          text: {
            top: json.textModeHomeTitleLocalized,
            bottom: json.textModeHomeSubtitleLocalized
          },
          vocal: {
            top: json.voiceModeHomeTitleLocalized,
            bottom: json.voiceModeHomeSubtitleLocalized
          }
        },
        hideRetorikLogo: json.hideRetorikLogo,
        baseMenu: undefined,
        customMenu: undefined,
        subMenu: undefined,
        hideMenu: json.hideMenu,
        hideControls: json.hideControls,
        doNotDetectDeviceFromUserAgent: json.doNotDetectDeviceFromUserAgent,
        enableConversationCookie: json.enableConversationCookie,
        conversationCookieMaxAge: json.conversationCookieMaxAge,
        enableTelemetry: json.enableTelemetry,
        allowSwitchLayout: json.allowSwitchLayout,
        forceMobileView: json.forceMobileFullScreenLayout,
        enableSpeechCaching: json.enableSpeechCaching,
        speechRecognitionOptions: json.speechRecognitionOptions,
        speechSynthesisOptions: json.speechSynthesisOptions,
        enableDocumentPrinting: undefined,
        disableSpeechMode: json.disableSpeechMode,
        disableSound: json.disableSound,
        timerForFilterSelectionListMode: undefined
      },
      viewsConfig: {
        homeRoute: 'home',
        webcamRotation: undefined,
        views: {
          home: {
            background: {
              style: 'image',
              image: background,
              video: undefined,
              webcam: undefined,
              neutral: undefined,
              blur: undefined,
              overlayOpacity: undefined
            }
          },
          news: {
            intervalInSeconds: 2,
            loop: true,
            openingVideo: '',
            background: {
              style: 'image',
              image: newsBackground,
              video: undefined,
              webcam: undefined,
              neutral: undefined,
              blur: undefined,
              overlayOpacity: undefined
            }
          }
        }
      },
      chatbotData: {
        size: size,
        height: height
      },
      chatbotDataWidget: {
        size: sizeWidget,
        height: heightWidget
      },
      agentData: personnage,
      ponyfillFactoryCredentials: {
        region: json.voiceCredentialsRegion ?? 'francecentral',
        subscriptionKey:
          json.voiceCredentialsSubscriptionKey ??
          '2f62867e22a544b78a16c99a2707ef49'
      },
      addressData: retorikProps.addressData,
      userData: retorikProps.userData,
      customVoice: {
        voice: json.voice,
        gender: undefined
      },
      colors: {
        theme: json.colorSettings.theme,
        primary: json.colorSettings.primary,
        secondary: json.colorSettings.secondary,
        black: json.colorSettings.black,
        whereToEatColor: json.colorSettings.whereToEatColor,
        whereToSleepColor: json.colorSettings.whereToSleepColor,
        tobeSeenColor: json.colorSettings.tobeSeenColor,
        tobeDoneColor: json.colorSettings.tobeDoneColor,
        localProductsColor: json.colorSettings.localProductsColor,
        servicesColor: json.colorSettings.servicesColor,
        companyName: json.colorSettings.companyName,
        agendaColor: json.colorSettings.agendaColor,
        occupantColor: json.colorSettings.occupantColor,
        loader: {
          animation: json.colorSettings.loader?.animation,
          background: json.colorSettings.loader?.background,
          text: json.colorSettings.loader?.text,
          toggle: {
            text: json.colorSettings.loader?.toggle?.text,
            background: json.colorSettings.loader?.toggle?.background
          },
          button: {
            background: {
              default: json.colorSettings.loader?.button?.background?.default,
              hover: json.colorSettings.loader?.button?.background?.hover
            },
            border: {
              default: json.colorSettings.loader?.button?.border?.default,
              hover: json.colorSettings.loader?.button?.border?.hover
            },
            text: {
              default: json.colorSettings.loader?.button?.text?.default,
              hover: json.colorSettings.loader?.button?.text?.hover
            }
          }
        },
        card: {
          frame: {
            background: json.colorSettings.card?.frame?.background,
            border: json.colorSettings.card?.frame?.border,
            text: json.colorSettings.card?.frame?.text
          },
          button: {
            background: {
              default: json.colorSettings.card?.button?.background?.default,
              hover: json.colorSettings.card?.button?.background?.hover
            },
            border: {
              default: json.colorSettings.card?.button?.border?.default,
              hover: json.colorSettings.card?.button?.border?.hover
            },
            text: {
              default: json.colorSettings.card?.button?.text?.default,
              hover: json.colorSettings.card?.button?.text?.hover
            }
          },
          discoverButton: {
            background: {
              default:
                json.colorSettings.card?.discoverButton?.background?.default,
              hover: json.colorSettings.card?.discoverButton?.background?.hover
            },
            border: {
              default: json.colorSettings.card?.discoverButton?.border?.default,
              hover: json.colorSettings.card?.discoverButton?.border?.hover
            },
            text: {
              default: json.colorSettings.card?.discoverButton?.text?.default,
              hover: json.colorSettings.card?.discoverButton?.text?.hover
            }
          }
        },
        textMode: {
          panel: {
            background: json.colorSettings.textMode?.panel?.background,
            border: json.colorSettings.textMode?.panel?.border,
            conversationUser:
              json.colorSettings.textMode?.panel?.conversationUser,
            conversationBot: json.colorSettings.textMode?.panel?.conversationBot
          }
        },
        vocalMode: {
          subtitles: {
            text: json.colorSettings.vocalMode?.subtitles?.text,
            background: json.colorSettings.vocalMode?.subtitles?.background
          }
        },
        formInput: {
          text: {
            default: json.colorSettings.formInput?.inputText?.default,
            hover: json.colorSettings.formInput?.inputText?.hover
          },
          inputRadioCheckbox: {
            unchecked: {
              background:
                json.colorSettings.formInput?.inputRadioCheckbox?.unchecked
                  ?.background,
              border:
                json.colorSettings.formInput?.inputRadioCheckbox?.unchecked
                  ?.border
            },
            checked: {
              background:
                json.colorSettings.formInput?.inputRadioCheckbox?.checked
                  ?.background,
              border:
                json.colorSettings.formInput?.inputRadioCheckbox?.checked
                  ?.border,
              item: json.colorSettings.formInput?.inputRadioCheckbox?.checked
                ?.item
            }
          },
          inputButton: {
            background: {
              default:
                json.colorSettings.formInput?.inputButton?.background?.default,
              hover:
                json.colorSettings.formInput?.inputButton?.background?.hover
            },
            border: {
              default:
                json.colorSettings.formInput?.inputButton?.border?.default,
              hover: json.colorSettings.formInput?.inputButton?.border?.hover
            },
            text: {
              default: json.colorSettings.formInput?.inputButton?.text?.default,
              hover: json.colorSettings.formInput?.inputButton?.text?.hover
            }
          },
          likert: {
            default: json.colorSettings.formInput?.likert?.default,
            hover: json.colorSettings.formInput?.likert?.hover
          }
        }
      },
      defaultMode: json.defaultMode,
      skipLoader: json.skipLoader
    }

    return props
  }

  return null
}

export default getRetorikConfigs
