import React, { useState } from 'react'
import { useLocaleStore } from '../Contexts/localeStore'
import { setRoute } from '../Contexts/viewStore'
import { sendEvent } from '../Contexts/directLineStore'

import type { ChatbotData } from '../../models/types'
import { Routes } from '../../models/enums'

import NewsView from './NewsView'
import { BackButton } from '../Menu/Common'

interface WarningBeforeNewsViewProps {
  propsToTransfer: {
    chatbotData: ChatbotData
    hideMenu?: boolean
    isRetorikNews?: boolean
    handleEndedWithoutloop?: () => void
  }
}

const WarningBeforeNewsView = ({
  propsToTransfer
}: WarningBeforeNewsViewProps): JSX.Element => {
  const translation = useLocaleStore((state) => state.currentTranslations)
  const [waiting, setWaiting] = useState<boolean>(true)

  const handleClick = (goHome: boolean): void => {
    sendEvent('Davi.CloseWindow', null)
    sendEvent('Davi.CloseWindowNewsSoundActivated', null)
    goHome ? setRoute(Routes.Home) : setWaiting(false)
  }

  return waiting ? (
    <div className='rf-relative rf-z-util rf-col-start-1 rf-col-span-full rf-row-start-1 rf-row-span-full rf-flex rf-flex-col rf-gap-4 rf-justify-center rf-items-center rf-bg-truewhite'>
      <BackButton
        title={translation.common.back}
        handleBack={(): void => handleClick(true)}
        className='rf-absolute rf-top-4 rf-left-4 rf-text-size-auto'
      />

      <p className='rf-px-4 rf-text-center'>{translation.news.warningtext}</p>
      <button
        className='rf-card-button-secondary'
        onClick={(): void => handleClick(false)}
      >
        {translation.common.continue}
      </button>
      <button
        className='rf-card-button-secondary'
        onClick={(): void => handleClick(true)}
      >
        {translation.common.back}
      </button>
    </div>
  ) : (
    <NewsView {...propsToTransfer} />
  )
}

export default WarningBeforeNewsView
